const Calculate = () =>{

    const root = document.getElementById('root');
    const WrapperTop = document.getElementById('wrapperTop');
    const WrapperLeft = document.getElementById('wrapperLeft');
    const WrapperRight = document.getElementById('wrapperRight');
    const WrapperBottom = document.getElementById('wrapperBottom');
    const LeftAdminPanel = document.getElementsByClassName("left_admin_panel").item(0);
    const RightAdminPanel = document.getElementsByClassName("right_admin_panel").item(0);
    const LeftWidth = WrapperLeft && WrapperLeft.offsetWidth;
    const LeftHeight = WrapperLeft && WrapperLeft.offsetHeight;
    const RightWidth = WrapperRight && WrapperRight.offsetWidth;
    const RightHeight = LeftHeight;
    const TopHeight = WrapperTop.offsetHeight;
    const BottomHeight = WrapperBottom.offsetHeight;
    const isBottomOpen = document.getElementsByClassName('bottom_open');
    const MainAdminPanel = document.getElementById('main_admin_panel');
    const ModulesBar = document.getElementById('modules_bar');
    const ModulesBarList = document.getElementById('modulesBar_list');
    const ImportIndexesListTablePanel = document.getElementById('import_indexes_list_table_panel');
    const IndexesListTablePanel = document.getElementById('indexes_list_table_panel');


    const BaseHeight = window.innerHeight - TopHeight - BottomHeight;

    // console.log("TOP" + TopHeight)
    // console.log("Bottom: "+  BottomHeight);
    // console.log("window: "+ window.innerHeight);
    // console.log("Is Open: "+ isBottomOpen.length);
    // console.log("BaseHeight: "+ BaseHeight);
    // console.log("LeftPanelHeight: "+ LeftHeight);
    
    if(WrapperLeft.offsetHeight === BaseHeight){
        if(isBottomOpen.length){
          let NewHeight = BaseHeight - isBottomOpen[0].offsetHeight;
          [WrapperLeft, WrapperRight, LeftAdminPanel, RightAdminPanel].forEach((elem) => {
            elem.style.height = `${NewHeight}px`;
            elem.style.transitionDuration = '0.4s';
            elem.style.transitionDelay = '0.1s';
          });
        } else {
          // console.log("LEWY JEST RÓWNY MAX I ZAMKNIĘTE");
        }
      } else {
        if(isBottomOpen.length && WrapperLeft.offsetHeight !== BaseHeight - isBottomOpen[0].offsetHeight){
          let NewHeight = BaseHeight - isBottomOpen[0].offsetHeight;
          [WrapperLeft, WrapperRight, LeftAdminPanel, RightAdminPanel].forEach((elem) => {
            elem.style.height = `${NewHeight}px`;
          });
          RightAdminPanel.style.transitionDuration = '0.1s';
          LeftAdminPanel.style.transitionDuration = '0.1s';
        } else if(!isBottomOpen.length){
          [WrapperLeft, WrapperRight, LeftAdminPanel, RightAdminPanel].forEach((elem) => {
            elem.style.height = `${BaseHeight}px`;
            elem.style.transitionDuration = '0.3s';
          });
        }
      }


    const isLeftPinActive = document.getElementsByClassName('left_pin_active').item(0);
    const isRightPinActive = document.getElementsByClassName('right_pin_active').item(0);
    const MainPanelScrolling = document.getElementById('mainAdminPanelScroller');
    const rightAndLeftWrapperWidth = LeftWidth + RightWidth;
    const MainPanelScrrollingHeight = window.innerHeight - TopHeight - BottomHeight - ModulesBar.offsetHeight;
    if(isBottomOpen.length){
        const OpenBottomHeight = isBottomOpen.item(0).offsetHeight;
        MainPanelScrolling.style.height = `${MainPanelScrrollingHeight - OpenBottomHeight}px`;
    }else MainPanelScrolling.style.height = `${MainPanelScrrollingHeight}px`;



if (isLeftPinActive && isRightPinActive){
  const mainNewWidth = window.innerWidth - LeftAdminPanel.offsetWidth - RightAdminPanel.offsetWidth - rightAndLeftWrapperWidth;
  if(ModulesBar){
      ModulesBar.style.width = `${mainNewWidth}px`;
      ModulesBar.style.marginRight = `${RightAdminPanel.offsetWidth}px`;
      ModulesBar.style.marginLeft = `${LeftAdminPanel.offsetWidth}px`;
  }
  if(ImportIndexesListTablePanel){
      ImportIndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  if(IndexesListTablePanel){
    IndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  MainPanelScrolling.style.width = `${mainNewWidth}px`;
  MainPanelScrolling.style.marginRight = `${RightAdminPanel.offsetWidth}px`;
  MainPanelScrolling.style.marginLeft = `${LeftAdminPanel.offsetWidth}px`;
}
else if(!isLeftPinActive && isRightPinActive){
  const mainNewWidth = window.innerWidth - RightAdminPanel.offsetWidth - rightAndLeftWrapperWidth;
  if(ModulesBar){
      ModulesBar.style.width = `${mainNewWidth}px`;
      ModulesBar.style.marginRight = `${RightAdminPanel.offsetWidth}px`;
      ModulesBar.style.marginLeft = `0`;
  }
  if(ImportIndexesListTablePanel){
      ImportIndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  if(IndexesListTablePanel){
    IndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  MainPanelScrolling.style.width = `${mainNewWidth}px`;
  MainPanelScrolling.style.marginRight = `${RightAdminPanel.offsetWidth}px`;
  MainPanelScrolling.style.marginLeft = `0`;
}
else if(isLeftPinActive && !isRightPinActive){
  const mainNewWidth = window.innerWidth - LeftAdminPanel.offsetWidth - rightAndLeftWrapperWidth;
  if(ModulesBar){
      ModulesBar.style.width = `${mainNewWidth}px`;
      ModulesBar.style.marginRight = `0`;
      ModulesBar.style.marginLeft = `${LeftAdminPanel.offsetWidth}px`;
  }
  if(ImportIndexesListTablePanel){
      ImportIndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  if(IndexesListTablePanel){
    IndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  MainPanelScrolling.style.width = `${mainNewWidth}px`;
  MainPanelScrolling.style.marginRight = `0`;
  MainPanelScrolling.style.marginLeft = `${LeftAdminPanel.offsetWidth}px`;
}
else{
  const mainNewWidth = window.innerWidth - rightAndLeftWrapperWidth;
  if(ModulesBar){
      ModulesBar.style.width = `${mainNewWidth}px`;
      ModulesBar.style.marginRight = `0`;
      ModulesBar.style.marginLeft =  `0`;
  }
  if(ImportIndexesListTablePanel){
      ImportIndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  if(IndexesListTablePanel){
    IndexesListTablePanel.style.width = `${mainNewWidth}px`;
  }
  MainPanelScrolling.style.width = `${mainNewWidth}px`;
  MainPanelScrolling.style.marginRight = `0`;
  MainPanelScrolling.style.marginLeft = `0`;
}


//INDEX MANAGER


const IndexesManagerContent = document.getElementById('IndexesManagerContent')
const IndexesManagerFieldPanel = document.getElementById('IndexesManagerFieldPanel')
const IndexesManagerFieldPanelActions = document.getElementById('IndexesManagerFieldPanelActions')
const IndexeManagerOperationsFieldTopPanel = document.getElementById('IndexeManagerOperationsFieldTopPanel')
const BoxGroupFieldContainer = document.querySelectorAll('.BoxGroupFieldContainer')
const IndexesManagerAttributesFieldContainer = document.getElementById('IndexesManagerAttributesFieldContainer')
const IndexesManagerOperationsFieldContainer = document.getElementById('IndexesManagerOperationsFieldContainer')

if(IndexesManagerContent){
  IndexesManagerContent.style.height = `${MainPanelScrolling.offsetHeight}px`;
  IndexesManagerAttributesFieldContainer.style.height = `${MainPanelScrolling.offsetHeight}px`;
  IndexesManagerOperationsFieldContainer.style.height = `${MainPanelScrolling.offsetHeight}px`;
}


if(IndexesManagerFieldPanel){
  let newHeight = BaseHeight - IndexesManagerFieldPanelActions.offsetHeight - IndexeManagerOperationsFieldTopPanel.offsetHeight - ModulesBar.offsetHeight;
  if(isBottomOpen.length === 1){
    IndexesManagerFieldPanel.style.height=`${MainPanelScrolling.offsetHeight - IndexesManagerFieldPanelActions.offsetHeight - IndexeManagerOperationsFieldTopPanel.offsetHeight}px`;
    if(BoxGroupFieldContainer.length>0){
      BoxGroupFieldContainer.forEach((el)=>{
        el.style.height = `${MainPanelScrolling.offsetHeight - IndexesManagerFieldPanelActions.offsetHeight - IndexeManagerOperationsFieldTopPanel.offsetHeight}px`;
      })
    }
  }
  else{
    IndexesManagerFieldPanel.style.height=`${newHeight}px`;
    if(BoxGroupFieldContainer.length>0){
      BoxGroupFieldContainer.forEach((el)=>{
        el.style.height = `${newHeight}px`;
      })
    }
  }
}


const CategoryEditOptionsField =  document.querySelectorAll('.CategoryEditOptionsField');
if(CategoryEditOptionsField.length>0){
  CategoryEditOptionsField.forEach((el)=>{
    el.style.height = `${MainPanelScrrollingHeight}px`;
  })
}
const ProductEditOptionsField =  document.querySelectorAll('.ProductEditOptionsField');
if(ProductEditOptionsField.length>0){
  ProductEditOptionsField.forEach((el)=>{
    el.style.height = `${MainPanelScrrollingHeight}px`;
  })
}
const ProductEditFields =  document.querySelectorAll('.ProductEditFields');
if(ProductEditFields.length>0){
  ProductEditFields.forEach((el)=>{
    el.style.height = `${MainPanelScrrollingHeight}px`;
  })
}

const BlogPostsContentComponent = document.querySelector('.BlogPostsContentComponent');
if(BlogPostsContentComponent){
  BlogPostsContentComponent.style.height = `${MainPanelScrrollingHeight}px`;
}
const CategoryOptionsField = document.querySelector('.CategoryOptionsField');
if(CategoryOptionsField){
  CategoryOptionsField.style.height = `${MainPanelScrrollingHeight}px`;
}
const BlogPostsComponent = document.querySelector('.BlogPostsComponent');
if(BlogPostsComponent){
  BlogPostsComponent.style.height = `${MainPanelScrrollingHeight}px`;
}

const CategoryFields = document.getElementById('CategoryFields')
if(CategoryFields){
  CategoryFields.style.height = `${MainPanelScrrollingHeight}px`;
}

const CategoryEditFields = document.querySelectorAll('.CategoryEditFields')
if(CategoryEditFields.length>0){
  CategoryEditFields.forEach((el)=>{
    el.style.height = `${MainPanelScrrollingHeight}px`;
  })
}

const WarehouseContainer = document.getElementById('WarehouseContainer')
const WarehouseHeadersContainer = document.getElementById('WarehouseHeadersContainer')
if(WarehouseContainer){
  WarehouseHeadersContainer.style.width = `${WarehouseContainer.offsetWidth-5}px`;
}





//MODULES BAR MECHANICS
if(ModulesBarList){
  const barWidth = ModulesBarList.offsetWidth;
  let barItemsWidth = 0;
  const barItems = document.querySelectorAll('.modulesBar_list_item')
  barItems.forEach((item)=>{
    barItemsWidth = barItemsWidth + item.offsetWidth;
  })
  if(barWidth < barItemsWidth){
    console.log('overloaded')
  }
}


}
export default Calculate;