import styles from "./AttributesGroupSettings.module.css";
import { useContext, useState } from 'react';
import AdminPanelContext from '../../../../../Context/AdminPanelContext';
import { Autocomplete, Button, FormControlLabel, Grid, Input, InputLabel, Switch, TextField } from '@mui/material';
import PostMethod from '../../../../FetchMethods/PostMethod';
import {UPDATE_ATTRIBUTES_GROUP_URL } from "../../../../../Constants";
import { useEffect } from "react";

const AttributesGroupSettings =(props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [token, setToken] = useState();
    const [namePl, setNamePl] = useState();
    const [nameEn, setNameEn] = useState();
    const [active, setActive] = useState(false);
    const [autoOpen, setAutoOpen] = useState(false);
    const [pill, setPill] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [sortBy, setSortBy] = useState('')
    const [priority, setPriority] = useState()
    
    
    useEffect(()=>{
        props.data.token !== ""? setToken(props.data.token) : setToken('');  
        setNamePl(props.data.assets.name.pl);
        props.data.assets.name.en !== null ? setNameEn(props.data.assets.name.en) : setNameEn("");
        setActive(props.data.active)
        setAutoOpen(props.data.settings.autoopen);
        setPill(props.data.settings.pill);
        setHidden(props.data.settings.hidden);
        props.data.settings.sort!== undefined ? setSortBy(getSortOption(props.data.settings.sort)) : setSortBy('');
        setPriority(props.data.settings.priority);
    },[props.data])
    
    const sortByOptionsValues={
        "afabetycznie":'alphabet',
        "wartość (liczba)":'value',
        "ilość ofert":'quantity'
    }
    const sortByOptions=[
        {label:"afabetycznie"},
        {label:"wartość (liczba)"},
        {label:"ilość ofert"}
    ]
    const getSortOption=(name)=>{
        if(name){
            if(name === 'alphabet') return sortByOptions[0].label;
            else if(name === 'value') return sortByOptions[1].label;
            return sortByOptions[2].label;
        }
        return null
    }
    
    const submitHandler =(e) =>{
        e.preventDefault();
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            id:props.data.id,
            bulkId:props.data.bulkId,
            active:active==0? 0 : 1,
            assets:{
                name:{
                    pl:props.nameEditing,
                    en:nameEn
                }
            },
            token:token,
            settings:{
                priority:priority,
                autoopen:autoOpen,
                pill:pill,
                hidden:hidden,
                sort:sortByOptionsValues[sortBy]
            }
        }
        PostMethod(UPDATE_ATTRIBUTES_GROUP_URL, data, getResponse)
    }
    const getResponse = (data)=>{
        if(data){
            props.reload();
            console.log('posted');
        }
    }

    return(
        <div className={styles.attributes_group_form}>
            <form>
                <div className={styles.attributes_group_inputs}>
                    <Grid container>
                        <Grid container item xs={4}>
                            <Grid item xs={11} m={2} sx={{backgroundColor:admin_panel_ctx.color2, borderRadius:'10px'}} textAlign={'left'} p={3} py={2}>
                                <Grid item xs={12}><TextField className={styles.attributes_group_input} sx={{label: {color: admin_panel_ctx.cText.negative}, input: {color: admin_panel_ctx.cText.negative}}} label="Token" value={token} onChange={(e)=>setToken(e.target.value)} fullWidth={true}/></Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={11} m={2} sx={{backgroundColor:admin_panel_ctx.color2, borderRadius:'10px'}} textAlign={'left'} p={3} py={2}>
                                <Grid item xs={12}><Autocomplete options={sortByOptions} value={sortBy} onInputChange={(e, newInput)=>setSortBy(newInput)} renderInput={(params) => <TextField {...params} label="Sortowanie" sx={{label: {color: admin_panel_ctx.cText.negative}, input: {color: admin_panel_ctx.cText.negative}}} fullWidth={true} />}/></Grid>
                                <Grid item xs={12}><FormControlLabel sx={{color:admin_panel_ctx.cText.negative}} control={<Switch sx={{margin:'0 !important'}} className={styles.attributes_group_input} checked={active} onChange={(e)=>setActive(e.target.checked)}/>} label={active ? "aktywny":"nieaktywny"}/></Grid>
                                <Grid item xs={12}><FormControlLabel sx={{color:admin_panel_ctx.cText.negative}} control={<Switch sx={{margin:'0 !important'}} className={styles.attributes_group_input} checked={autoOpen} onChange={(e)=>setAutoOpen(e.target.checked)}/>} label={autoOpen ? "autootwieranie aktywne":"autootwieranie nieaktywne"}/></Grid>
                                <Grid item xs={12}><FormControlLabel sx={{color:admin_panel_ctx.cText.negative}} control={<Switch sx={{margin:'0 !important'}} className={styles.attributes_group_input} checked={pill} onChange={(e)=>setPill(e.target.checked)}/>} label={pill ? "pill":"no-pill"}/></Grid>
                                <Grid item xs={12}><FormControlLabel sx={{color:admin_panel_ctx.cText.negative}} control={<Switch sx={{margin:'0 !important'}} className={styles.attributes_group_input} checked={hidden} onChange={(e)=>setHidden(e.target.checked)}/>} label={hidden ? "ukryte":"nieukryte"}/></Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={11} m={2} sx={{backgroundColor:admin_panel_ctx.color2, borderRadius:'10px'}} textAlign={'left'} p={3} py={2}>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} textAlign={"right"}>
                            <Button color='info' variant="contained" size="small" onClick={submitHandler}>Zapisz</Button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    )
}

export default AttributesGroupSettings;