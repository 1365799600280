import { Grid } from "@mui/material";
import CategoryContext from "../../../../../../Context/CategoryContext";
import { useState } from "react";
import CategoryOptions from "./CategoryOptions/CategoryOptions";
import CategoryFields from "./CategoryFields/CategoryFields";

const Category = ()=> {
    const [currentField, setCurrentField] = useState(1);
    const [mainCategories, setMainCategories] = useState([])

    return (
        <CategoryContext.Provider
            value={{
                currentField,
                mainCategories,
                setCurrentField,
                setMainCategories
            }}
        >
            <Grid container className="zoomOnShow">
                <Grid item xs={2}><CategoryOptions/></Grid>
                <Grid item xs={10}><CategoryFields/></Grid>
            </Grid>
        </CategoryContext.Provider>
    )
}

export default Category;