import { Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { GET_ATTRIBUTES_URL, SET_ATTRIBUTE_URL, UPDATE_ATTRIBUTE_NAME_URL, UPDATE_ATTRIBUTE_URL } from "../../../../Constants";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import PostMethod from "../../../FetchMethods/PostMethod";
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import SettingsIcon from '@mui/icons-material/Settings';

const Attributes=(props)=>{

    const admin_panel_ctx = useContext(AdminPanelContext);
    const [attributes, setAttributes] = useState();
    const [addingAttribute, setAddingAttribute] = useState();
    const [attributeNameEditing, setAttributeNameEditing] = useState();


    useEffect(()=>{
        getAttributesForGroup();
    },[props.groupId]);

    useEffect(()=>{
        const setAtributeName=setTimeout(()=>{
                if(attributeNameEditing){
                    attributes[attributeNameEditing['id']].assets.name.pl = attributeNameEditing['value'];
                    attributes[attributeNameEditing['id']]['db'] = JSON.parse(localStorage.getItem("currentOrganization")).config.database;
                    attributes[attributeNameEditing['id']].active ? attributes[attributeNameEditing['id']].active = 1 : attributes[attributeNameEditing['id']].active = 0;
                    const data = attributes[attributeNameEditing['id']]
                PostMethod(UPDATE_ATTRIBUTE_URL,data,getResponseHandler)
            }},1000)
        return ()=>{
            clearTimeout(setAtributeName);
        }
    },[attributeNameEditing])


    const getResponseHandler=(data)=>{
        getAttributesForGroup();
    }

    const getAttributesForGroup=()=>{
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            groupId:props.groupId
        }
        PostMethod(GET_ATTRIBUTES_URL,data,getResponse)
    }

    const getResponse=(data)=>{
        if(JSON.stringify(data) !== "{}"){
            setAttributes(data);
        }
    };


    const NewAttributeElement = (props) =>{
        const newAttributeElementRef = useRef();
        return(
            <Paper
                component="form"
                onSubmit={(e)=>getNewAttributeElementInput(e, props.groupId, newAttributeElementRef.current.value)}
                sx={{ mt:'2px', mb:'20px',p: '2px 4px', display: 'flex', alignItems: 'center', cursor:'pointer', backgroundColor:admin_panel_ctx.cSheet.default }}
                >
                    <IconButton sx={{ p: '10px', color:'silver' }} aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <InputBase sx={{ ml: 1, flex: 1, color:'white' }} placeholder={"Wpisz nazwę atrybutu ..."} autoFocus={true} inputRef={newAttributeElementRef} onBlur={(e)=>getNewAttributeElementInput(e, props.groupId)} />
            </Paper>
        )
    }
    const getNewAttributeElementInput = (e, groupId, formInputValue=null) =>{
        e.preventDefault();
        if(e.target.value || formInputValue){
            const data={
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                groupId:groupId,
                active:false,
                assets:{
                    name:{
                        pl:e.target.value? e.target.value : formInputValue,
                        en:null
                    }
                },
                token:null,
                settings:{
                    priority:0,
                }
            }

            PostMethod(SET_ATTRIBUTE_URL,data,getNewAttributeResponse)
        }
        setAddingAttribute(false);
    }
    const getNewAttributeResponse = (data)=>{
        if(data){
            getAttributesForGroup();
        }
    }

    return(
        <>
        <Grid container alignItems={'center'} justifyContent={"center"} pb={3} mt={3} borderRadius={2} sx={{backgroundColor:admin_panel_ctx.color2}}>
                <Grid container item mt={1} rowSpacing={2} xs={12} mx={2}>
                    <Grid container item xs={12}>
                        <Grid container item xs={8} alignItems={'center'}>
                            <img src="/images/flags/pl.png" width={'30px'} style={{border:'1px solid gray'}}/>
                        </Grid>
                        <Grid item xs={4} textAlign={'right'}>
                            <Fab style={{width:35, height:25}} color='info' variant="contained" size="small" onClick={()=>setAddingAttribute(true)}><AddIcon/></Fab>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!attributes && !addingAttribute && <h4>Brak atrybutów dla tej grupy</h4>}
                        {addingAttribute && <NewAttributeElement groupId={props.groupId}/>}
                        {attributes && 
                                <TableContainer component={Paper} sx={{backgroundColor:admin_panel_ctx.cPanel.default}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Id</TableCell>
                                                <TableCell align="left"><Typography>Nazwa</Typography></TableCell>
                                                <TableCell align="center">Akcje</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    <TableBody>
                                    {Object.keys(attributes).map((element, i)=>(

                                        <TableRow key={attributes[element].id}>
                                        <TableCell component="th" scope="row" align="center">{attributes[element].id}</TableCell>
                                        <TableCell align="left">
                                            <Paper
                                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
                                            >
                                                <InputBase
                                                    sx={{ ml: 1, flex: 1 }}
                                                    fullWidth={true}
                                                    value={ attributeNameEditing &&  attributeNameEditing['id'] === element ? attributeNameEditing['value'] : attributes[element].assets.name.pl}
                                                    onChange={(e)=>setAttributeNameEditing({id:element,value:e.target.value})}
                                                    onKeyUp={(e)=>{if(e.keyCode === 13) e.target.blur()}}
                                                />
                                            </Paper>
                                        
                                        </TableCell>
                                        <TableCell align="center">{<SettingsIcon style={{color:'gray'}}/>}</TableCell>
                                    </TableRow>
                                    ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                        }
                    </Grid>
                </Grid>
        </Grid>
        </>

    )
}
export default Attributes;