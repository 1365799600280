import styles from "../AuthForm.module.css";
import {useRef, useEffect, useContext} from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AuthTemplate from "../AuthTemplate/AuthTemplate";
import { TextField, Typography } from "@mui/material";
import SignUpPostDataHandler from "../../FetchMethods/SignUpPostDataHandler";
import {SIGN_UP_URL } from "../../../Constants";
import CookiesContext from "../../../Context/CookiesContext";

const SignUp = () => {

    const enteredEmail = useRef();
    const enteredPassword = useRef();
    const cookies_ctx = useContext(CookiesContext);
    let navigate = useNavigate();

    useEffect(()=>{
        if(typeof(cookies_ctx.GetCookie("token")) === 'string') return navigate('/dashboard');
    })
    const onSubmitHandler = event =>{
        event.preventDefault();
        let data = {
            email:enteredEmail.current.value,
            password:enteredPassword.current.value,
        };
        SignUpPostDataHandler(SIGN_UP_URL, data, responseHandler)
    }
    const responseHandler = (response) => {
        if (response.ok){
            console.log("Registered Successfully");
        }
        else alert("Provide valid information");
    }

    return (
        <AuthTemplate>
            <Typography className={styles.typography} variant="h2" color="white" >Sign up</Typography>
            <form onSubmit={onSubmitHandler}>
                <div className={styles.login_controls}>
                    <div className={styles.login_inputs}>
                        <TextField className={styles.login_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="Email" minLength={10} variant="standard" type="email" inputRef={enteredEmail} required={true}/>
                        <TextField className={styles.login_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="Password" pattern ="(?=.*\d)(?=.*[\W_]).{5,}" variant="standard" type="password" inputRef={enteredPassword} required={true}/>
                    </div>
                    <div>Already have an account ? <Link to="/login" className={styles.sign_in_href}>Log in</Link></div>
                </div>
                <div className={styles.login_actions}>
                    <Button color='info' variant="contained" size="large" type="submit">Sign up</Button>
                </div>
            </form>
        </AuthTemplate>
    )
}

export default SignUp;