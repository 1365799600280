import { Grid, Typography, Button, TextField, Paper } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GET_INDEX_ATTRIBUTES_URL } from "../../../../../../../Constants";
import AdminPanelContext from "../../../../../../../Context/AdminPanelContext";
import IndexesManagerContext from "../../../../../../../Context/IndexesManagerContext";
import Calculate from "../../../../../../AdminPanel/Calculate";
import PostMethod from "../../../../../../FetchMethods/PostMethod";
import IndexesManagerBoxGroupField from "../../IndexesManagerBoxGroupField/IndexesManagerBoxGroupField";
import styles from "./IndexesManagerGroupField.module.css";
import {MdOutlineNoPhotography} from "react-icons/md";

const IndexesManagerGroupField = (props) =>{
    const admin_panel_ctx = useContext(AdminPanelContext)
    const indexes_manager_ctx = useContext(IndexesManagerContext);
    const [attributesToRefresh, setAttributesToRefresh] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [checkedProductsToFilter, setCheckedProductsToFilter] = useState([]);

useEffect(() => {
  const tableOfPhrases = searchInput.toLowerCase().split(' ');
  const productsInGroup = document.querySelectorAll('.ProductInGroup');
  const listOfProductToSet = [];

  productsInGroup.forEach(product => {
    const parentContainer = product.parentNode.parentNode;
    const show = tableOfPhrases.every(splittedPhrase => product.innerHTML.toLowerCase().includes(splittedPhrase));

    parentContainer.style.display = show ? 'block' : 'none';

    if (show) {
      listOfProductToSet.push(parseInt(product.dataset.id));
    }
  });

  setCheckedProductsToFilter(listOfProductToSet);
}, [searchInput]);

    const getAttributesForProduct = () => {
        const listOfIds = indexes_manager_ctx.productsInGroup.map(prod => prod.id);
        const data = {
        db: JSON.parse(localStorage.getItem("currentOrganization")).config.database,
        language: 'pl',
        index: listOfIds,
        };
        PostMethod(GET_INDEX_ATTRIBUTES_URL, data, getAttributesResponse);
    };

    const getAttributesResponse=(response)=>{
        let currentAttributesInGroupList=[];
        let currentAttributesInGroupListIds = [];
        if(response.data){
            Object.keys(response.data).map(atr=>{
                currentAttributesInGroupList.push(response.data[atr])
                currentAttributesInGroupListIds.push(response.data[atr].id)
            })
        }
        let intersection = indexes_manager_ctx.removeAttributesInGroupChecked.filter(x => currentAttributesInGroupListIds.includes(x));
        indexes_manager_ctx.setRemoveAttributesInGroupChecked(intersection)
        indexes_manager_ctx.setAttributesListInProductsInGroup(currentAttributesInGroupList);
        setAttributesToRefresh(currentAttributesInGroupList);
    }

    const removeFromGroup = () => {
        const productsListToSet = indexes_manager_ctx.productsInGroup.filter(product => !props.chosenProductsInGroup.includes(product.id) || checkedProductsToFilter.indexOf(product.id) === -1);
        indexes_manager_ctx.setProductsInGroup(productsListToSet);
        props.setChosenProductsInGroup([]);
      }

    useEffect(()=>{
        getAttributesForProduct();
    },[indexes_manager_ctx.productsInGroup.length])

    useEffect(()=>{
        getAttributesForProduct();
        Calculate();
    },[])

    useEffect(()=>{
        refreshAttributesInGroup();
        if(indexes_manager_ctx.productsInGroup.length === 0) indexes_manager_ctx.setAttributesListInGroupToAdd([])
    },[attributesToRefresh])

    const addingToChosenInGroup = (id)=>{
        if(props.chosenProductsInGroup.indexOf(id) === -1 && indexes_manager_ctx.isControlPressed){
            let productsToSet = props.chosenProductsInGroup;
            productsToSet.push(id)
            props.setChosenProductsInGroup(productsToSet)
        }
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }
    const removeFromChosenInGroup = (id)=>{
        if(props.chosenProductsInGroup.indexOf(id) !== -1 && indexes_manager_ctx.isControlPressed){
            let productsToSet = props.chosenProductsInGroup;
            productsToSet.splice(props.chosenProductsInGroup.indexOf(id),1)
            props.setChosenProductsInGroup(productsToSet)
        }
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }

    const refreshAttributesInGroup = () => {
        const newList = indexes_manager_ctx.attributesListInGroupToAdd;
        const difference = newList.filter(x => !indexes_manager_ctx.attributesListInProductsInGroup.map(x => x.id).includes(x.id));
        indexes_manager_ctx.setAttributesListInGroupToAdd(difference);
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer + 1);
      }


    const ProductInGroupElement = ({product}) =>{
        return(
            <Paper className={`${styles.product_in_group_element} ${indexes_manager_ctx.isControlPressed && styles.pointer}`} onClick={()=>{addingToChosenInGroup(product.id)}}>
                <div style={{backgroundColor:'white', marginBottom:'5px', borderRadius:'3px', display:'flex', justifyContent:'center'}}>
                {product.photo ? <img style={{ maxWidth:'100%', borderRadius:'3px', height:'auto', maxHeight:'100px'}} alt="product" src={`${product.photo.path}${product.photo.filename}`}/> : <div style={{height:'70px', color:'black', paddingTop:'30px'}}><MdOutlineNoPhotography size={40}/></div>}
                </div>
                <Typography fontSize={10} sx={{height:'50px'}} className={'ProductInGroup'} data-id={product.id}>{`${product.name} (${product.id})`}</Typography>
            </Paper>
        )
    }
    const ChosenProductInGroupElement = ({product}) =>{
        return(
            <Paper className={`${styles.chosen_product_in_group_element} ${indexes_manager_ctx.isControlPressed && styles.pointer}`} onClick={()=>{removeFromChosenInGroup(product.id)}}>
                <div style={{backgroundColor:'white', marginBottom:'5px', borderRadius:'3px', display:'flex', justifyContent:'center'}}>{product.photo ? <img style={{ maxWidth:'100%', borderRadius:'3px', height:'auto', maxHeight:'100px'}} alt="product" src={`${product.photo.path}${product.photo.filename}`}/> : <div style={{height:'70px', color:'black', paddingTop:'30px'}}><MdOutlineNoPhotography size={40}/></div>}</div>
                <Typography fontSize={10} sx={{height:'50px'}} className={'ProductInGroup'} data-id={product.id}>{`${product.name} (${product.id})`}</Typography>
            </Paper>
        )
    }


    return(
        <Grid container>
            <Grid container item xs={12} p={2} sx={{backgroundColor:admin_panel_ctx.cSheet.default}} id={"IndexesManagerFieldPanelActions"}>
                <Grid item xs={3}><TextField placeholder="Szukaj..." fullWidth value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}/></Grid>
                <Grid container item xs={5} alignItems={'center'} justifyContent={'center'}>
                    <Typography>Wszystkie:</Typography>
                    <Button sx={{color:"white", paddingBottom:0, alignItems:'center', justifyContent:'center', paddingTop:'3px'}} onClick={()=>props.setAllInGroupToChosen()}>zaznacz</Button>
                    <Typography>/</Typography>
                    <Button sx={{color:"white", paddingBottom:0, alignItems:'center', justifyContent:'center', paddingTop:'3px'}} onClick={()=>{props.setChosenProductsInGroup([]); indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)}}>odznacz</Button>
                </Grid>
                <Grid container item xs={4} alignItems={'center'} justifyContent={'center'}>
                    <Button variant="contained" onClick={()=>{removeFromGroup()}}>- usuń z grupy</Button>
                </Grid>
            </Grid>
            <Grid container item xs={8} height={'200px'} className={'scrollerY'} sx={{overflow:'auto', display:'inline-block'}} p={1}  id={"IndexesManagerFieldPanel"}>
            {(indexes_manager_ctx.productsInGroup && indexes_manager_ctx.productsInGroup.length !== 0) ? 
            <Grid container item sx={{overflow:'hidden'}}>
                {Object.keys(indexes_manager_ctx.productsInGroup).map(prod=>{
                    return(<Grid item xs={(admin_panel_ctx.leftPinActive || admin_panel_ctx.rightPinActive) ? 3 : 2} key={indexes_manager_ctx.productsInGroup[prod].id} p={1}>
                                {props.chosenProductsInGroup.indexOf(indexes_manager_ctx.productsInGroup[prod].id) === -1 ?  <ProductInGroupElement product={indexes_manager_ctx.productsInGroup[prod]}/> : <ChosenProductInGroupElement product={indexes_manager_ctx.productsInGroup[prod]}/>}
                            </Grid>)
                })}
            </Grid>
            :
            <Grid item xs={12} textAlign={'center'}><Typography fontSize={20}>Brak produktów w grupie</Typography></Grid>
        }
            </Grid>
            {indexes_manager_ctx.boxGroupField !== 0 && <Grid item xs={4} className="BoxGroupFieldContainer"><IndexesManagerBoxGroupField/></Grid>}
        </Grid>

    )
}
export default IndexesManagerGroupField;