import { Grid } from "@mui/material";

const WarehouseTopPanel =() => {

    return (
        <Grid container>
            <Grid item pr={2}>Opcje cenowe</Grid>
            <Grid item pr={2}>Opcje cenowe 2</Grid>
            <Grid item pr={2}>Opcje cenowe 3</Grid>
        </Grid>
    );

};

export default WarehouseTopPanel;