import { Divider, Grid, Typography } from "@mui/material";

const BlogPostsContentComponent =()=>{
    const headers = [
        {label:'LP', width:'15'},
        {label:'Tytuł', width:'75'},
        {label:'Indexowanie', width:'10'}
        ]
    return (

        <Grid className={'BlogPostsContentComponent'} sx={{backgroundColor:'gray'}}>
                <Grid container item xs={12} className='scrollerY' sx={{overflowY:'auto', height:'100%'}} py={3} px={1}>
                    {headers.map((header)=>{
                        return <div style={{width:`${header.width}%`}}><Typography textAlign={'center'}>{header.label}</Typography></div>
                    })}
                </Grid>
            </Grid>
    )
}
export default BlogPostsContentComponent;