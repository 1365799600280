import { Switch } from "@mui/material";
import { useState } from "react";
import { WAREHOUSE_SET_ITEM_ACTIVITY_URL } from "../../../../../../../../Constants";
import PostMethod from "../../../../../../../FetchMethods/PostMethod";

const IndexesListModuleTableSwitchComponent =(props)=>{
    const [isActive, setIsActive] = useState(props.active)

    const handleActiveChange = () =>{
        setIsActive(!isActive)
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            index: props.id,
            active: !isActive,
        }
        PostMethod(WAREHOUSE_SET_ITEM_ACTIVITY_URL,data,()=>{})
    }

    return (
        <Switch checked={isActive} onChange={handleActiveChange}/>
    )
}
export default IndexesListModuleTableSwitchComponent;