import Calculate from "./Calculate";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CookiesContext from "../../Context/CookiesContext";
import LeftAdminPanel from "./LeftAdminPanel/LeftAdminPanel";
import MainAdminPanel from "./MainAdminPanel/MainAdminPanel";
import React, { useEffect, useState, useContext } from "react";
import AdminPanelContext from "../../Context/AdminPanelContext";
import RightAdminPanel from "./RightAdminPanel/RightAdminPanel";
import BottomAdminPanel from "./BottomAdminPanel/BottomAdminPanel";
import AdminPanelWrapper from "./AdminPanelWrapper/AdminPanelWrapper";
import styles from "./AdminPanel.module.css";
import ModulesBar from "./ModulesBar/ModulesBar";

const AdminPanel = () =>{

    const [isLeftUpOpen, setIsLeftUpOpen] = useState(false);
    const [isLeftDownOpen, setIsLeftDownOpen] = useState(false);
    const [isRightUpOpen, setIsRightUpOpen] = useState(false);
    const [isRightDownOpen, setIsRightDownOpen] = useState(false);
    const [isBottomLeftOpen, setIsBottomLeftOpen] = useState(false);
    const [isBottomRightOpen, setIsBottomRightOpen] = useState(false);
    const [leftPinActive, setLeftPinActive] = useState(false);
    const [rightPinActive, setRightPinActive] = useState(false);

    //MODULES
    const [currentOpenModule, setCurrentOpenModule] = useState();
    const [reloadModulesBar, setReloadModulesBar] = useState(0);
    
    const [isAttributesOpen, setIsAttributesOpen] = useState(false);
    const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
    const [isImportIndexesListOpen, setIsImportIndexesListOpen] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isIndexesListOpen, setIsIndexesListOpen] = useState(false);

    const [reloadProducts, setReloadProducts] = useState(false);
    const [reloadProductsEdit, setReloadProductsEdit] = useState(false);
    const [reloadProductsEditUri, setReloadProductsEditUri] = useState(false);
    
    const [isIndexesAttributesManagerOpen, setIsIndexesAttributesManagerOpen] = useState(false);

    const [isBlogPostsOpen, setIsBlogPostsOpen] = useState(false);

    const getColor = usage =>{
        const org = JSON.parse(localStorage.getItem("currentOrganization"));
        let color=null;
        if("colors" in org.config){
          if(usage==="text") color = org.config.colors.cText;
          else if(usage==="sheet") color = org.config.colors.cSheet;
          else if(usage==="panel") color = org.config.colors.cPanel;
          else if(usage==="element") color = org.config.colors.cElement;
          else if(usage==="color1") color = org.config.colors.color1;
          else if(usage==="color2") color = org.config.colors.color2;
          else if(usage==="color3") color = org.config.colors.color3;
        }
        return color;
    }

    const [colorRoot, setColorRoot] = useState(getColor('root') ? getColor('text') : '#6B7575');
    const [color1, setColor1] = useState(getColor('color1') ? getColor('color1') : '#3C3F41');
    const [color2, setColor2] = useState(getColor('color2') ? getColor('color2') : '#232525');
    const [color3, setColor3] = useState('');

    useEffect(()=>{
      const root = document.getElementById("root");
      root.style.backgroundColor = colorRoot;
    },[colorRoot])

    const [cTextHead, setCTextHead] = useState({default:'black', negative:'silver'});
    const [cTextTitle, setCTextTitle] = useState({default:'black', negative:'silver'});

    const [cText, setCText] = useState(getColor('text') ? getColor('text') : {default:'black', negative:'silver'});
    const [cSheet, setCSheet] = useState(getColor('sheet') ? getColor('sheet') : {default:'#4C4F51', negative:'#232525'});
    const [cPanel, setCPanel] = useState(getColor('panel') ? getColor('panel') : {default:'silver', negative:'green'});
    const [cElement, setCElement] = useState(getColor('element') ? getColor('element') : {default:'#232525', negative:'#434545'});
    
    const [cLabel, setCLabel] = useState({default:'red', negative:'green'});
    const [cButton, setCButton] = useState({default:'red', negative:'green'});



    const cookies_ctx = useContext(CookiesContext);
    let navigate = useNavigate();

    useEffect(() => {
      if(!cookies_ctx.GetCookie('token')){
        return navigate('/expected-logged-in');
      }
      else {
        Calculate();
        if(localStorage.getItem("ModulesBar")){
          let modules = JSON.parse(localStorage.getItem("ModulesBar"));
          modules.map((mod)=>{
            if(mod.label==="Attributes"){setIsAttributesOpen(true)}
            else if(mod.label==="Languages"){setIsLanguagesOpen(true)}
            else if(mod.label==="ImportIndexesList"){setIsImportIndexesListOpen(true)}
            else if(mod.label==="IndexesList"){setIsIndexesListOpen(true)}
            else if(mod.label==="IndexesAttributesManager"){setIsIndexesAttributesManagerOpen(true)}
            else if(mod.label==="Category"){setIsCategoryOpen(true)}
            else if(mod.label==="BlogPosts"){setIsBlogPostsOpen(true)}
          })
        }
      }
    });

    window.addEventListener('resize', Calculate);

    const ClosePanels =() =>{
      setIsLeftUpOpen(false);
      setIsLeftDownOpen(false);
      setIsRightUpOpen(false);
      setIsRightDownOpen(false);
      setIsBottomLeftOpen(false);
      setIsBottomRightOpen(false);
    }

    const IfAnyModuleOpen =(JSON.parse(localStorage.getItem("ModulesBar")) && JSON.parse(localStorage.getItem("ModulesBar")).length>0);


    const isScrollable =() =>{
      let result=true;
      if(JSON.parse(localStorage.getItem("ModulesBar"))){
          let type;
          let active = JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.active===true);
          if(active){
            type = active.config.componentType;
          }
        if(type === "IndexesAttributesManager" || type === "ProductEdit" || type ==="Category") result = false;
      }
      return result
    }

    return (
        <AdminPanelContext.Provider
        value = {{
          isLeftUpOpen,
          isLeftDownOpen,
          isRightUpOpen,
          isRightDownOpen,
          isBottomLeftOpen,
          isBottomRightOpen,
          leftPinActive,
          rightPinActive,
          currentOpenModule,
          reloadModulesBar,
          //MODULES
          isAttributesOpen,
          isLanguagesOpen,
          isImportIndexesListOpen,
          isIndexesListOpen,
          reloadProducts,
          reloadProductsEdit,
          reloadProductsEditUri,
          colorRoot,
          color1,
          color2,
          color3,
          cTextHead,
          cTextTitle,
          cText,
          cSheet,
          cPanel,
          cLabel,
          cElement,
          cButton,
          IfAnyModuleOpen,
          isIndexesAttributesManagerOpen,
          isCategoryOpen,
          isBlogPostsOpen,
          setIsLeftUpOpen,
          setIsLeftDownOpen,
          setIsRightUpOpen,
          setIsRightDownOpen,
          setIsBottomLeftOpen,
          setIsBottomRightOpen,
          setLeftPinActive,
          setRightPinActive,
          setCurrentOpenModule,
          setReloadModulesBar,
          //MODULES
          setIsAttributesOpen,
          setIsLanguagesOpen,
          setIsImportIndexesListOpen,
          setIsIndexesAttributesManagerOpen,
          setIsIndexesListOpen,
          setReloadProducts,
          setReloadProductsEdit,
          setReloadProductsEditUri,
          setIsCategoryOpen,
          ClosePanels,
          setIsBlogPostsOpen,
          setColorRoot,
          setColor1,
          setColor2,
          setColor3,
          setCTextHead,
          setCTextTitle,
          setCText,
          setCSheet,
          setCPanel,
          setCLabel,
          setCElement,
          setCButton,
        }}>
          <AdminPanelWrapper/>
          <LeftAdminPanel/>
          <Grid item xs={12} className={styles.main_admin_panel_grid}>
            <ModulesBar/>
            <div id="mainAdminPanelScroller" className={`${isScrollable() ?'scrollerY':''} scrollerX ${styles.main_admin_panel_scroller}`} style={isScrollable() ? {overflowY:'auto'} :{}}><MainAdminPanel/></div>
          </Grid>
          <RightAdminPanel/>
          <BottomAdminPanel/>
        </AdminPanelContext.Provider>
      );
}

export default AdminPanel;