import { Button, TextField, Typography } from "@mui/material";
import { useRef } from "react";
import styles from "./AddNewBulkForm.module.css";
import { useContext } from "react";
import AdminPanelContext from "../../../../../Context/AdminPanelContext";
import { SET_BULKS_URL } from "../../../../../Constants";
import PostMethod from "../../../../FetchMethods/PostMethod";
const AddNewBulkForm = props =>{
    const admin_panel_ctx = useContext(AdminPanelContext)
    const token = useRef();
    const namePl = useRef();
    const nameEn = useRef();

    const submitHandler = e =>{
        e.preventDefault()
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            assets:{
                name:{
                    pl:namePl.current.value,
                    en:nameEn.current.value
                }
            },
            token:token.current.value,
            settings:{}
        }
        PostMethod(SET_BULKS_URL, data, getResponse);
    }
    const getResponse =(data)=>{
        if(data){
            props.reload();
            props.close();
        }
    }

    return (
        
        <div className={styles.add_new_bulk_form}>
            <form onSubmit={submitHandler}>
                <Typography fontSize={25}>Dodaj nowy obszar</Typography>
                <div className={styles.bulk_inputs}>
                    <TextField className={styles.bulk_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="Token" variant="standard" type="text" inputRef={token}/>
                    <TextField className={styles.bulk_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="nazwa PL" variant="standard" type="text" inputRef={namePl} required={true}/>
                    <TextField className={styles.bulk_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="nazwa EN" variant="standard" type="text" inputRef={nameEn}/>
                </div>
                <Button className={styles.add_new_bulk_button} color='info' variant="contained" size="small" type="submit">Dodaj</Button>
            </form>
        </div>
    )
}
export default AddNewBulkForm;