import React, { useContext }  from "react";
import styles from "./WrapperRight.module.css";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import ModulesBarManager from "../../../Modules/ModulesBarManager";
const WrapperRight = () =>{

    const admin_panel_ctx = useContext(AdminPanelContext);

    const setRightOpen =(direction, number)=>{
        const right_pin_active = document.getElementsByClassName('right_pin_active').item(0);
        if(localStorage.getItem('rightPin')!=null){
            admin_panel_ctx.setRightPinActive(true);
            localStorage.removeItem('rightPin');
        }
        if(direction === 'up'){
            if (admin_panel_ctx.isRightUpOpen !== number) admin_panel_ctx.setIsRightUpOpen(number);           
            else if (!admin_panel_ctx.isRightDownOpen && right_pin_active){
                admin_panel_ctx.setRightPinActive(false);
                admin_panel_ctx.setIsRightUpOpen(false);
            }
            else{
                admin_panel_ctx.setIsRightUpOpen(false);
            }
        }
        else{
            if (admin_panel_ctx.isRightDownOpen !== number) admin_panel_ctx.setIsRightDownOpen(number);
            else if (!admin_panel_ctx.isRightUpOpen && right_pin_active){
                admin_panel_ctx.setRightPinActive(false);
                admin_panel_ctx.setIsRightDownOpen(false);
            }
            else{
                admin_panel_ctx.setIsRightDownOpen(false);
            }
        }
    }

    const getOrganizationPrivileges = ()=>{
        if(JSON.parse(localStorage.getItem('currentOrganization'))){
            return JSON.parse(localStorage.getItem('currentOrganization')).config.privileges;
        }
    } 
    //APP
    const getOrganizationName=()=>{
        if(localStorage.getItem("currentOrganization")){
            return JSON.parse(localStorage.getItem("currentOrganization")).settings.name;
        }
    }
    const HandleAppClick =() =>{
        let modulesBar = JSON.parse(localStorage.getItem("ModulesBar"))
        if(modulesBar && modulesBar.find(x=>x.label==="APP")){
            ModulesBarManager("APP", "show");
        }
        else{
            ModulesBarManager("APP", "push", {componentType:"APP"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }
    //APP

    return(
        <div id={'wrapperRight'} className={styles.wrapper_right} style={{backgroundColor:admin_panel_ctx.color1}}>
            <div className={styles.wrapper_right_up}>
                <ul className={styles.wrapper_right_up_list}>
                    {getOrganizationPrivileges().cms.available && <li style={admin_panel_ctx.isRightUpOpen===1?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setRightOpen('up',1)}>CMS</li>}
                    {getOrganizationPrivileges().pim.available && <li style={admin_panel_ctx.isRightUpOpen===2?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setRightOpen('up',2)}>PIM</li>}
                    {getOrganizationName() === 'Warsztat24 Sp. z o.o.' && <li style={admin_panel_ctx.isRightUpOpen===3?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={HandleAppClick}>APP</li>}
                </ul>
            </div>
            <div className={styles.wrapper_right_down}>
                <ul className={styles.wrapper_right_down_list}>
                    {getOrganizationPrivileges().settings.available && <li style={admin_panel_ctx.isRightDownOpen===1?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setRightOpen('down',1)}>Ustawienia</li>}
                    {getOrganizationPrivileges().configure.available && <li style={admin_panel_ctx.isRightDownOpen===2?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setRightOpen('down',2)}>Konfiguracja</li>}
                </ul>
            </div>
        </div>
    )
}

export default WrapperRight;