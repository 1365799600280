import { Button, Checkbox, FormControl, Grid, Input, MenuItem, NativeSelect, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { GET_INDEX_ATTRIBUTES_URL, SEARCH_INDEX_MANAGER_PRODUCTS } from "../../../../../../Constants";
import AdminPanelContext from "../../../../../../Context/AdminPanelContext";
import IndexesManagerContext from "../../../../../../Context/IndexesManagerContext";
import PostMethod from "../../../../../FetchMethods/PostMethod";
import IndexesManagerFoundField from "./IndexesManagerFoundField/IndexesManagerFoundField";
import IndexesManagerGroupField from "./IndexesManagerGroupField/IndexesManagerGroupField";

const IndexesManagerOperationsField=() =>{
    const [isJustActiveChecked, setIsJustActiveChecked] = useState(false);
    const [isJustNewChecked, setIsJustNewChecked] = useState(false);
    const [selectValue, setSelectValue] = useState('or');
    const [searchName, setSearchName] = useState();
    const [searchSku, setSearchSku] = useState();
    const [priceFrom, setPriceFrom] = useState();
    const [priceTo, setPriceTo] = useState();
    const [foundProducts, setFoundProducts] = useState();
    const [chosenProducts, setChosenProducts] = useState([])
    const [chosenProductsInGroup, setChosenProductsInGroup] = useState([])
    const indexes_manager_ctx = useContext(IndexesManagerContext);
    const admin_panel_ctx = useContext(AdminPanelContext);

    useEffect(()=>{
        if(chosenProducts.length === 1) {
            indexes_manager_ctx.setIsOnlyOneChosen(chosenProducts[0]);
            indexes_manager_ctx.setBoxGroupField(3)
            if(indexes_manager_ctx.operationsField === 1) indexes_manager_ctx.setBoxGroupField(0)
        }
        else if (chosenProducts.length === 0){
            indexes_manager_ctx.setIsOnlyOneChosen(false);
            if(indexes_manager_ctx.operationsField === 2) indexes_manager_ctx.setBoxGroupField(3)
        }
        else {
            indexes_manager_ctx.setIsOnlyOneChosen(false);
            if(indexes_manager_ctx.operationsField === 2) indexes_manager_ctx.setBoxGroupField(1)
        }
    }, [indexes_manager_ctx.timer])



    const handleSearchRequest = ()=>{
        setChosenProducts([]);
        setChosenProductsInGroup([]);
        indexes_manager_ctx.setOperationsField(2);
        indexes_manager_ctx.setBoxGroupField(1);
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            searchName:searchName === "" ? null : searchName,
            sku:searchSku,
            priceFrom: priceFrom ? (priceFrom.includes(",") ? parseFloat(priceFrom.replace(',','.')) : parseFloat(priceFrom)) : null ,
            priceTo: priceTo ? (priceTo.includes(",") ? parseFloat(priceTo.replace(',','.')) : parseFloat(priceTo)) : null ,
            justActive:isJustActiveChecked,
            justNew:isJustNewChecked,
            searchMode:selectValue,
            fields:indexes_manager_ctx.attributesList,
            language:'pl',
            market:[2]
        }
        PostMethod(SEARCH_INDEX_MANAGER_PRODUCTS, data, getResponse);
    }
    const getResponse = response =>{
        setFoundProducts(response.data);
    }

    const setAllInGroupToChosen =()=>{
        indexes_manager_ctx.productsInGroup.map((prod)=>{
            if(chosenProductsInGroup.indexOf(prod.id) === -1){
                let productsToSet = chosenProductsInGroup;
                productsToSet.push(prod.id);
                setChosenProductsInGroup(productsToSet);
                indexes_manager_ctx.setIsControlPressed(true);
                setTimeout(()=>{indexes_manager_ctx.setIsControlPressed(false)},5)
            }
        })
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)
    }

    const removeAttributeFromField =(attribute)=>{
        indexes_manager_ctx.attributesList.splice(attribute,1)
        indexes_manager_ctx.setAttributesList(prev=>{return [...prev]});
    }

    return (
        <>
        <Grid container>
            <Grid item xs={12} p={2} sx={{backgroundColor:admin_panel_ctx.cSheet.default}} id={'IndexeManagerOperationsFieldTopPanel'}>
                <Button sx={{marginRight:1}} variant={indexes_manager_ctx.operationsField===1 ? "contained" : "standard"} onClick={()=>{indexes_manager_ctx.setOperationsField(1); indexes_manager_ctx.setBoxGroupField(0); indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)}}>Wyszukiwanie</Button>
                <Button sx={{marginRight:1}} variant={indexes_manager_ctx.operationsField===2 ? "contained" : "standard"} onClick={()=>{indexes_manager_ctx.setOperationsField(2); indexes_manager_ctx.setBoxGroupField(1); indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1)}}>Znalezione</Button>
                <Button variant={indexes_manager_ctx.operationsField===3 ? "contained" : "standard"} onClick={()=>{indexes_manager_ctx.setOperationsField(3); indexes_manager_ctx.setBoxGroupField(2)}}>{`Grupa(${indexes_manager_ctx.productsInGroup.length})`}</Button>
            </Grid>
            {indexes_manager_ctx.operationsField === 1 && 
                <>
                <Grid container item m={3} justifyContent={'center'}>
                    <Grid container item lg={12} xl={11} alignItems={'center'}>
                        <Grid item xs={3}>
                            <Grid container item alignItems={'center'}>
                                    <Grid item alignItems={'center'} mr={3}><Typography>nazwa:</Typography></Grid>
                                    <Grid item><TextField value={searchName} onChange={(e)=>setSearchName(e.target.value)}/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container item alignItems={'center'}>
                                <Grid item alignItems={'center'} mr={3}><Typography>sku:</Typography></Grid>
                                <Grid item><TextField value={searchSku} onChange={(e)=>setSearchSku(e.target.value)}/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} pr={2}>
                            <Grid container item alignItems={'center'}>
                                <Grid item alignItems={'center'} mr={1}><Typography>Cena od</Typography></Grid>
                                <Grid item><Input value={priceFrom} onChange={(e)=>setPriceFrom(e.target.value)}/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container item alignItems={'center'}>
                                <Grid item alignItems={'center'} mr={1}><Typography>Cena do</Typography></Grid>
                                <Grid item ><Input value={priceTo} onChange={(e)=>setPriceTo(e.target.value)}/></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container item alignItems={'center'}><Checkbox checked={isJustActiveChecked} onChange={()=>setIsJustActiveChecked(!isJustActiveChecked)}/><Typography>Tylko aktywne</Typography></Grid>
                            <Grid container item alignItems={'center'}><Checkbox checked={isJustNewChecked} onChange={()=>setIsJustNewChecked(!isJustNewChecked)}/><Typography>Tylko nowe</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} my={1} justifyContent={'center'} >
                    <Grid container item lg={12} xl={11} justifyContent={'center'} alignItems={'center'}>
                        <Typography fontSize={17}>Zawierające</Typography>
                        <FormControl sx={{ m: 2}}>
                            <Select
                            variant="standard"
                            value={selectValue}
                            onChange={(e)=>{setSelectValue(e.target.value)}}
                            sx={{color:'white'}}
                            >
                                <MenuItem value={'or'}>{'rozdzielnie (OR)'}</MenuItem>
                                <MenuItem value={'and'}>{'łącznie (AND)'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item id={'including_field'} lg={12} xl={11} mx={1} p={1} flexWrap minHeight={'10vh'} height={'70%'} sx={{backgroundColor:'lightgreen', border:'green solid 2px', borderRadius:'10px'}}>
                        {Object.keys(indexes_manager_ctx.attributesList).map(atr=>{
                            if(indexes_manager_ctx.attributesList[atr].including === true){
                                return(
                                    <Paper key={atr} sx={{height:'fit-content', p:1, mr:1, backgroundColor:admin_panel_ctx.color1, color:'white', cursor:'pointer'}} onDoubleClick={()=>removeAttributeFromField(atr)}>{indexes_manager_ctx.attributesList[atr].name}</Paper>
                                    )
                                }
                            })}
                    </Grid>
                </Grid>

                <Grid container item xs={12} my={3} justifyContent={'center'} >
                    <Grid container item lg={12} xl={11} mb={1} justifyContent={'center'}><Typography fontSize={17}>Niezawierające</Typography></Grid>
                    <Grid container item id={'not_including_field'} lg={12} xl={11} mx={1} p={1} minHeight={'10vh'} height={'70%'} sx={{backgroundColor:'lightcoral', border:'red solid 2px', borderRadius:'10px'}}>
                    {Object.keys(indexes_manager_ctx.attributesList).map(atr=>{
                        if(indexes_manager_ctx.attributesList[atr].notIncluding === true){
                            return(
                                <Paper key={atr} sx={{height:'fit-content', p:1, mr:1, backgroundColor:admin_panel_ctx.color1, color:'white', cursor:'pointer'}} onDoubleClick={()=>removeAttributeFromField(atr)}>{indexes_manager_ctx.attributesList[atr].name}</Paper>
                                )
                            }
                        })}
                    </Grid>
                </Grid>

                <Grid container item xs={12} mb={2}>
                    <Grid item xs={12} mx={10}>
                        <Button fullWidth variant="contained" onClick={handleSearchRequest}>Wyszukaj</Button>
                    </Grid>
                </Grid>
                </>
            }
            {indexes_manager_ctx.operationsField === 2 && <IndexesManagerFoundField foundProducts = {foundProducts} chosenProducts={chosenProducts} setChosenProducts={setChosenProducts}/>}
            {indexes_manager_ctx.operationsField === 3 && <IndexesManagerGroupField setAllInGroupToChosen={setAllInGroupToChosen} chosenProductsInGroup={chosenProductsInGroup} setChosenProductsInGroup={setChosenProductsInGroup}/>}
        </Grid>
        </>
    )
}

export default IndexesManagerOperationsField;