import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ExpectedLoggedIn.module.css";

const ExpectedLoggedIn =() =>{

    let navigate = useNavigate();
    const [redirectTime, setRedirectTime] = useState(10);

    const TimeInterval = setInterval(()=>{
        if(redirectTime !== 0){
            setRedirectTime(redirectTime-1);
            clearInterval(TimeInterval);
        }
        else{
            clearInterval(TimeInterval);
            return navigate('/login');
    }
    }, 1000);

    return(
        <div className={styles.main_warning_panel}>
            <div className={styles.warning_container}>
            <div className={styles.warning_message}>Oops, it seems that you're not logged in</div>
            <div className={styles.warning_counter}>You will be redirected in... {redirectTime}</div>
            </div>
        </div>
    );

}
export default ExpectedLoggedIn;