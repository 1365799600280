import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DEPLOY_URL } from "../../../../../Constants";
import PostMethod from "../../../../FetchMethods/PostMethod";
const Box=()=>{
    const [buttonLoading, setButtonLoading] = useState();
    const handleClick=url=>{
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
        }
        PostMethod(url,data, getResponse);
    }

    const getResponse =() =>{
        setButtonLoading(false)
    }

    return(
        <>
            <Grid item mb={4} mt={1}><Typography color={'white'}>Działania na bazie: {JSON.parse(localStorage.getItem("currentOrganization")).config.database}</Typography></Grid>
            <Grid container spacing={2}>
                <Grid item><LoadingButton variant="contained" size="small" onClick={()=>{handleClick(DEPLOY_URL); setButtonLoading(true)}} loading={buttonLoading}>Deploy</LoadingButton></Grid>
            </Grid>
        </>
    )
}
export default Box;