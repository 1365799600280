import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import "./Description.css"
import PostMethod from '../../../../../../../../FetchMethods/PostMethod';
import ProductEditContext from '../../../../../../../../../Context/ProductEditContext';
import { GET_PRODUCT_EDIT_CKE_DESCRIPTION, SET_PRODUCT_EDIT_CKE_DESCRIPTION } from '../../../../../../../../../Constants';
import editorConfiguration from './CkEditorConfig/CkEditorConfig';

const Description =()=>{
    const product_edit_context = useContext(ProductEditContext)
    const [editorOutput, setEditorOutput] = useState();
    const [saved, setSaved] = useState(true)
    
    useEffect(()=>{
        const data ={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            objectId:JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.config.id === product_edit_context.product.config.id).config.id,
            objectType:"index",
            language:'pl',
            refLanguage:'pl',
            textToken:"description",
            html:true
        }
        PostMethod(GET_PRODUCT_EDIT_CKE_DESCRIPTION, data, getResponseHandler)
    },[])

    const handleSave =() =>{
        setSaved(true)
        const data ={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            objectId:JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.config.id === product_edit_context.product.config.id).config.id,
            objectType:"index",
            language:'pl',
            textToken:"description",
            html:true,
            body:editorOutput.trim().replace("'", '&amp;'),
        }
        PostMethod(SET_PRODUCT_EDIT_CKE_DESCRIPTION, data, setResponseHandler)
    }

    const getResponseHandler = (data) =>{
        if(data.data.body.pl){
            setEditorOutput(data.data.body.pl)
        }
    }
    const setResponseHandler = (data) =>{
        console.log(data)
    }


    return(
        <Grid container>
            <Grid containter item xs={12} color={'black'} p={1}>
                <CKEditor
                    editor={ClassicEditor}
                    data={editorOutput}
                    onChange={(e, editor)=>{setEditorOutput(editor.getData()); setSaved(false)}}
                    config={editorConfiguration}
                />
            </Grid>
            <Grid container item xs={12} p={1}>
                <Grid item xs={12}>
                    <Button variant='contained' onClick={handleSave} fullWidth disabled={saved}>Zapisz</Button>
                </Grid>
            </Grid>
        </Grid>
    )    
}
export default Description;