import { Grid, TextareaAutosize, Typography } from "@mui/material";
import { useContext } from "react";
import AdminPanelContext from "../../../../../../../../../../../Context/AdminPanelContext";

const TextInputField = (props)=>{

    let editingObject = props.object;
    const admin_panel_ctx = useContext(AdminPanelContext);

    const getEditedTextareaColor =(num)=>{
        if(num >= 150 && num < 200) return 'green';
        else if(num >= 200 && num < 220) return 'orange';
        else if(num >= 220) return 'red';
        return 'gray'
    }
    
    return(
        <Grid item xs={12} position={'relative'}>
            <TextareaAutosize minRows={5} style={{backgroundColor:admin_panel_ctx.cPanel.default, width:'100%'}} defaultValue={editingObject.content} onChange={(e)=>props.setEditingObject((prev)=>{return({...prev, content:e.target.value})})}/>
            <Typography fontSize={15} color={getEditedTextareaColor(editingObject.content ? editingObject.content.trim().length : 0)} position={'absolute'} bottom={15} right={0}>{editingObject.content ? editingObject.content.trim().length : 0}</Typography>
        </Grid>
    )
}
export default TextInputField;