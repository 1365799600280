import { useContext, useEffect, useState } from "react";
import {AiOutlineLoading, AiOutlineCloseCircle} from "react-icons/ai"
import {RiArrowUpDownLine} from "react-icons/ri";
import styles from "./BulletPointsTable.module.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AdminPanelContext from "../../../../../../../../../../Context/AdminPanelContext";
import { Box, Button, FormControl, Grid, IconButton, InputBase, LinearProgress, NativeSelect, Paper, TextareaAutosize, Typography } from "@mui/material";
import TextInputField from "./TableInputFields/TextInputField";
import AttributeInputField from "./TableInputFields/AttributeInputField";
import MetricInputField from "./TableInputFields/MetricInputField";
import BulletPointsContext from "../../../../../../../../../../Context/BulletPointsContext";
import { MANAGE_INDEX_BULLET_POINTS } from "../../../../../../../../../../Constants";
import useFetch from "../../../../../../../../../../Hooks/use-fetch";
import ConfirmationModal from "../../../../../../../../../ConfirmationModal/ConfirmationModal";

const BulletPointTable =(props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [bulletPointTitle, setBulletPointTitle] = useState('');
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [editingObject, setEditingObject] = useState();
    const [selectedBulletToAdd, setSelectedBulletToAdd] = useState('text')
    const bullet_points_ctx = useContext(BulletPointsContext);


    const {loading:manageIndexBulletPointsTokensLoading, error:manageIndexBulletPointsTokensError, sendRequest:sendManageIndexBulletPointsTokensRequest } = useFetch();
    const {loading:updateIndexBulletPointsTokensLoading, error:updateIndexBulletPointsTokensError, sendRequest:sendUpdateIndexBulletPointsTokensRequest } = useFetch();



    const getCurrentBulletPoint = () =>{
        if(bullet_points_ctx.bulletPointsCurrentTokenId){
            sendManageIndexBulletPointsTokensRequest({
                url:MANAGE_INDEX_BULLET_POINTS,
                method:"POST",
                body:{
                    mode:'get', 
                    id:bullet_points_ctx.bulletPointsCurrentTokenId, 
                    db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
                }
            },
            getResponse)
        }
    }

    const updateCurrentBulletPoint =(bulletsToSet)=>{
        sendUpdateIndexBulletPointsTokensRequest({
            url:MANAGE_INDEX_BULLET_POINTS,
            method:"POST",
            body:{
                mode:'update', 
                id:bullet_points_ctx.bulletPointsCurrentTokenId, 
                features:bullet_points_ctx.currentBulletPoint.features,
                bullets:bulletsToSet,
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
            }
        },
        getUpdateResponse)
    }

    const getResponse =(response)=>{
        if(response && !editingObject){
            bullet_points_ctx.setCurrentBulletPoint(response[0])
        }
        else if(response && editingObject){
            console.log(editingObject)
        }
    }

    useEffect(()=>{
        getCurrentBulletPoint()
    },[bullet_points_ctx.bulletPointsCurrentTokenId])



    const inputFields = [
        {type:'text', field:<TextInputField object={editingObject} setEditingObject={setEditingObject}/> },
        {type:'attribute', field:<AttributeInputField object={editingObject} setEditingObject={setEditingObject}/>},
        {type:'metric', field:<MetricInputField object={editingObject} setEditingObject={setEditingObject}/>}
    ]

    const row = (id, type, content) =>{
        return(
            <Grid container key={id}>
                <Grid item xs={3} className={styles.cell_container} p={2}>{type}</Grid>
                <Grid item xs={7} className={styles.cell_container} p={2}>
                    {editingObject && editingObject.id === id ? 
                        inputFields.find(x=>x.type === editingObject.type).field
                    :
                    <Typography textAlign={'left'} fontSize={13}>{content}</Typography>
                    }
                </Grid>
                <Grid container item xs={2} className={styles.cell_container} py={1}>
                    <div className={styles.delete_bullet_icon} ><IconButton color='error' onClick={()=>deleteBullet(id, content)}><AiOutlineCloseCircle size={15}/></IconButton></div>
                    <Grid item xs={12}><IconButton color='primary'><RiArrowUpDownLine size={30}/></IconButton></Grid>
                    <Grid item xs={12} mx={1}><Button variant="contained" fullWidth onClick={editingObject && editingObject.id === id ? (e)=>saveEditingBullet(e) : ()=>setEditingObject({id:id, content:content, type:type})}>{editingObject && editingObject.id === id ? "Zapisz" : "Edytuj"}</Button></Grid>
                </Grid>
            </Grid>
        )
    }

    const saveEditingBullet =(e)=>{
        if(editingObject.type === 'text'){
            let currentBullets = [...bullet_points_ctx.currentBulletPoint.bullets];
            currentBullets[editingObject.id].body.pl = editingObject.content;
            updateCurrentBulletPoint(currentBullets)
        }
        setEditingObject(null)
    }


    const getUpdateResponse = (response) =>{
        if(!editingObject){
            getCurrentBulletPoint()
        }
    }

    const addNewBullet =()=>{
        bullet_points_ctx.setReloadTokens(true);
        bullet_points_ctx.setReloadBulletPoints(true);
        let bulletsToSet = [{type:selectedBulletToAdd, body:{pl:null} }]
        if(bullet_points_ctx.currentBulletPoint.bullets){
            const currentBullets = [...bullet_points_ctx.currentBulletPoint.bullets];
            bulletsToSet = [...currentBullets, {type:selectedBulletToAdd, body:{pl:null} }]
        }
        updateCurrentBulletPoint(bulletsToSet)
  
    }

    const deleteBullet =(id, content)=>{
        if(!content){
            const currentBullets = [...bullet_points_ctx.currentBulletPoint.bullets];
            currentBullets.splice(id,1)
            updateCurrentBulletPoint(currentBullets)
        }
        else{
            setConfirmationModal({trigger:true, id:id, content:content})
        }
    }
    const deleteBulletAfterConfirmation =()=>{
        if(confirmationModal && confirmationModal.id){
            const currentBullets = [...bullet_points_ctx.currentBulletPoint.bullets];
            currentBullets.splice(confirmationModal.id,1)
            updateCurrentBulletPoint(currentBullets)
        }
        setConfirmationModal(false)
    }


    return(
        <>
        {(bullet_points_ctx.currentBulletPoint && bullet_points_ctx.currentBulletPoint.token == 'usp' && !manageIndexBulletPointsTokensLoading) ? 
            <>
            <Grid container item xs={12} sx={{marginTop:'10px'}}>
                <Grid item xs={10}>
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:(bulletPointTitle && (bulletPointTitle.length > 0)) ? admin_panel_ctx.cPanel.default : '#F9CB9C'}}>
                        <InputBase
                            sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }}
                            value={bulletPointTitle} 
                            onChange={(e)=>setBulletPointTitle(e.target.value)}
                            fullWidth={true}
                            onKeyUp={(e)=>{if(e.keyCode === 13) e.target.blur()}}
                            placeholder={bulletPointTitle? null: "Brak nazwy"}
                            />
                        <Typography fontSize={15} pr={1} color={'gray'}>
                            {props.loading ? <AiOutlineLoading size={30} className="loading"/> : (bulletPointTitle && bulletPointTitle.trim().length)}
                        </Typography>        
                    </Paper>
                </Grid>
                <Grid container item xs={2} justifyContent={'center'}><Button variant="contained" color="error" fullWidth sx={{marginX:'10px'}}>Usuń</Button></Grid>
            </Grid>
            <Grid item xs={7}><TextareaAutosize minRows={5} style={{backgroundColor:admin_panel_ctx.cPanel.default, width:'100%', padding:'10px', marginTop:'10px'}}/></Grid>

            <Grid container item xs={12} pt={3} textAlign={"center"} pr={2}>
                <Grid item xs={3} className={styles.cell_header_container} p={2}><Typography fontSize={20} fontWeight={700}>Typ</Typography></Grid>
                <Grid item xs={7} className={styles.cell_header_container} p={2}><Typography fontSize={20} fontWeight={700}>Treść/Wartość</Typography></Grid>
                <Grid item xs={2} className={styles.cell_header_container} p={2}><Typography fontSize={20} fontWeight={700}><RiArrowUpDownLine size={30}/></Typography></Grid>
                {(bullet_points_ctx.currentBulletPoint && bullet_points_ctx.currentBulletPoint.token == 'usp') && bullet_points_ctx.currentBulletPoint.bullets && bullet_points_ctx.currentBulletPoint.bullets.map((bullet, id) => {return row(id, bullet.type, bullet.body.pl)})}

                <Grid container>
                    <Grid container item xs={3} className={styles.cell_container} p={2} alignItems={'center'} >
                        <FormControl sx={{minWidth: 80, textAlignLast:'center'}} fullWidth>
                            <NativeSelect
                            onChange={(e)=>setSelectedBulletToAdd(e.target.value)}
                            value={selectedBulletToAdd}
                            sx={{color:'black'}}
                            >
                                <option value={'text'}>Text</option>
                                <option value={'attribute'}>Atrybut</option>
                                <option value={'metric'}>Metryka</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7} className={styles.cell_container} p={2}></Grid>
                    <Grid container item xs={2} className={styles.cell_container} py={1}>
                        <Grid item xs={12}><IconButton color='primary' onClick={addNewBullet}><AddCircleIcon fontSize="large"/></IconButton></Grid>
                    </Grid>
                </Grid>
                </Grid>
            </>

            :

            <Grid item xs={12} mt={3} mr={1}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Grid>
            }
            {confirmationModal && <ConfirmationModal 
            color={'error'} 
            fontSize={20} 
            text={'Czy napewno usunąć ten bullet ?'} 
            acceptButtonText={'Tak'}
            declineButtonText={'Nie'}
            closeModal={()=>setConfirmationModal(false)}
            declineButtonClick={()=>setConfirmationModal(false)}
            acceptButtonClick={deleteBulletAfterConfirmation}
            />
            }
        </>
    )
}

export default BulletPointTable;