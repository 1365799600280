import { Alert, AlertTitle, Button, Divider, Fab, Grid, IconButton, InputBase, Paper, Slide, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from "react";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import { useRef } from "react";
import { SET_ATTRIBUTES_GROUP_URL } from "../../../../Constants";
import PostMethod from "../../../FetchMethods/PostMethod";
import { createPortal } from "react-dom";
import styles from "./AttributesGroups.module.css";
import AddIcon from '@mui/icons-material/Add';


const AttributesGroups =(props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [attributesGroupAdding, setAttributesGroupAdding] = useState();
    const [addedSuccessfully, setAddedSuccessfully] = useState()
    const Groups = props.data;

    const AttributesGroupElement = (props) =>{
        return (
            <Paper 
                onClick={()=>props.onClick()}
                component="form"
                sx={{ '&:hover': {backgroundColor:admin_panel_ctx.cSheet.negative, color:admin_panel_ctx.cText.negative}, color:admin_panel_ctx.cText.default , mt:'2px', p: '2px 4px', display: 'flex', alignItems: 'center', cursor:'pointer', backgroundColor:admin_panel_ctx.cPanel.default }}
                >
                    <IconButton sx={{ p: '10px', color:'rgb(161, 161, 161)' }} aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Typography textAlign={'left'} sx={{ml: 1, flex: 1 }}>{Groups[props.groupId].assets.name.pl}</Typography>
    
            </Paper>
        )
    }
    const NewAttributesGroupElement = (props) =>{
        const newAttributesGroupRef = useRef();
        return(
            <Paper
                component="form"
                onSubmit={(e)=>getNewGroupElementInput(e, props.bulkId, newAttributesGroupRef.current.value)}
                sx={{ mb:2, mt:'2px', p: '2px 4px', display: 'flex', alignItems: 'center', cursor:'pointer', backgroundColor:admin_panel_ctx.cSheet.negative }}
                >
                    <IconButton sx={{ p: '10px', color:'silver' }} aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <InputBase sx={{ ml: 1, flex: 1, color:'white' }} placeholder={"Wpisz nazwę grupy ..."} autoFocus={true} inputRef={newAttributesGroupRef} onBlur={(e)=>getNewGroupElementInput(e, props.bulkId)}/>
            </Paper>
        )
    }
    const getNewGroupElementInput = (e, bulkId, formInputValue=null) =>{
        e.preventDefault();
        if(e.target.value || formInputValue){
            const data={
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                bulkId:bulkId,
                active:false,
                assets:{
                    name:{
                        pl:e.target.value? e.target.value : formInputValue,
                        en:null
                    }
                },
                token:null,
                settings:{
                    autoopen:false,
                    pill:false,
                    hidden:false,
                    sort:null
                }
            }
            PostMethod(SET_ATTRIBUTES_GROUP_URL,data,getResponse)
        }
        setAttributesGroupAdding(false);
    }
    const getResponse = (data)=>{
        if(data){
            props.reload();
            setAddedSuccessfully(true);
        }
    }

    return (
        <>
        <Grid container rowSpacing={2}>
            <Grid container item xs={12}>
                <Grid item xs={8}>

                </Grid>
                <Grid item xs={4} textAlign={'right'}>
                    <Fab sx={{width:35, height:25}} color='info' variant="contained" size="small" onClick={()=>setAttributesGroupAdding(true)}><AddIcon/></Fab>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {!Groups && !attributesGroupAdding  && <div>Brak grup dla tego obszaru ...</div>}
                {attributesGroupAdding && <NewAttributesGroupElement bulkId={props.bulkId}/>}
                {Groups &&  
                Object.keys(Groups).map((element, i) => {
                    return(
                        <AttributesGroupElement key={i} delay={(i+1)*50+100} groupId={element} onClick={()=>props.setAttributeGroupToLoad(Groups[element], Groups[element].id)}/>
                    )   
                })}
            </Grid>
        </Grid>
        
        {createPortal(<Snackbar open={addedSuccessfully} autoHideDuration={3000} onClose={()=>{setAddedSuccessfully(false)}} TransitionComponent={Slide} anchorOrigin={{vertical:'top', horizontal:'center'}} >
                        <Alert style={{backgroundColor:'lightgreen'}} sx={{ width: '100%' }}>
                        <AlertTitle>Success</AlertTitle>
                            Nowa grupa atrybutów zotała poprawnie dodana !!!
                        </Alert>
            </Snackbar>, document.getElementById('modal'))
            }


        </>
    )
}
export default AttributesGroups;