import { Fab, Grid, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import AdminPanelContext from "../../../../../../../../../../Context/AdminPanelContext";
import AddIcon from '@mui/icons-material/Add';
import styles from "./ProductAttributesInGroup.module.css"
import { useState } from "react";
import { useEffect } from "react";
import PostMethod from "../../../../../../../../../FetchMethods/PostMethod";
import { GET_ATTRIBUTES_URL, UPDATE_INDEX_ATTRIBUTES_URL } from "../../../../../../../../../../Constants";
import ProductEditContext from "../../../../../../../../../../Context/ProductEditContext";
import {AiOutlineLoading} from "react-icons/ai";


const ProductAttributesInGroup =(props)=>{
    const product_edit_context = useContext(ProductEditContext)
    const admin_panel_ctx = useContext(AdminPanelContext)
    const [attributes, setAttributes] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(props.groupId){   
            getAttributesForGroup();
        }
    },[])

    const getAttributesForGroup=()=>{
        setLoading(true)
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            groupId:props.groupId
        }
        PostMethod(GET_ATTRIBUTES_URL, data, getResponse)
    }

    const getResponse=(data)=>{
        if(JSON.stringify(data) !== "{}"){
            setAttributes(data);
        }
        setLoading(false)
    };
    const addAttribute = (attributeId) =>{
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            language:'pl',
            index: JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.config.id === product_edit_context.product.config.id).config.id,
            resetAttributes: null,
            addAttributes: [attributeId],
            removeAttributes: null
        }
        PostMethod(UPDATE_INDEX_ATTRIBUTES_URL,data,responseHandler)
    }
    const responseHandler =(data) =>{
        product_edit_context.setRefreshCurrentAttributesField(true);
    }

    return(
        <Grid className={`${styles.attributes_scroller} scrollerY`}>
            <Grid style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
            <Grid container id={`attributesContainer`} sx={{display:'inline-block'}}>
            {attributes ? Object.keys(attributes ? attributes : '').map((a, i)=>{
                return (
                    <Paper
                    data-id={a}
                    key={a}
                    className={`${styles.attribute_object} attribute_object`}
                    sx={{margin:1, padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative, display:'inline-block'}}
                    >{attributes[a].assets.name.pl}
                         <Fab sx={{width:25, height:25, minHeight:25,ml:1}} color='warning' variant="contained" disabled={product_edit_context.productAttributes && product_edit_context.productAttributes.findIndex(x=>x.id === attributes[a].id ) !== -1} onClick={()=>{addAttribute(attributes[a].id)}}><AddIcon fontSize="10"/></Fab>
                    </Paper>)
                }
                )
            :
            (
                loading
                ?
                <Grid item xs={12} textAlign={'center'} mt={4}><AiOutlineLoading size={30} className="loading"/></Grid>
                :
                <Typography fontSize={15} color={'darkgray'} textAlign={'center'} m={3}>Brak atrybutów dla tej grupy</Typography>
            )
            }
                </Grid>
                </Grid>
                </Grid>
    )
}
export default ProductAttributesInGroup;