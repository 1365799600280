const editorConfiguration = {
    heading: {
      options: [
        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    codeBlock: {
        languages: [
            { language: "plaintext", label: "Plain text" },
            { language: "html", label: "HTML" },
            { language: "css", label: "CSS" },
            { language: "javascript", label: "JavaScript" },
            { language: "cs", label: "C#" },
            { language: "sql", label: "SQL" },
            { language: "json", label: "JSON" },
            { language: "c", label: "C" },
            { language: "cpp", label: "C++" },
            { language: "diff", label: "Diff" },
            { language: "java", label: "Java" },
            { language: "php", label: "PHP" },
            { language: "python", label: "Python" },
            { language: "ruby", label: "Ruby" },
            { language: "typescript", label: "TypeScript" },
            { language: "xml", label: "XML" }
        ],
        indentSequence: "    "
    },
  };

  export default editorConfiguration;