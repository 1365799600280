import WrapperTop from "./WrapperTop/WrapperTop";
import WrapperLeft from "./WrapperLeft/WrapperLeft";
import WrapperRight from "./WrapperRight/WrapperRight";
import WrapperBottom from "./WrapperBottom/WrapperBottom";
import styles from "./AdminPanelWrapper.module.css";
const AdminPanelWrapper = () =>{
    
      return (
        <div style={{position:'relative'}}>
            <div className={styles.wrapper_top_logo}><img id={'logo'} className={styles.wrapper_top_logo_img} src={`/images/logo/${localStorage.getItem('currentLogo')}`}/></div>
            <WrapperTop/>
            <WrapperLeft/>
            <WrapperRight/>
            <WrapperBottom/>
        </div>
      )
}

export default AdminPanelWrapper;