import { Box, Button, FormControl, Grid, InputBase, LinearProgress, NativeSelect, Paper, Typography } from "@mui/material";
import styles from "./BulletPointsList.module.css"
import { useContext, useEffect } from "react";
import AdminPanelContext from "../../../../../../../../../../Context/AdminPanelContext";
import BulletPointsContext from "../../../../../../../../../../Context/BulletPointsContext";
import { MANAGE_INDEX_BULLET_POINTS } from "../../../../../../../../../../Constants";
import ProductEditContext from "../../../../../../../../../../Context/ProductEditContext";
import useFetch from "../../../../../../../../../../Hooks/use-fetch";
import { useState } from "react";

const BulletPointsList =(props) =>{

    const admin_panel_ctx = useContext(AdminPanelContext);
    const bullet_points_ctx = useContext(BulletPointsContext);
    const product_edit_context = useContext(ProductEditContext);

    const [selectValue, setSelectValue] = useState();


    const {loading:manageIndexBulletPointsLoading, error:manageIndexBulletPointsError, sendRequest:sendManageIndexBulletPointsRequest } = useFetch();
    const {loading:addNewBulletPointTypeLoading, error:addNewBulletPointTypeError, sendRequest:sendAddNewBulletPointTypeRequest } = useFetch();


    const setBulletPointsList = (response) =>{
        console.log(response)
        let tokensToSet = []
        response.forEach(token =>{
            let tok = bullet_points_ctx.allBulletPointsTokens.find(x=>x.token === token.token)
            tokensToSet.push({id:token.id, token:tok.token, name:tok.name})
        })

        bullet_points_ctx.setBulletPointsTokensInUse(tokensToSet)

        const id = response[bullet_points_ctx.currentBulletPointIndex].id

        if(id){
            bullet_points_ctx.setBulletPointsCurrentTokenId(id)
        }
    }

    useEffect(()=>{
        getBulletPointsList()
    },[bullet_points_ctx.allBulletPointsTokens])


    const getBulletPointsList =()=>{
        if(bullet_points_ctx.allBulletPointsTokens){
            sendManageIndexBulletPointsRequest(
                {
                    url:MANAGE_INDEX_BULLET_POINTS,
                    method:"POST",
                    body:{
                        mode:'getSet', 
                        index:product_edit_context.product.config.shop_index_id, 
                        db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
                    }
                },
                setBulletPointsList)
            }
    }

    const addNewBulletPointType = () =>{
        sendAddNewBulletPointTypeRequest(
            {
                url:MANAGE_INDEX_BULLET_POINTS,
                method:"POST",
                body:{
                    mode:'create',
                    index:product_edit_context.product.config.shop_index_id,
                    db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                    token:selectValue ? selectValue : bullet_points_ctx.bulletPointsTokensToSelect[0].token,
                    features:null,
                    bullets:null
                }
            },
            getBulletPointsList
        )
    }




    return (
        <Grid className={styles.bullet_points_list_container} sx={{backgroundColor:admin_panel_ctx.cPanel.default}}>
            {bullet_points_ctx.bulletPointsTokensToSelect && 
                <Grid item xs={12}>
                    <FormControl sx={{minWidth: 80, textAlignLast:'center', marginBottom:3, marginTop:1}} fullWidth>
                        <NativeSelect
                        sx={{color:'black'}}
                        value={selectValue}
                        onChange={(e)=>setSelectValue(e.target.value)}
                        >
                            {bullet_points_ctx.bulletPointsTokensToSelect.map(token=>{
                                return <option key={token.token} value={token.token}>{token.name}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                    <Button variant={'contained'} fullWidth onClick={addNewBulletPointType}>Dodaj</Button>
                </Grid>
            }
            {bullet_points_ctx.bulletPointsTokensInUse && !manageIndexBulletPointsLoading && bullet_points_ctx.bulletPointsTokensInUse.map((bulletPoint, index)=>{
                return (
                    <Grid item xs={12} mt={1} key={bulletPoint.token}> 
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent:'center', 
                        backgroundColor:bullet_points_ctx.currentBulletPointIndex === index ? 'lightgray' : admin_panel_ctx.cPanel.default, 
                        cursor:'pointer'}} 
                        onClick={()=>bullet_points_ctx.setCurrentBulletPointIndex(index)}
                        >
                            <Typography m={'5px'}>{bulletPoint.name}</Typography>
                        </Paper>
                    </Grid>
                    )
            })}
            <Grid item xs={12} mt={5}>
                {manageIndexBulletPointsLoading || props.loading && 
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
                }
            </Grid>
        </Grid>
    )
}

export default BulletPointsList;