import styles from './EditBulkModal.module.css';
import { createPortal } from 'react-dom';
import {RiCloseFill} from 'react-icons/ri';
import { useContext, useState } from 'react';
import AdminPanelContext from '../../../../../Context/AdminPanelContext';
import { Button, TextField, Typography } from '@mui/material';
import { UPDATE_ATTRIBUTES_URL } from '../../../../../Constants';
import PostMethod from '../../../../FetchMethods/PostMethod';

const EditBulkModal = (props) => {

    const admin_panel_ctx = useContext(AdminPanelContext);
    const parseData = JSON.parse(props.data);
    const [token, setToken] = useState(parseData.token);
    const [namePl, setNamePl] = useState(parseData.assets.name.pl);
    const [nameEn, setNameEn] = useState(parseData.assets.name.en);

    const submitHandler = e =>{
        e.preventDefault()
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            id:parseData.id,
            assets:{
                name:{
                    pl:namePl,
                    en:nameEn
                }
            },
            token:token,
            settings:{
                priority:parseData.settings.priority
            }
        }
        PostMethod(UPDATE_ATTRIBUTES_URL, data, getResponse);
    }
    const getResponse = (data)=>{
        if(data){
            props.reload();
            props.closeModal();
        }
    }
    return(
        <>
            {createPortal(
            <div className={styles.edit_bulk_modal}  style={{backgroundColor:admin_panel_ctx.color1}}>
                <div className={styles.edit_bulk_modal_close_icon_container}><RiCloseFill className={styles.edit_bulk_modal_close_icon} size={25} onClick={()=>props.closeModal(false)}/></div>
                <div className={styles.edit_bulk_modal_form_container}>

                    <div className={styles.edit_bulk_form}>
                        <form onSubmit={submitHandler}>
                            <Typography fontSize={25}>Edytuj obszar</Typography>
                            <div className={styles.bulk_inputs}>
                                <TextField className={styles.bulk_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="Token" value={token} variant="standard" type="text" onChange={(e)=>setToken(e.target.value)}/>
                                <TextField className={styles.bulk_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="nazwa PL" value={namePl} variant="standard" type="text" onChange={(e)=>setNamePl(e.target.value)} required={true}/>
                                <TextField className={styles.bulk_input} sx={{label: {color: "white"}, input: {color: "white"}}} label="nazwa EN" value={nameEn} variant="standard" type="text" onChange={(e)=>setNameEn(e.target.value)}/>
                            </div>
                            <Button className={styles.edit_bulk_button} color='info' variant="contained" size="small" type="submit">Add</Button>
                        </form>
                    </div>

                </div>
            </div>, document.getElementById('modal'))}

            {createPortal(<div className={styles.backdrop} onClick={()=>props.closeModal(false)}/>, document.getElementById('backdrop'))}
        </>
    )
}

export default EditBulkModal;