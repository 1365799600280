import { useContext } from "react";
import ProductEditContext from "../../../../../../../../Context/ProductEditContext";
import { Button, FormControl, Grid, IconButton, InputBase, NativeSelect, Paper, TextField, Typography } from "@mui/material";
import AdminPanelContext from "../../../../../../../../Context/AdminPanelContext";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./ProductEditFieldsComponent.module.css"
import Metadata from "./Metadata/Metadata";
import {BsFillCheckCircleFill} from "react-icons/bs"
import {AiFillWarning} from "react-icons/ai"
import BulletPoints from "./BulletPoints/BulletPoints";
import PostMethod from "../../../../../../../FetchMethods/PostMethod";
import { GET_PRODUCT_EDIT_NAME } from "../../../../../../../../Constants";
import CheckModal from "./CheckModal/CheckModal";
import ModulesBarManager from "../../../../../../ModulesBarManager";
import {AiOutlineLoading} from "react-icons/ai"
import Description from "./Description/Description";
import ProductAttributes from "./ProductAttributes/ProductAttributes";

const ProductEditFieldsComponent =() =>{

    const product_edit_context = useContext(ProductEditContext);
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [nameChanging, setNameChanging] = useState();
    const [duplicates, setDuplicates] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setNameChanging(JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).label)
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            index:JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).config.id,
            name:JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).label,
            language:'pl',
            refLanguage:'pl'
        }
        PostMethod(GET_PRODUCT_EDIT_NAME, data, responseHandler);
    },[JSON.parse(localStorage.getItem("ModulesBar")).length])

    
    useEffect(()=>{
        if(!nameChanging){
            let defaultName = JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).label
            setNameChanging(defaultName === '' ? null : defaultName)
        }
        if(nameChanging){
            let editing = setTimeout(()=>{
                const data = {
                    db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                    index:JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).config.id,
                    name:nameChanging && nameChanging.replace("'", '&amp;'),
                    language:'pl',
                    refLanguage:'pl'
                }
                setLoading(true)
                PostMethod(GET_PRODUCT_EDIT_NAME, data, responseHandler);
                admin_panel_ctx.setReloadProductsEdit(true)
            },300)
            return ()=>{
                clearTimeout(editing)
            }
        }
    },[nameChanging])

    useEffect(()=>{
        if(admin_panel_ctx.reloadProductsEdit){
            const data = {
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                index:JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).config.id,
                name:nameChanging && nameChanging.replace("'", '&amp;'),
                language:'pl',
                refLanguage:'pl'
            }
            PostMethod(GET_PRODUCT_EDIT_NAME, data, responseHandler);
            admin_panel_ctx.setReloadProductsEdit(false)
            setLoading(false)
        }
    },[admin_panel_ctx.reloadProductsEdit])

    const responseHandler =(data)=>{
        if(data.data.duplicates){
            setDuplicates(data.data.duplicates)
        }else{
            setDuplicates(null)
        }
        if(nameChanging !== JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).label && nameChanging){
            ModulesBarManager(nameChanging, "changeModulesBarItemLabel", {id:JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === product_edit_context.product.config.id).config.id})
            admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
            admin_panel_ctx.setReloadProducts(true);
        }
    }
    
        const FieldsToRender = [{fieldId:1, name:"Basic Informations", component:<></>}, {fieldId:2, name:"Metadata", component:<Metadata/>}, {fieldId:3, name:"Bullet Points", component:<BulletPoints/>}, {fieldId:4, name:"Media", component:<></>}, {fieldId:5, name:"Description", component:<Description/>}, {fieldId:6, name:"Attributes", component:<ProductAttributes/>}]
        const FieldsWithoutTopBar = [2,3,4,5,6]

    const isFieldWithoutTopBar = (fieldId) =>{
        return FieldsWithoutTopBar.includes(fieldId)
    }


    return(
        <>
            <Grid className={'ProductEditFields'} sx={{backgroundColor:'gray'}}>
                <Grid item xs={12} sx={{ height: isFieldWithoutTopBar(product_edit_context.selectedOperationField) ? '0%': '15%', backgroundColor:"#4C4F51"}} p={isFieldWithoutTopBar(product_edit_context.selectedOperationField) ? 0 : 3}>
                    {product_edit_context.selectedOperationField === 1 && 
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:(duplicates && (duplicates.length > 0)) ? '#F9CB9C' :admin_panel_ctx.cPanel.default}}>
                            <InputBase
                                sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }}
                                value={nameChanging} 
                                onChange={(e)=>setNameChanging(e.target.value)}
                                fullWidth={true}
                                onKeyUp={(e)=>{if(e.keyCode === 13) e.target.blur()}}
                                />
                            <Typography fontSize={15} p={1} color={'gray'}>{nameChanging && nameChanging.trim().length}</Typography>
                            {loading ? <AiOutlineLoading size={30} className="loading"/> 
                            :
                            (duplicates && duplicates.length > 0) ? 
                            <IconButton style={{padding:0}}><AiFillWarning color="darkOrange" size={30} margin={0} onClick={()=>product_edit_context.setCheckModalOpen(true)}/></IconButton>
                            :
                            <IconButton style={{padding:0}}><BsFillCheckCircleFill color="green" size={30} margin={0}/></IconButton>
                            }
                    </Paper>
                    }
                </Grid>
                <Grid item xs={12} className='scrollerY' sx={{overflowY:'auto', height: isFieldWithoutTopBar(product_edit_context.selectedOperationField) ? '100%': '85%'}}>
                        {FieldsToRender.find(x=>x.fieldId === product_edit_context.selectedOperationField).component}
                </Grid>
            </Grid>
            {product_edit_context.checkModalOpen && <CheckModal duplicates={duplicates} close={()=>product_edit_context.setCheckModalOpen(false)}/>}
        </>
    )
}

export default ProductEditFieldsComponent;