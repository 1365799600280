import { Grid } from "@mui/material";
import BlogPostsComponent from "./BlogPostsComponents/BlogPostsComponent";
import BlogPostsContext from "../../../../../Context/BlogPostsContext";
import { useState } from "react";
import BlogPostsContentComponent from "./BlogPostsComponents/BlogPostsContentComponent";

const BlogPost =()=>{

    const [listOfPosts, setListOfPosts] = useState([]);
    const [currentBlogPost, setCurrentBlogPost] = useState([]);

    return (
        <BlogPostsContext.Provider
        value={{
            currentBlogPost,
            listOfPosts,
            setListOfPosts,
            setCurrentBlogPost
        }}>
        <Grid container>
            <Grid item xs={2}>
                <BlogPostsComponent/>
            </Grid>
            <Grid item xs={10}>
                <BlogPostsContentComponent/>
            </Grid>
        </Grid>
        </BlogPostsContext.Provider>
    )
}
export default BlogPost;