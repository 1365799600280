import { Grid } from "@mui/material";
import ProductEditOptionsComponent from "../ProductEditComponents/ProductEditOptionsComponent/ProductEditOptionsComponent";
import ProductEditFieldsComponent from "../ProductEditComponents/ProductEditFieldsComponent/ProductEditFieldsComponent";

const DefaultProductEdit=()=>{
    return(
        <Grid container>
            <Grid item xs={2}>
                <ProductEditOptionsComponent/>
            </Grid>
            <Grid item xs={10}>
                <ProductEditFieldsComponent/>
            </Grid>
        </Grid>
    )
}
export default DefaultProductEdit;