import { Grid } from "@mui/material";

const WarehouseSidePanel = () =>{

    return (
        <Grid container>
            <Grid item pb={2}>informacje o cenach</Grid>
            <Grid item pb={2}>informacje 2</Grid>
            <Grid item pb={2}>informacje 3</Grid>
        </Grid>
    );
}
export default WarehouseSidePanel;