import { Grid, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import { useContext } from 'react';
import AdminPanelContext from '../../../../Context/AdminPanelContext';
import ModulesBarManager from "../../ModulesBarManager";
const Marketing = ()=> {

    const admin_panel_ctx = useContext(AdminPanelContext);

    const BlogPostsClick =()=>{
        if(admin_panel_ctx.isBlogPostsOpen){
            ModulesBarManager("BlogPosts", "show", {componentType:"BlogPosts"})
        }
        else{
            admin_panel_ctx.setIsBlogPostsOpen(true);
            ModulesBarManager("BlogPosts", "push", {componentType:"BlogPosts"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }

    return (
            <Grid container>
                <Grid mt={1} p={0} xs={12} item>

                    <MenuList sx={{padding:0}}>
                        <MenuItem onClick={BlogPostsClick}>
                            <ListItemText align="center"><Typography fontSize={12} color={admin_panel_ctx.cText.negative}>Opisy na bloga</Typography></ListItemText>
                        </MenuItem>
                    </MenuList>

                </Grid>
            </Grid>
    )
}
export default Marketing;