import { createPortal } from "react-dom";
import styles from "./PhotoModal.module.css";
import {RiCloseFill} from 'react-icons/ri';
import { useEffect, useState } from "react";
import {BsFillArrowRightCircleFill,BsFillArrowLeftCircleFill} from "react-icons/bs";
import { Grid, Typography } from "@mui/material";
import { render } from "react-dom";


const PhotoModal=(props)=>{
    const [index, setIndex] = useState(0);


    useEffect(()=>{
        document.onkeydown = (e)=>{
            if (e.keyCode == 27) {
               props.close();
           }
       };
    },[])


    const slideRight = () => {
        setIndex((index + 1) % props.productData.photos.length);
      };

    const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
        setIndex(props.productData.photos.length - 1);
    } else {
        setIndex(nextIndex);
    }
    };

    const getProperData=(key) =>{
        switch(key){
            case 'description':
                return(
                    <div dangerouslySetInnerHTML={{__html: JSON.parse(props.productData.product_import)["description"]}}></div>
                    )
            case 'product_sku':
                let join = ''
                let sku = JSON.parse(props.productData.product_import)["product_sku"].split('');
                sku.map(i=>{
                    i==="-" ? join += " - " : join += "X";
                })
                return join;
        }
    }

    return (
        createPortal(
            <div className={styles.photo_modal}>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                    <div className={styles.photo_modal_close_icon_container}><RiCloseFill className={styles.photo_modal_close_icon} size={40} onClick={()=>props.close()}/></div>
                    </Grid>
                    {console.log(props.productData.photos.length)}
                    <Grid item xs={6} mt={10}>
                        <div className={styles.photos_container}>
                            <div className={styles.main_image_container}>
                                {props.productData.photos.length > 1 && <BsFillArrowLeftCircleFill className={styles.arrow} size={40} onClick={slideLeft}/>}
                                    <div className={styles.main_image_div}>
                                        <img className={styles.main_image} src={props.productData.photos[index]} alt={index} />
                                    </div>
                                {props.productData.photos.length > 1 && <BsFillArrowRightCircleFill className={styles.arrow} size={40} onClick={slideRight}/>}
                            </div>
                            {props.productData.photos.length >1 &&
                            <div className={styles.all_images}>
                                {props.productData.photos.map((pht)=>{
                                    return <div key={pht} className={styles.small_image} style={pht === props.productData.photos[index]? {boxShadow:'black 1px 1px 10px 1px', borderRadius:'10px'}:{}} ><img className={styles.small_img} src={pht} onClick={()=>setIndex(props.productData.photos.indexOf(pht))}/></div>
                                })}
                            </div>    
                            }
                        </div>
                    </Grid>

                    <Grid container item xs={6}>
                        <Grid container item xs={12} mt={10} pr={10}className="scrollerY" sx={{color:'black', height:'75vh', maxHeight:'1000px', boxShadow:"black 1px 0 10px 0", borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px", overflowY:'auto'}} p={4} mr={5}>
                            <Grid item sx={{overflow:'hidden'}}>
                            {Object.keys(JSON.parse(props.productData.product_import)).map(key=>{
                                if(key && typeof(JSON.parse(props.productData.product_import)[key]) !== 'object'){
                                    return <Typography fontSize={20} key={key} id={'modalTypography'} style={{marginTop:'20px'}}> <b>{key}</b> : {(key==="description"|| key==="product_sku") ? getProperData(key) : JSON.parse(props.productData.product_import)[key]}</Typography>
                                }
                                else if(key && typeof(JSON.parse(props.productData.product_import)[key]) === 'object'){
                                    return <Typography component={'span'} fontSize={20} key={key} id={'modalTypography'} style={{marginTop:'20px'}}> <b>{key}</b> : {JSON.stringify(JSON.parse(props.productData.product_import)[key])}</Typography>
                                }
                            })}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
            ,document.getElementById('modal'))
    )
}

export default PhotoModal;