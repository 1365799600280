import { Grid, Typography } from "@mui/material";
import { useState, useContext } from "react";
import AdminPanelContext from "../../../../../../../../../../Context/AdminPanelContext";
import styles from "./ProductAttributesGroup.module.css"
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ProductAttributesInGroup from "../ProductAttributesInGroup/ProductAttributesInGroup";

const ProductAttributesGroup = (props)=>{
    const [expanded, setExpanded] = useState();
    const admin_panel_ctx = useContext(AdminPanelContext);
    const Groups = props.data;

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    return (

        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                {!Groups && <Typography fontSize={15} color={'darkgray'} textAlign={'center'} m={3}>Brak grup dla tego obszaru</Typography>}
                {Groups &&  
                Object.keys(Groups).map((group, i) => {
                    return(
                        <MuiAccordion key={i} disableGutters elevation={0} expanded={expanded === i} onChange={handleChange(i)} sx={{animationDelay:`${40+i*50}ms`}}  className={styles.accordion}>
                            <MuiAccordionSummary sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative, boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <Grid container item xs={12}>
                                    <Typography fontSize={13} color={admin_panel_ctx.cText.negative} mt={1}>{Groups[group].assets.name.pl}</Typography>
                                </Grid>
                            </MuiAccordionSummary>
                            <MuiAccordionDetails className={styles.accrdeon_details} sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative , boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <div className={`${styles.accrdeon_details_scroller} scrollerY`}>
                                    <div style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
                                        {expanded === i && <ProductAttributesInGroup groupId={Groups[group].id}/>}
                                    </div>
                                </div>
                            </MuiAccordionDetails>
                        </MuiAccordion>
                        )   
                    })}
            </Grid>
        </Grid>
    )
}
export default ProductAttributesGroup;