import { useContext } from "react";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import styles from "./Warehouse.module.css"
import WarehouseContext from "../../../../Context/WarehouseContext";
import WarehouseContent from "./WarehouseContent/WarehouseContent";
import WarehouseTopPanel from "./WarehouseTopPanel/WarehouseTopPanel";
import WarehouseSidePanel from "./WarehouseSidePanel/WarehouseSidePanel";

const Warehouse =()=>{
    const admin_panel_ctx = useContext(AdminPanelContext);

    return (
        <WarehouseContext.Provider
            value={{
            }}
        >
            <div className={styles.bottom_left_panel_top} style={{backgroundColor:admin_panel_ctx.color1}}><WarehouseTopPanel/></div>
            <div className={styles.bottom_left_panel_side} style={{backgroundColor:admin_panel_ctx.color1}}><WarehouseSidePanel/></div>
            <div className={styles.bottom_left_panel_content} style={{backgroundColor:admin_panel_ctx.color1}}><WarehouseContent/></div>
        </WarehouseContext.Provider>
    )
}
export default Warehouse;