import { Grid, NativeSelect } from "@mui/material";
import { useEffect, useState } from "react";
import PostMethod from "../../../../FetchMethods/PostMethod";
import { DEPLOY_KILL_URL, GET_ALL_ORGANIZATIONS, PREPARE_QUEUE_URL, PROCESS_QUEUE_URL } from "../../../../../Constants";
import { LoadingButton } from "@mui/lab";
import styles from "./AdminButtons.module.css";
import {AiOutlineLoading} from "react-icons/ai"

const AdminButtons = () =>{

    const [buttonLoading, setButtonLoading] = useState();
    const [selectValue, setSelectValue] = useState(0);
    const [allDatabases, setAllDatabases] = useState(null)

    useEffect(()=>{
        PostMethod(GET_ALL_ORGANIZATIONS, {}, getDatabaseResponse)
    },[])

    const getDatabaseResponse =(data)=>{
        const allDatabasesToAdd = []
        Object.keys(data).map(i=>{
            const singleData = {name:JSON.parse(data[i].settings).name, database:JSON.parse(data[i].config).database}
            allDatabasesToAdd.push(singleData);
        })
        setAllDatabases(allDatabasesToAdd)
    }

    const handleClick=url=>{
        const data={
            db:allDatabases[selectValue].database
        }
        PostMethod(url,data, getResponse);
    }

    const getResponse =() =>{
        setButtonLoading(null)
    }

    return(
        <Grid container>
            {allDatabases?
            <>
                <Grid container item xs={12} alignItems={'center'} justifyContent={'center'}>
                    <NativeSelect
                        className={styles.AdminButtonsSelect}
                        value={selectValue}
                        onChange={(e)=>{setSelectValue(e.target.value)}}
                        sx={{color:'white'}}
                        >
                        {allDatabases && allDatabases.map(database=>{
                            return <option key={allDatabases.indexOf(database)} value={allDatabases.indexOf(database)}>{`${database.name}(${database.database})`}</option>
                        })}
                    </NativeSelect>    
                </Grid>
                <Grid container item spacing={2}>
                        <Grid item><LoadingButton variant="contained" size="small" onClick={()=>{handleClick(PREPARE_QUEUE_URL); setButtonLoading(1)}} loading={buttonLoading===1}>Prepare</LoadingButton></Grid>
                        <Grid item><LoadingButton variant="contained" size="small" onClick={()=>{handleClick(PROCESS_QUEUE_URL); setButtonLoading(2)}} loading={buttonLoading===2}>Process</LoadingButton></Grid>
                        {/* <Grid item><LoadingButton variant="contained" size="small" onClick={()=>{handleClick(DEPLOY_KILL_URL); setButtonLoading(3)}} loading={buttonLoading===3}>Kill</LoadingButton></Grid> */}
                </Grid>
            </>
            :
            <Grid item xs={12} textAlign={'center'} mt={4}><AiOutlineLoading size={30} className="loading"/></Grid>
            }
        </Grid>
    )
}
export default AdminButtons;