import { Button, Divider, Grid, IconButton } from "@mui/material";
import styles from "./CategoryOptions.module.css"
import { useContext } from "react";
import CategoryContext from "../../../../../../../Context/CategoryContext";

export const FieldOptions = [{id:1, name:'Menu'}, {id:2, name:'Konfiguracja'}]

const CategoryOptions =()=>{
    const category_context = useContext(CategoryContext)
    
    return (
        <Grid container className={`${styles.CategoryOptionsField} CategoryOptionsField`} p={1} color={'white'}>
            <IconButton sx={{ py: '10px' }} aria-label="menu">
                <img src="/images/flags/pl.png" width={'35px'} height={'35px'} style={{border:'1px solid gray', borderRadius:'30px'}}/>
            </IconButton>
            <Divider/>
            {FieldOptions.map(opt=>{
                return <Grid item xs={12} mt={1} key={opt.id}><Button className={styles.fieldButton} variant={category_context.currentField === opt.id ? "contained" : "standard" } onClick={()=>{category_context.setCurrentField(opt.id)}} fullWidth>{opt.name}</Button></Grid>
            })}
        </Grid>
    )
}
export default CategoryOptions;