import { Button, Divider, Grid, IconButton, InputBase, Paper, TextField } from "@mui/material";
import { useContext } from "react";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import styles from "./Bulks.module.css";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { useEffect } from "react";
import { GET_ATTRIBUTES_GROUP_URL, GET_BULKS_URL, UPDATE_ATTRIBUTES_GROUP_URL } from "../../../../Constants";
import AddNewBulkModal from "./AddNewBulkModal/AddNewBulkModal";
import {AiOutlineEdit} from"react-icons/ai";
import EditBulkModal from "./EditBulkModal/EditBulkModal";
import AttributesGroups from "./AttributesGroups";
import AttributesGroupSettings from "./AttributesGroupSettings/AttributesGroupSettings";
import SettingsIcon from '@mui/icons-material/Settings';
import Attributes from "./Attributes";
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import PostMethod from "../../../FetchMethods/PostMethod";
import SearchIcon from '@mui/icons-material/Search';



const Bulks =() =>{
    
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [expanded, setExpanded] = useState(false);
    const [attributes, setAttributes] = useState(0);
    const [bulkAdding, setBulkAdding] = useState();
    const [bulkEditing, setBulkEditing] = useState();
    const [attributesGroup, setAttributesGroup] = useState();
    const [attributesGroupToLoad, setAttributesGroupToLoad]=useState();
    const [showPanel, setShowPanel] = useState(0);
    const [nameEditing, setNameEditing] = useState();

    useEffect(()=>{
        GetData();
    },[])

    useEffect(()=>{
        if(expanded !== false) {
            let bulk = getCurrentBulk();
            getGroups(bulk.id);
        };
    },[expanded])

    useEffect(()=>{
        setNameEditing(attributesGroupToLoad ? attributesGroupToLoad.assets.name.pl : null)
    },[attributesGroupToLoad])

    useEffect(()=>{
        const sendNewName = setTimeout(()=>{
        if(attributesGroupToLoad && (nameEditing !== attributesGroupToLoad.assets.name.pl)){
                const data={
                    db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                    id:attributesGroupToLoad.id,
                    bulkId:attributesGroupToLoad.bulkId,
                    active:attributesGroupToLoad.active==0? 0 : 1,
                    assets:{
                        name:{
                        pl:nameEditing,
                        en:attributesGroupToLoad.assets.name.en
                    }
                },
                token:attributesGroupToLoad.token,
                settings:{
                    priority:attributesGroupToLoad.settings.priority,
                    autoopen:attributesGroupToLoad.settings.autoOpen,
                    pill:attributesGroupToLoad.settings.pill,
                    hidden:attributesGroupToLoad.settings.hidden,
                    sortBy:attributesGroupToLoad.settings.sortBy
                }
            }
            PostMethod(UPDATE_ATTRIBUTES_GROUP_URL, data, getResponse)
        }
    }
        ,1000);
        return ()=>{
            clearTimeout(sendNewName);
        }
    },[nameEditing])

    const getResponse = (data)=>{
        if(data){
            GetData();
            let bulk = getCurrentBulk();
            getGroups(bulk.id);
        }
    }


    const getGroups=(bulkId)=>{
        fetch(GET_ATTRIBUTES_GROUP_URL, {
            body:JSON.stringify({
                bulkId:bulkId,
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
            }),
            method:"POST"
        })
        .then(response => response.json())
        .then(data => setAttributesGroups(data));
    }
    const setAttributesGroups=(data)=>{
        setAttributesGroup(data);
        if(JSON.stringify(data) === '{}') setAttributesGroup(null)
    }

    const GetData=()=>{
        fetch(GET_BULKS_URL,{
            body:JSON.stringify({db:JSON.parse(localStorage.getItem("currentOrganization")).config.database}),
            method:"POST"
        })
        .then(response => response.json())
        .then(data => setAttributes(data));
    }

    const editBulk= (event,element,id )=>{
        event.stopPropagation();
        element.id = id;
        setBulkEditing(JSON.stringify(element));
    }
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getAttributeGroupToLoad = (attributeGroup,attributeGroupId, bulkId) => {
        setAttributesGroupToLoad(false);
        setTimeout(()=>{
            attributeGroup['id']= parseInt(attributeGroupId);
            attributeGroup['bulkId'] = bulkId
            setAttributesGroupToLoad(attributeGroup);
        }, 10)
    }

    const getCurrentBulk=()=>{
        let objects = []
        Object.keys(attributes).map((element,i)=>{
            objects.push(attributes[element])
        })
        return objects[expanded];
    }


    let el;
    return(
<>
        <Grid container className="zoomOnShow">
            <Grid container item p={2} xs={5}>

            <Grid item xs={12} p={2} className={styles.bulks_container} style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
                <Grid container pb={2}>
                    <Grid item xs={12} textAlign={'right'}><Fab style={{width:35, height:25}} color='info' variant="contained" size="small" onClick={()=>setBulkAdding(!bulkAdding)}><AddIcon/></Fab></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                {Object.keys(attributes).map((element, i) => {
                    {el = attributes[element]}
                    
                    return(
                        <MuiAccordion key={i} disableGutters elevation={0} expanded={expanded === i} onChange={handleChange(i)} sx={{animationDelay:`${40+element*50}ms`}}  className={styles.accordion}>
                            <MuiAccordionSummary sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative, boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>

                                <Grid container item xs={12}>
                                    <Grid item xs={10}>
                                        <Typography fontSize={13} color={admin_panel_ctx.cText.negative} mt={1}>{el.assets.name.pl}</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign={'right'}>
                                            <AiOutlineEdit className={styles.accrdeon_edit_icon} size={20} onClick={(e)=>editBulk(e,attributes[element],element)}/>
                                    </Grid>
                                </Grid>

                            </MuiAccordionSummary>
                            <MuiAccordionDetails className={styles.accrdeon_details} sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative , boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <div className={`${styles.accrdeon_details_scroller} scrollerY`}>
                                    <div style={{padding:'15px', backgroundColor:admin_panel_ctx.cSheet.default}}>
                                        <AttributesGroups data={attributesGroup? attributesGroup:null} bulkId={parseInt(attributes[element].id)} reload ={()=>getGroups(parseInt(attributes[element].id))} setAttributeGroupToLoad={(group, groupId)=>getAttributeGroupToLoad(group, groupId, parseInt(attributes[element].id))}/>
                                    </div>
                                </div>
                            </MuiAccordionDetails>
                        </MuiAccordion>)
                    })
                }
                    </Grid>
                </Grid>
            </Grid>
            </Grid>

            <Grid container item pr={2} py={2} xs={7}>
                <Grid item xs={12} p={1} className={styles.bulks_container} style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
                    {attributesGroupToLoad && 
                    
                        <Grid container>
                                    {/* <Typography fontSize={10} my={1} sx={{color:'silver'}}>{attributesGroupToLoad.id}</Typography> */}
                            <Grid item xs={12}>
                                <Paper
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:admin_panel_ctx.cPanel.default}}
                                >
                                    <IconButton sx={{ py: '10px' }} aria-label="menu">
                                        <img src="/images/flags/pl.png" width={'20px'} style={{border:'1px solid gray'}}/>
                                    </IconButton>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1, color:admin_panel_ctx.cText.default }}
                                        value={nameEditing} 
                                        onChange={(e)=>setNameEditing(e.target.value)}
                                        fullWidth={true}
                                        onKeyUp={(e)=>{if(e.keyCode === 13) e.target.blur()}}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton onClick={()=>{showPanel && setTimeout(()=>{setShowPanel(0)},500);setShowPanel(showPanel===0 ? true : !showPanel)}} sx={{ p: '10px', color:'black' }} aria-label="menu">
                                        <SettingsIcon sx={showPanel&&{color:'#0288D1'}}/>
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} className={`${showPanel === 0 &&styles.change_group_settings} ${showPanel && styles.change_group_settings_show} ${showPanel===false && styles.change_group_settings_hide}`}>
                                <Paper component="form" sx={{ mt:'2px', p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:admin_panel_ctx.color2 }}>
                                    <AttributesGroupSettings reload={getGroups} data={attributesGroupToLoad} nameEditing={nameEditing}/>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Attributes groupId={attributesGroupToLoad.id}/>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>

        </Grid>
        {bulkAdding && <AddNewBulkModal closeModal={setBulkAdding} reload={GetData}/>}
        {bulkEditing && <EditBulkModal data={bulkEditing} closeModal={setBulkEditing} reload={GetData}/>}

</>
    )
}

export default Bulks;