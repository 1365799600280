import { createPortal } from "react-dom";
import styles from "./CheckModal.module.css"
import { Button, Grid, Typography } from "@mui/material";
import {RiCloseFill} from 'react-icons/ri';
import ModulesBarManager from "../../../../../../../ModulesBarManager";
import { useContext } from "react";
import AdminPanelContext from "../../../../../../../../../Context/AdminPanelContext";

const CheckModal =(props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext)

    const ShowDuplicate = (id, name) =>{
        let decodedModuleBar = JSON.parse(localStorage.getItem("ModulesBar"));
        console.log(decodedModuleBar.find(x=>x.config.id === id))
        if(!decodedModuleBar.find(x=>x.config.id === id)){
            ModulesBarManager(name, "push", {componentType:"ProductEdit", id:id, name:name});
        }
        else{
            ModulesBarManager(name, "show", {componentType:"ProductEdit", id:id})
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }
    return (
        <>
            {createPortal(
                <div className={styles.check_modal}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className={styles.check_modal_close_icon_container}><RiCloseFill className={styles.check_modal_close_icon} size={40} onClick={()=>props.close()}/></div>
                            <Grid container>
                                <Grid container item xs={12} color={'black'}>
                                    <Typography fontSize={25} mb={2} mx={2} color={'gray'}>{props.uri ? "Indexy z powielonymi uri" : "Indexy z powielonymi nazwami"}</Typography>
                                    {props.duplicates && props.duplicates.map(dup=>{
                                        return (
                                            <Grid container item xs={12} mx={2} justifyContent={'center'} alignItems={'center'} key={dup.id}>
                                                <Grid item xs={2} className={styles.cell} p={2}><Typography fontSize={15} textAlign={'center'}>Id:{dup.id}</Typography></Grid>
                                                {dup.uri ? 
                                                    <Grid container item className={styles.cell} xs={8} p={1}>
                                                        <Grid item xs={12}><Typography fontSize={15} textAlign={'left'}>{dup.name}</Typography></Grid>
                                                        <Grid item xs={12}><Typography fontSize={11} fontWeight={700} textAlign={'left'} color={'gray'}>{dup.uri}</Typography></Grid>
                                                    </Grid>
                                                :
                                                    <Grid item xs={8} className={styles.cell} p={2}><Typography fontSize={15} textAlign={'left'}>{dup.name}</Typography></Grid>
                                                }
                                                <Grid item xs={2} className={styles.cell} p={2}><Button variant="contained" fullWidth style={{padding:0}} onClick={()=>ShowDuplicate(dup.id, dup.name)}>Show</Button></Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                ,document.getElementById('modal'))}
            {createPortal(<div className={styles.backdrop} onClick={()=>props.close()}/>, document.getElementById('backdrop'))}
        </>
            )
}
export default CheckModal;