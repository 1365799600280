import { Button, Fab, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GET_ATTRIBUTES_URL } from "../../../../../../../Constants";
import AdminPanelContext from "../../../../../../../Context/AdminPanelContext";
import IndexesManagerContext from "../../../../../../../Context/IndexesManagerContext";
import PostMethod from "../../../../../../FetchMethods/PostMethod";
import styles from "./IndexesManagerAttributes.module.css";
import AddIcon from '@mui/icons-material/Add';

const IndexesManagerAttributes =(props)=>{
    
    const admin_panel_ctx = useContext(AdminPanelContext) 
    const indexes_manager_ctx = useContext(IndexesManagerContext) 
    const [attributes, setAttributes] = useState();

    useEffect(()=>{
        if(props.groupId){   
            getAttributesForGroup();
        }
    },[])

    const getAttributesForGroup=()=>{
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            groupId:props.groupId
        }
        PostMethod(GET_ATTRIBUTES_URL,data,getResponse)
    }

    const getResponse=(data)=>{
        if(JSON.stringify(data) !== "{}"){
            setAttributes(data);
        }
    };


    const ifObjectOnList = (id) =>{
        let result=false
        indexes_manager_ctx.attributesList.map(object=>{
            if(object.id == id){
            result=true}
        })
        return result;
    }

    const returnObjectOnList =(id) =>{
        let obj = null;
        indexes_manager_ctx.attributesList.map(object=>{
            if(object.id === id)
            obj = object;
        })
        return obj;
    }

    const ManageAtributeOnList = (id, field) =>{
        if(field === "including"){
            if(!ifObjectOnList(id)){
                indexes_manager_ctx.attributesList.push({id:id, name:attributes[id].assets.name.pl, including:true, notIncluding:false});
            }
            else{
                let newAttributeList = indexes_manager_ctx.attributesList;
                let object = returnObjectOnList(id);
                let indexOfObject = newAttributeList.indexOf(object);
                newAttributeList.splice(indexOfObject,1);
                object.including = true;
                object.notIncluding = false;
                newAttributeList.push(object)
                indexes_manager_ctx.setAttributesList(newAttributeList);
            };
        }
        else if(field === "notIncluding"){
            if(!ifObjectOnList(id)){
                indexes_manager_ctx.attributesList.push({id:id, name:attributes[id].assets.name.pl, including:false, notIncluding:true});
            }
            else {
                let newAttributeList = indexes_manager_ctx.attributesList;
                let object = returnObjectOnList(id);
                let indexOfObject = newAttributeList.indexOf(object);
                newAttributeList.splice(indexOfObject,1);
                object.including = false;
                object.notIncluding = true;
                newAttributeList.push(object)
                indexes_manager_ctx.setAttributesList(newAttributeList);
            }
        }
        else if(indexes_manager_ctx.operationsField === 3 && field === "attributeInGroup"){
            if(indexes_manager_ctx.attributesListInGroupToAdd.indexOf(attributes[id]) === -1){
                let newList = indexes_manager_ctx.attributesListInGroupToAdd;
                newList.push(attributes[id]);
                indexes_manager_ctx.setAttributesListInGroupToAdd(newList);
            }
        }
        else if(indexes_manager_ctx.operationsField === 2 && field === "attributeInGroup"){
            if(indexes_manager_ctx.attributesListInSingleToAdd.indexOf(attributes[id]) === -1){
                let newList = indexes_manager_ctx.attributesListInSingleToAdd;
                newList.push(attributes[id]);
                indexes_manager_ctx.setAttributesListInSingleToAdd(newList);
            }
        }
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1);
    }

    const checkIfNotInRemoveFieldGroup = (attributeName) =>{
        let bool = true;
        Object.keys(indexes_manager_ctx.attributesListInProductsInGroup).map(atr=>{
            if(indexes_manager_ctx.attributesListInProductsInGroup[atr].attribute === attributeName) bool = false;
        })
        return bool;
    }
    const checkIfNotInRemoveFieldFound = (attributeName) =>{
        let bool = true;
        Object.keys(indexes_manager_ctx.attributesListInProductsInFound).map(atr=>{
            if(indexes_manager_ctx.attributesListInProductsInFound[atr].attribute === attributeName) bool = false;
        })
        return bool;
    }

    const isDisabled = (id,field) =>{
        if(indexes_manager_ctx.operationsField === 3 && field === "attributeInGroup"){
            if(indexes_manager_ctx.attributesListInGroupToAdd.indexOf(attributes[id]) === -1 && checkIfNotInRemoveFieldGroup(attributes[id].assets.name.pl)) return false
            return true
        }else if(indexes_manager_ctx.operationsField === 2 && field === "attributeInGroup"){
            if(indexes_manager_ctx.attributesListInSingleToAdd.indexOf(attributes[id]) === -1 && checkIfNotInRemoveFieldFound(attributes[id].assets.name.pl)) return false
            return true
        }
        else{

            let obj = returnObjectOnList(id);
            if(obj){
                let included = obj.including;
                if(field === 'including'){
                    return included;
                } 
                else if(field === 'notIncluding'){
                    return !included
                }
            }
        }
        return false;
    }

    const changeOperationField = ()=>{
        indexes_manager_ctx.setOperationsField(1); 
        indexes_manager_ctx.setBoxGroupField(0)
    }

    return (
        <>
        {indexes_manager_ctx.timer && 
        <div className={`${styles.attributes_scroller} scrollerY`}>
            <div style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
            <Grid container id={`attributesContainer`} sx={{display:'inline-block'}}>
            {attributes ? Object.keys(attributes?attributes:'').map((a, i)=>{
                return (
                    <Paper
                    data-id={a}
                    key={a}
                    className={`${styles.attribute_object} attribute_object`}
                    sx={{margin:1, padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative, display:'inline-block'}}
                    >{attributes[a].assets.name.pl}
                        {indexes_manager_ctx.operationsField !== 1 && (indexes_manager_ctx.operationsField === 2 ? indexes_manager_ctx.isOnlyOneChosen : true) ?
                         <Fab sx={{width:25, height:25, minHeight:25, ml:1}} color='warning' variant="contained" onClick={()=>{ManageAtributeOnList(a,"attributeInGroup")}} disabled={isDisabled(a,'attributeInGroup')}><AddIcon fontSize="10"/></Fab>
                         :
                         <>
                        <Fab sx={{width:25, height:25, minHeight:25, ml:1}} color='success' variant="contained" onClick={()=>{ManageAtributeOnList(a,"including"); changeOperationField()}} disabled={isDisabled(a,'including')}><AddIcon fontSize="10"/></Fab>
                        <Fab sx={{width:25, height:25, minHeight:25, ml:1}} color='error' variant="contained" onClick={()=>{ManageAtributeOnList(a,"notIncluding"); changeOperationField()}} disabled={isDisabled(a,'notIncluding')}><AddIcon fontSize="10"/></Fab>
                        </>
                        }
                    </Paper>)
                }
                )
            :
            <Typography fontSize={20} color={'darkgray'} textAlign={'center'} mt={3}>Brak atrybutów dla tej grupy</Typography>
            }
                </Grid>
                </div>
                </div>
            }
            </>
                )
            }
            export default IndexesManagerAttributes;