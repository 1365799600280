const PostMethod=(url, data, method=console.log, config=null)=>{
        fetch(url,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data =>method(data));
}

export default PostMethod