import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { GET_ATTRIBUTES_GROUP_URL, GET_BULKS_URL } from "../../../../../../Constants";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import styles from "./IndexesManagerAttributesField.module.css";
import { useContext } from "react";
import AdminPanelContext from "../../../../../../Context/AdminPanelContext";
import IndexesManagerAttributesGroups from "./IndexesManagerAttributesGroups/IndexesManagerAttributesGroups";

const IndexesManagerAttributesField =()=>{
    const [bulks, setBulks] = useState();
    const [expanded, setExpanded] = useState();
    const [groups, setGroups] = useState();
    const admin_panel_ctx = useContext(AdminPanelContext)

    
    useEffect(()=>{
        GetBulks();
    },[])
    useEffect(()=>{
        let bulksArray = [];
        Object.keys(bulks?bulks:'').map((grp, i)=>{
            bulksArray.push(bulks[grp]);
        })
        if (bulksArray.length !== 0 && expanded !==false) getGroups(bulksArray[expanded].id)
    },[expanded])

    const GetBulks=()=>{
        fetch(GET_BULKS_URL,{
            body:JSON.stringify({db:JSON.parse(localStorage.getItem("currentOrganization")).config.database}),
            method:"POST"
        })
        .then(response => response.json())
        .then(data => setBulks(data));
    }

    const getGroups=(bulkId)=>{
        fetch(GET_ATTRIBUTES_GROUP_URL, {
            body:JSON.stringify({
                bulkId:bulkId,
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
            }),
            method:"POST"
        })
        .then(response => response.json())
        .then(data => setAttributesGroups(data));
    }
    const setAttributesGroups=(data)=>{
        setGroups(data);
        if(JSON.stringify(data) === '{}') setGroups(null)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return(
        <Grid container>
            <Grid container item>
                {/* <Grid container item xs={12} mb={1}>
                    <TextField variant="outlined" placeholder="Wyszukaj atrybut..." fullWidth></TextField>
                </Grid> */}
                <Grid item xs={12}>
                    {bulks && Object.keys(bulks).map((bulk, i)=>{
                        return(
                            <MuiAccordion key={i} disableGutters elevation={0} expanded={expanded === i} onChange={handleChange(i)} sx={{animationDelay:`${40+i*50}ms`}}  className={styles.accordion}>
                            <MuiAccordionSummary sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative, boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <Grid container item xs={12}>
                                    <Typography fontSize={13} color={admin_panel_ctx.cText.negative} mt={1}>{bulks[bulk].assets.name.pl}</Typography>
                                </Grid>
                            </MuiAccordionSummary>
                            <MuiAccordionDetails className={styles.accrdeon_details} sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative , boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <div className={`${styles.accrdeon_details_scroller} scrollerY`}>
                                    <div style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
                                        <IndexesManagerAttributesGroups data={groups ? groups : null} />
                                    </div>
                                </div>
                            </MuiAccordionDetails>
                        </MuiAccordion>)
                })}
                </Grid>
            </Grid>
        </Grid>
    )
}
export default IndexesManagerAttributesField;