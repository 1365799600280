import { Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useContext } from "react";
import AdminPanelContext from "../../../../../Context/AdminPanelContext";
import ModulesBarManager from "../../../ModulesBarManager";

const Index = ()=>{
    const admin_panel_ctx = useContext(AdminPanelContext);

    const importIndexesListClick =() =>{
        if(admin_panel_ctx.isImportIndexesListOpen){
            ModulesBarManager("ImportIndexesList", "show", {componentType:"ImportIndexesList"})
        }
        else{
            admin_panel_ctx.setIsImportIndexesListOpen(true);
            ModulesBarManager("ImportIndexesList", "push", {componentType:"ImportIndexesList"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }
    return(
        <Grid container>
            <Grid p={0} xs={12} item>
                <MenuList sx={{padding:0}}>

                    <MenuItem onClick={importIndexesListClick}>
                        <ListItemIcon>
                            <BookmarkIcon sx={{color:"silver"}} fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText align="left"><Typography fontSize={12} color={admin_panel_ctx.cText.negative}>Lista indeksów</Typography></ListItemText>
                    </MenuItem>

                </MenuList>
            </Grid>
        </Grid>
    )
}
export default Index