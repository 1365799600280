import styles from './AddNewElementModal.module.css';
import { createPortal } from 'react-dom';
import {RiCloseFill} from 'react-icons/ri';
import { useEffect } from 'react';
import AddNewElementForm from './AddNewElementForm/AddNewElementForm';

const AddNewElementModal = props => {

    useEffect(()=>{
        document.onkeydown = (e)=>{
            if (e.keyCode == 27) {
                props.closeModal(false);
           }
       };
    },[])
    
    return(
        <>
            {createPortal(
            <div className={styles.new_element_modal}  style={{backgroundColor:'gray'}}>
                <div className={styles.new_element_modal_close_icon_container}><RiCloseFill className={styles.new_element_modal_close_icon} size={25} onClick={()=>props.closeModal(false)}/></div>
                <div className={styles.new_element_modal_form}>
                    <AddNewElementForm selected ={props.selected} closeModal={()=>props.closeModal(false)}/>
                </div>
            </div>, document.getElementById('modal'))}

            {createPortal(<div className={styles.backdrop} onClick={()=>props.closeModal(false)}/>, document.getElementById('backdrop'))}
        </>
    )
}

export default AddNewElementModal;