import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import AdminPanelContext from "../../../../../../../Context/AdminPanelContext";
import PhotoModal from "../../../PhotoModal/PhotoModal";
import styles from "./IndexesListModuleTable.module.css";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import ModulesBarManager from "../../../../../ModulesBarManager";
import Popover from '@mui/material/Popover';
import LinearProgress from '@mui/material/LinearProgress';
import IndexesListModuleTableSwitchComponent from "./IndexesListModuleTableSwitchComponent/IndexesListModuleTableSwitchComponent";

const IndexesListModuleTable = (props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [showPhotos, setShowPhotos] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isControlPressed, setIsControlPressed] = useState(false);

    window.onkeydown = listenToTheKey;
    window.onkeyup = removeProperties;
    function listenToTheKey(e)
    {
        if(e.key === 'Control'){
            setIsControlPressed(true)
        }
    }
    function removeProperties(e){setIsControlPressed(false)}

    const HandleEditClick = (object) =>{
        let decodedModuleBar = JSON.parse(localStorage.getItem("ModulesBar"));
        if(!decodedModuleBar.filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === object.id)){
            ModulesBarManager(object.identity.name, "push", {componentType:"ProductEdit", id:object.id, name:object.identity.name, isControlPressed:isControlPressed, shop_index_id:object.shop_index_id});
        }
        else{
            ModulesBarManager(object.identity.name, "show", {componentType:"ProductEdit", id:object.id, name:object.identity.name, isControlPressed:isControlPressed, shop_index_id:object.shop_index_id})
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }

    const handlePopoverOpen = (event, index, text) => {
      setAnchorEl({target:event.currentTarget, index:index, text:text});
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const checks =['uri', 'identity', 'metadata', 'content', 'media']

    return (
        <>
            <Grid container>

                <Grid container item xs={12} sx={{ xs:{backgroundColor:admin_panel_ctx.cSheet.default}}} p={1}>
                    <Grid container item xs={12} p={2}>
                        {Object.keys(props.columns).map(col => {
                            return (<Grid key={col} textAlign={props.columns[col].anchor} xs={props.columns[col].width} item>{props.columns[col].label}</Grid>)
                            })
                        }
                    </Grid>
                </Grid>

                {props.loading ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                      :
                <Grid container item xs={12} sx={{backgroundColor:admin_panel_ctx.cSheet.default}} mt={2} p={1}>
                    {Object.keys(props.rows).map((row, index)=>{
                        
                        return (
                            <Grid container item xs={12} key={index} p={1} sx={{borderBottom:'solid #1f1f1f 1px'}}>
                                
                                <Grid item xs={1}>
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                        <IndexesListModuleTableSwitchComponent id={props.rows[row].id} active={props.rows[row].active}/>
                                    </Grid>
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                        {checks.map((check,i)=>{
                                            return (
                                                <AiFillCloseCircle key={i} style={{padding:'3px', paddingTop:'20px'}} color="lightcoral" size={12} aria-owns={anchorEl ? `mouse-over-popover-${index}` : undefined} aria-haspopup="true"
                                                onMouseEnter={(e)=>handlePopoverOpen(e,index,check)}
                                                onMouseLeave={handlePopoverClose}
                                                />
                                            )
                                        })}
                                        <Popover
                                            id={`mouse-over-popover-${index}`}
                                            sx={{pointerEvents: 'none'}}
                                            open={anchorEl && anchorEl.index === index}
                                            anchorEl={anchorEl && anchorEl.target}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                                            transformOrigin={{vertical: 'top', horizontal: 'left'}}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                            >
                                            <Typography sx={{ p: 1 }}>{anchorEl && anchorEl.text}</Typography>
                                        </Popover>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={5}>
                                    <Grid container item xs={12}>
                                        <Grid container item xs={3} xl={2} justifyContent={'center'}>{props.rows[row].photo ? <img alt={'zdjęcie produktu'} src={`${props.rows[row].photo.path}${props.rows[row].photo.filename}`} style={{width:'60px', maxWidth:'100%', height:'auto'}}/>: "Brak zdjęcia" }</Grid>
                                        <Grid container item xs={9} xl={10} color={'silver'} px={1}>
                                            <Grid item xs={12}>
                                                <Typography fontSize={14}>{props.rows[row].identity.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={12}>sku: {props.rows[row].sku}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={12}>bazowe Id: {props.rows[row].id}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={12}>Id w sklepie: {props.rows[row].shop_index_id}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3} px={2} textAlign={'center'}><span style={{margin:0}}>ATRYBUTY</span></Grid>
                                <Grid item xs={3} px={2} textAlign={'center'}>
                                    <Button variant="contained" onClick={()=>HandleEditClick(props.rows[row])}>Edytuj</Button>
                                </Grid>

                            </Grid>
                        )
                    })}
                </Grid>
                }

            </Grid>
            {showPhotos && <PhotoModal photos={showPhotos} close={()=>setShowPhotos(null)}/>}
        </> 
    )
}

export default IndexesListModuleTable;