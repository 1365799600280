import styles from "./AuthTemplate.module.css";
const AuthTemplate = (props) =>{

    return(
        <div className={styles.mainContainer}>
            <div className={styles.formContainer}>
                {props.children}
            </div>
        </div>
    );

}

export default AuthTemplate;