import React, { useContext } from "react";
import styles from "./WrapperLeft.module.css";
import AdminPanelContext from "../../../../Context/AdminPanelContext";

const WrapperLeft = () =>{
    
    const admin_panel_ctx = useContext(AdminPanelContext);

    const setLeftOpen =(direction, number)=>{
        const left_pin_active = document.getElementsByClassName('left_pin_active').item(0);
        if(localStorage.getItem('leftPin')!=null){
            admin_panel_ctx.setLeftPinActive(true);
            localStorage.removeItem('leftPin');
        }
        if(direction === 'up'){
            if (admin_panel_ctx.isLeftUpOpen !== number) admin_panel_ctx.setIsLeftUpOpen(number);
            else if (!admin_panel_ctx.isLeftDownOpen && left_pin_active){
                admin_panel_ctx.setLeftPinActive(false);
                admin_panel_ctx.setIsLeftUpOpen(false);
            }
            else{
                admin_panel_ctx.setIsLeftUpOpen(false);
            }
        }
        else{
            if (admin_panel_ctx.isLeftDownOpen !== number) admin_panel_ctx.setIsLeftDownOpen(number);
            else if (!admin_panel_ctx.isLeftUpOpen && left_pin_active){
                admin_panel_ctx.setLeftPinActive(false);
                admin_panel_ctx.setIsLeftDownOpen(false);
            }
            else{
                admin_panel_ctx.setIsLeftDownOpen(false);
            }
        }
    }
    
    const getOrganizationPrivileges = ()=>{
        if(JSON.parse(localStorage.getItem('currentOrganization'))){
            return JSON.parse(localStorage.getItem('currentOrganization')).config.privileges;
        }
    } 

    return(
        <div id={'wrapperLeft'} className={styles.wrapper_left} style={{backgroundColor:admin_panel_ctx.color1}}>
            <div className={styles.wrapper_left_up}>
                <ul className={styles.wrapper_left_up_list}>
                    {getOrganizationPrivileges().sales.available && <li style={admin_panel_ctx.isLeftUpOpen===1?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setLeftOpen('up',1)}>Sprzedaż</li>}
                    {getOrganizationPrivileges().omnichannel.available && <li style={admin_panel_ctx.isLeftUpOpen===2?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setLeftOpen('up',2)}>Omnichannel</li>}
                </ul>
            </div>
            <div className={styles.wrapper_left_down}>
                <ul className={styles.wrapper_left_down_list}>
                    {getOrganizationPrivileges().clients.available && <li style={admin_panel_ctx.isLeftDownOpen===1?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setLeftOpen('down',1)}>Klienci</li>}
                    {getOrganizationPrivileges().marketing.available && <li style={admin_panel_ctx.isLeftDownOpen===2?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setLeftOpen('down',2)}>Marketing</li>}
                </ul>
            </div>
        </div>
    )
}

export default WrapperLeft;