import "./Menu.css";
import {AiOutlineEdit} from 'react-icons/ai';
import AddIcon from '@mui/icons-material/Add';
import { TreeItem, TreeView } from "@mui/lab";
import { useEffect, useState, useContext } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ModulesBarManager from "../../../../../../ModulesBarManager";
import { GET_CATEGORY_LIST } from "../../../../../../../../Constants";
import AddNewElementModal from "./AddNewElementModal/AddNewElementModal";
import { Box, Fab, Grid, LinearProgress, Typography } from "@mui/material";
import CategoryContext from "../../../../../../../../Context/CategoryContext";
import AdminPanelContext from "../../../../../../../../Context/AdminPanelContext";

const Menu = props => {
    const category_context = useContext(CategoryContext);
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [isAdding, setIsAdding] = useState(false);
    const [selected, setSelected] = useState();
    const [isControlPressed, setIsControlPressed] = useState(false);

    window.onkeydown = listenToTheKey;
    window.onkeyup = removeProperties;
    function listenToTheKey(e)
    {
        if(e.key === 'Control'){
            setIsControlPressed(true)
        }
    }
    function removeProperties(e){setIsControlPressed(false)}

    useEffect(()=>{
      if(selected){
        findInTree(category_context.mainCategories, selected.id)  
      }
    }, [selected])

    const findInTree = (tree, id) => {
      for(const nodeIdx in tree){
        const {label:name, children} = tree[nodeIdx]
        findInTree(children, id)
        if(tree[nodeIdx].id === id){
          let listToAdd = [];
          const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            'project-id':props.selectedProject,
            'parent':selected.id
          }
          fetch(GET_CATEGORY_LIST,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
            })
          .then(response => response.json())
          .then(data =>{
            if(data.data){
              Object.keys(data.data).map(child=>{
                let id = data.data[child].id;
                let label = data.data[child].settings.seo.pl.shortName;
                let children = {id:99*(id+1), label:'brak'}
                let childToAdd = {id:id, label:label, children:data.data[child].children ? [children] : []};
                listToAdd.push(childToAdd);
              })
            }
            tree[nodeIdx].children = listToAdd;
            category_context.setMainCategories([...category_context.mainCategories])
          }
          );
        }
      }
    }

    const editIconClick =(e, object) =>{
      e.stopPropagation();
      let decodedModuleBar = JSON.parse(localStorage.getItem("ModulesBar"));
      if(!decodedModuleBar.filter(x=>x.config.componentType === 'CategoryEdit').find(x=>x.config.id === object.id)){
          ModulesBarManager(object.label, "push", {componentType:"CategoryEdit", id:object.id, name:object.label, isControlPressed:isControlPressed});
      }
      else{
          ModulesBarManager(object.label, "show", {componentType:"CategoryEdit", id:object.id, name:object.label, isControlPressed:isControlPressed});
      }
      admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
  }

    const editIconWithLabel = object =>{
      return(
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          {object.label.replace(/(<([^>]+)>)/gi, "")}
          {object.label !== 'brak' && <AiOutlineEdit className='edit_icon' onClick={(e)=>editIconClick(e, object)} />}
        </div>
      )
    }

const renderTree = data => {
  if (!data.children) {
    return <TreeItem key={data.id} nodeId={`${data.id+1}`} onClick={()=>setSelected(data)} label={editIconWithLabel(data)} />;
  }
  return (
    <TreeItem key={data.id} nodeId={`${data.id+1}`} onClick={()=>setSelected(data)} label={editIconWithLabel(data)}>
      {data.children.map(child => {
        if (!child.children) {
          return <TreeItem key={child.id} nodeId={`${child.id+1}`} onClick={()=>setSelected(child)} label={editIconWithLabel(child)} />;
        }
        return renderTree(child);
      })}
    </TreeItem>
  );
};
  
    return (
        <>
          <Grid container>
              <Grid item xs={3} sx={{boxShadow:'#1f1f1f 1px 0 5px 0'}}>
                  <Grid container item xs={12} justifyContent={'right'} pt={2} px={2}>
                      <Fab style={{width:35, height:25}} color='info' variant="contained" size="small" onClick={()=>setIsAdding(true)}><AddIcon/></Fab>
                  </Grid>
                  {props.loading ? 
                  <Grid container item justifyContent={'center'}>
                    <Box sx={{ width: '90%', paddingTop:5}}>
                      <LinearProgress />
                    </Box>
                  </Grid>
                  :
                    (category_context.mainCategories ? 
                      <Grid item p={2} xs={12} mr={2}>
                      <TreeView
                      aria-label="file system navigator"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{flexGrow: 1, minWidth: '20%'}}
                      >
                      {category_context.mainCategories && category_context.mainCategories.map(data => renderTree(data))}
                      </TreeView>
                      </Grid>
                      :
                      <Typography textAlign={'center'} mt={3}>Brak kategorii dla tego projektu</Typography>
                    )
                  }
              </Grid>
          </Grid>
        {isAdding && <AddNewElementModal closeModal={setIsAdding} selected={selected ? selected : null}/>}
        </>
    )
}
export default Menu;