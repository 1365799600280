import { Button, Grid, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { UPDATE_INDEX_ATTRIBUTES_URL } from "../../../../../../Constants";
import AdminPanelContext from "../../../../../../Context/AdminPanelContext";
import IndexesManagerContext from "../../../../../../Context/IndexesManagerContext";
import PostMethod from "../../../../../FetchMethods/PostMethod";
import styles from "./IndexesManagerBoxGroupField.module.css";

const IndexesManagerBoxGroupField = ()=>{
    const indexes_manager_ctx = useContext(IndexesManagerContext)
    const admin_panel_ctx = useContext(AdminPanelContext)

    const removeAttributeToAdd =(attribute, config=null)=>{
        if(config === null){
            let newList = indexes_manager_ctx.attributesListInGroupToAdd;
            newList.splice(indexes_manager_ctx.attributesListInGroupToAdd.indexOf(attribute),1);
            indexes_manager_ctx.setAttributesListInGroupToAdd(newList);
        }
        else if (config==='single'){
            let newList = indexes_manager_ctx.attributesListInSingleToAdd;
            newList.splice(indexes_manager_ctx.attributesListInSingleToAdd.indexOf(attribute),1);
            indexes_manager_ctx.setAttributesListInSingleToAdd(newList);
        }
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1);
    }

    const setRemoveAttributes=(index, config) =>{
        if(config==="Group"){
            let newList = indexes_manager_ctx.removeAttributesInGroupChecked;
            if(!newList.includes(index)){
                newList.push(index)
                indexes_manager_ctx.setRemoveAttributesInGroupChecked(newList)
            }
        }
        else if(config==="Single"){
            let newList = indexes_manager_ctx.removeAttributesInFoundChecked;
            if(!newList.includes(index)){
                newList.push(index)
                indexes_manager_ctx.setRemoveAttributesInFoundChecked(newList)
            }
        }
        indexes_manager_ctx.setIsControlPressed(false)
        setTimeout(()=>{indexes_manager_ctx.setIsControlPressed(true)},5)
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1);
    }

    const removeFromRemoveAttributes = (index, config) =>{
        if(config==="Group"){
            let newList = indexes_manager_ctx.removeAttributesInGroupChecked;
            newList.splice(newList.indexOf(index),1)
            indexes_manager_ctx.setRemoveAttributesInGroupChecked(newList)
        }
        else if(config==="Single"){
            let newList = indexes_manager_ctx.removeAttributesInFoundChecked;
            newList.splice(newList.indexOf(index),1)
            indexes_manager_ctx.setRemoveAttributesInFoundChecked(newList)
        }
        indexes_manager_ctx.setTimer(indexes_manager_ctx.timer +1);
        indexes_manager_ctx.setIsControlPressed(false)
        setTimeout(()=>{indexes_manager_ctx.setIsControlPressed(true)},5)
    }

    const updateIndexAttributes = (single) =>{
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            language:'pl',
            index: single ? indexes_manager_ctx.isOnlyOneChosen : indexes_manager_ctx.productsInGroup.map(x=>{return x.id}),
            resetAttributes: null,
            addAttributes: single ? indexes_manager_ctx.attributesListInSingleToAdd.map(x=>{return x.id}) : indexes_manager_ctx.attributesListInGroupToAdd.map(x=>{return x.id}),
            removeAttributes: single ? indexes_manager_ctx.removeAttributesInFoundChecked  : indexes_manager_ctx.removeAttributesInGroupChecked
        }
        PostMethod(UPDATE_INDEX_ATTRIBUTES_URL,data,repsonseHandler)
    }

    const repsonseHandler=(response)=>{
        let field = indexes_manager_ctx.operationsField
        indexes_manager_ctx.setOperationsField(0);
        setTimeout(()=>{
            indexes_manager_ctx.setOperationsField(field);
        },1)
    }


    return (
            <Grid container px={2} height={'100%'}>
            <Grid item xs={12} justifyContent={'center'} height={'90%'}>
                {(indexes_manager_ctx.boxGroupField === 2 || indexes_manager_ctx.isOnlyOneChosen) && 
                <>
                    <Grid item xs={12} pt={3} height={'25%'} >
                        <Grid item xs={12} height={'20%'} textAlign={'center'}><Typography fontSize={11}>dodaj atrybuty</Typography></Grid>
                        <Grid container item height={'80%'} sx={{border:'#1f1f1f solid 2px', borderRadius:'10px', display:'inline-block', overflowY:'auto'}} className="scrollerY">
                            { indexes_manager_ctx.operationsField === 3 && Object.keys(indexes_manager_ctx.attributesListInGroupToAdd).map(attribute =>{
                                return (
                                    <Grid item sx={{height:'fit-content', display:'inline-block'}}>
                                        <Paper
                                        key={indexes_manager_ctx.attributesListInGroupToAdd[attribute].id}
                                        sx={{margin:'3px', padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative, display:'flex', flexDirection:'column', width:'fit-content', height:'fit-content', cursor:'pointer'}}
                                        onDoubleClick={()=>removeAttributeToAdd(indexes_manager_ctx.attributesListInGroupToAdd[attribute])}
                                        >
                                            <Typography fontSize={12}>{indexes_manager_ctx.attributesListInGroupToAdd[attribute].assets.name.pl}</Typography>
                                            </Paper>
                                    </Grid>
                                    )
                                })}
                                {indexes_manager_ctx.operationsField === 2 && Object.keys(indexes_manager_ctx.attributesListInSingleToAdd).map(attribute =>{
                                    return (
                                        <Grid item sx={{height:'fit-content', display:'inline-block'}}>
                                        <Paper
                                        key={indexes_manager_ctx.attributesListInSingleToAdd[attribute].id}
                                        sx={{margin:'3px', padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative, display:'flex', flexDirection:'column', width:'fit-content', height:'fit-content', cursor:'pointer'}}
                                        onDoubleClick={()=>removeAttributeToAdd(indexes_manager_ctx.attributesListInSingleToAdd[attribute], 'single')}
                                        >
                                        <Typography fontSize={12}>{indexes_manager_ctx.attributesListInSingleToAdd[attribute].assets.name.pl}</Typography>
                                        
                                        </Paper>
                                        </Grid>
                                        )
                                    })}
                                    </Grid>
                                    </Grid>
                                    <Grid  item xs={12} pt={4} height={'75%'}>
                                    <Grid item xs={12} height={'7%'} textAlign={'center'}><Typography fontSize={11}>usuń atrybuty</Typography></Grid>
                                    <Grid container item height={'93%'} sx={{border:'#1f1f1f solid 2px', borderRadius:'10px', display:'inline-block', overflowY:'auto'}} className="scrollerY">
                                        
                                    { indexes_manager_ctx.operationsField === 3 && Object.keys(indexes_manager_ctx.attributesListInProductsInGroup).map(attribute =>{
                                        return (
                                            <Grid item sx={{height:'fit-content', display:'inline-block'}}>
                                            <Paper
                                            className={`${styles.removeAttribute} ${ indexes_manager_ctx.isControlPressed && styles.pointer}`}
                                            key={attribute}
                                            onClick={()=>{indexes_manager_ctx.isControlPressed && ( indexes_manager_ctx.removeAttributesInGroupChecked.includes(indexes_manager_ctx.attributesListInProductsInGroup[attribute].id) ? removeFromRemoveAttributes(indexes_manager_ctx.attributesListInProductsInGroup[attribute].id, "Group") : setRemoveAttributes(indexes_manager_ctx.attributesListInProductsInGroup[attribute].id, "Group"))}}
                                            sx={indexes_manager_ctx.removeAttributesInGroupChecked.includes(indexes_manager_ctx.attributesListInProductsInGroup[attribute].id) ? {margin:'3px', padding:1, boxShadow:'lightcoral 0 0 0 2px ', backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative} : {margin:'3px', padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative}}
                                            >
                                            <Typography fontSize={12}>{indexes_manager_ctx.attributesListInProductsInGroup[attribute].attribute}</Typography>
                                            <Typography fontSize={10}>{indexes_manager_ctx.attributesListInProductsInGroup[attribute].group_name}</Typography>
                                            
                                        </Paper>
                                    </Grid>
                                )
                            })}
                            { indexes_manager_ctx.operationsField === 2 && Object.keys(indexes_manager_ctx.attributesListInProductsInFound).map(attribute =>{
                                return (
                                    <Grid item sx={{height:'fit-content', display:'inline-block'}}>
                                    <Paper
                                    className={`${styles.removeAttribute} ${ indexes_manager_ctx.isControlPressed && styles.pointer}`}
                                    key={attribute}
                                    onClick={()=>{indexes_manager_ctx.isControlPressed && ( indexes_manager_ctx.removeAttributesInFoundChecked.includes(indexes_manager_ctx.attributesListInProductsInFound[attribute].id) ? removeFromRemoveAttributes(indexes_manager_ctx.attributesListInProductsInFound[attribute].id, "Single") : setRemoveAttributes(indexes_manager_ctx.attributesListInProductsInFound[attribute].id, "Single"))}}
                                    sx={indexes_manager_ctx.removeAttributesInFoundChecked.includes(indexes_manager_ctx.attributesListInProductsInFound[attribute].id) ? {margin:'3px', padding:1, boxShadow:'lightcoral 0 0 0 2px ', backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative} : {margin:'3px', padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative}}
                                    >
                                            <Typography fontSize={12}>{indexes_manager_ctx.attributesListInProductsInFound[attribute].attribute}</Typography>
                                            <Typography fontSize={10}>{indexes_manager_ctx.attributesListInProductsInFound[attribute].group_name}</Typography>
                                            
                                        </Paper>
                                    </Grid>
                                    )
                                })}
                            </Grid>
                    </Grid>
                    </>}
            </Grid>
            <Grid container item xs={12}>
                    {(indexes_manager_ctx.operationsField === 2 ? indexes_manager_ctx.isOnlyOneChosen : true) && <Grid item xs={12}><Button fullWidth variant={"contained"} onClick={indexes_manager_ctx.operationsField === 2 ? ()=>updateIndexAttributes(true) : ()=>updateIndexAttributes(false)}>Zastosuj</Button></Grid>}
                    </Grid>
                    </Grid>
                )
            }
export default IndexesManagerBoxGroupField;