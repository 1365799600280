import { Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {BiSearchAlt} from "react-icons/bi";
import UpdateRowPanel from "./UpdateRowPanel/UpdateRowPanel";
import Calculate from "../../../../AdminPanel/Calculate";
const WarehouseContent =()=>{
    const [searchInfo, setSearchInfo] =  useState({
        gtinSearch:'',
        skuSearch:'',
        nameFotoSearch:'',
    });
    const [updateRowData, setUpdateRowData] = useState();

    const headers = [
        {id:1, label:'Lp', width:5, searchable:false},
        {id:2, label:'GTIN', width:12, searchable:true},
        {id:3, label:'SKU', width:10, searchable:true},
        {id:4, label:'Nazwa + foto na hover', width:38, searchable:true},
        {id:5, label:'MW', width:7, searchable:false},
        {id:6, label:'R', width:7, searchable:false},
        {id:7, label:'MO', width:7, searchable:false},
        {id:8, label:'CZ', width:7, searchable:false},
        {id:9, label:'CS', width:7, searchable:false}
    ];

    const dataSample = [
        {id:1, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:2, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:3, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:4, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:5, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:6, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:7, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:8, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:9, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:10, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:11, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:12, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:13, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:14, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:15, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:16, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:17, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:18, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:19, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:20, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:21, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:22, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:23, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:24, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:25, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:26, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:27, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:28, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:29, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:30, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:31, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:32, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:33, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:34, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
        {id:35, GTIN:'GTIN', SKU:'SKU', NazwaFotoNaHover:'Nazwa + foto na hover', MW:'MW', R:'R', MO:'MO', CZ:'CZ', CS:'CS'},
    ];



    useEffect(()=>{
        const sendSearchData = setTimeout(()=>{
            const data={
                GTIN: searchInfo.gtinSearch === '' ? null : searchInfo.gtinSearch,
                SKU: searchInfo.skuSearch === '' ? null : searchInfo.skuSearch,
                NazwaFotoNaHover: searchInfo.nameFotoSearch === '' ? null : searchInfo.nameFotoSearch,
            };
            if(!updateRowData){
                console.log(data)
            }
        },500);
        return (()=>{clearTimeout(sendSearchData)});
    })

    const updateSearchState = (label, value) =>{
        switch (label) {
            case 'GTIN': setSearchInfo({gtinSearch:value, skuSearch:'', nameFotoSearch:''}); break;
            case 'SKU': setSearchInfo({gtinSearch:'', skuSearch:value, nameFotoSearch:''}); break;
            case 'Nazwa + foto na hover': setSearchInfo({gtinSearch:'', skuSearch:'', nameFotoSearch:value}); break;
        }
    }
    const getValueForSearchInput = (label) => {
        switch (label) {
            case 'GTIN': return searchInfo.gtinSearch;
            case 'SKU': return searchInfo.skuSearch;
            case 'Nazwa + foto na hover': return searchInfo.nameFotoSearch;
        }
    }

    return(
        <>
        {updateRowData ? <UpdateRowPanel data={updateRowData} closePanel={()=>{setUpdateRowData(null); setTimeout(()=>{Calculate()}, 5)}}/>:
        <Grid container id='WarehouseContainer' sx={{overflow:'auto', height:'100%', width:'100%'}} className={'scrollerY scrollerX'}>
            <Grid container item xs={12} id='WarehouseHeadersContainer' zIndex={2} sx={{backgroundColor:'#1f1f1f', width:'max-content', position:'fixed'}} >
                {headers.map(header => {return <div key={header.id} style={{width:`${header.width}%`, textAlign:'center', boxShadow:'gray 1px 1px 0 0', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    {header.searchable ? 
                    <TextField fullWidth sx={{input:{color:'silver', height:'25px', padding:0, fontSize:13, '&::placeholder': {color: 'silver', opacity:100, fontSize:13}}}} placeholder={header.label}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <BiSearchAlt color="silver"/>
                          </InputAdornment>
                        ),
                    }}
                    onChange={(e)=>updateSearchState(header.label, e.target.value)}
                    value={getValueForSearchInput(header.label)}
                    />
                    : <Typography fontSize={13}>{header.label}</Typography>}
                </div>})}
            </Grid>
            <Grid container item mt={'25px'}>
                {dataSample.map((sample, index)=>{
                    return (
                        <Grid container item xs={12} key={sample.id} sx={{"&:hover": {backgroundColor: '#1f1f1f', cursor:'pointer'}}} onClick={()=>setUpdateRowData(sample)}>
                            <div style={{width:'5%', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}>{index + 1}</Typography></div>
                            <div style={{width:'12%', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}>{sample.GTIN}</Typography></div>
                            <div style={{width:'10%', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}>{sample.SKU}</Typography></div>
                            <div style={{width:'38%', display:'flex', alignItems:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12} pl={1}>{sample.NazwaFotoNaHover}</Typography></div>
                            <div style={{width:'7%', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}>{sample.MW}</Typography></div>
                            <div style={{width:'7%', textAlign:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}><Button sx={{height:'19px', padding:0, minWidth:0, marginY:'3px'}} variant={'outlined'} onClick={(e)=>{e.stopPropagation()}} fullWidth>{sample.R}</Button></Typography></div>
                            <div style={{width:'7%', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12} pl={'5px'}>{sample.MO}</Typography></div>
                            <div style={{width:'7%', textAlign:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}>{sample.CZ}</Typography></div>
                            <div style={{width:'7%', textAlign:'center', boxShadow:'gray 1px 1px 0 0'}}><Typography fontSize={12}>{sample.CS}</Typography></div>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
        }
        </>
    )
}
export default WarehouseContent;