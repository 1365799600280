import { useContext } from 'react';
import { Grid, TextField, Typography} from '@mui/material';
import AdminPanelContext from '../../../../../../Context/AdminPanelContext';
import styles from './ImportIndexesList.module.css'
import {GET_IMPORTED_INDEXES, GET_PROJECTS } from '../../../../../../Constants';
import PostMethod from '../../../../../FetchMethods/PostMethod';
import { useEffect } from 'react';
import { useState } from 'react';
import ImportIndexesListTable from './ImportIndexesListTable/ImportIndexesListTable';
import {BsFillArrowRightCircleFill,BsFillArrowLeftCircleFill} from "react-icons/bs";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const ImportIndexesList = () =>{
  const admin_panel_ctx = useContext(AdminPanelContext); 
  const [tableRows, setTableRows] = useState([]);
  const [status, setStatus] = useState('new');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [stats, setStats] = useState();
  const [searching, setSearching] = useState();
  const [loading, setLoading] = useState();
  const [allProjects, setAllProjects] = useState(null);
  const [selectProjectValue, setSelectProjectValue] = useState('all');

  const columns = [
    {label:'Media', width:2, anchor:'center'}, 
    {label:'Nazwa', width:5, anchor:'left'},
    {label:'Stan magazynowy', width:2, anchor:'center'},
    {label:'Cena', width:2, anchor:'center'},
  ]

  function createData(id,base_index_id, photos, name, sku, gtin, stock, price, supplierSourceName, status, product_import) {
    return {
      id,
      base_index_id,
      photos,
      name,
      sku,
      gtin,
      stock,
      price,
      supplierSourceName,
      status,
      product_import
    };
  }
  
  const GetData=(searchPhrase=null)=>{
    setLoading(true)
      const data={
          db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
          page:page,
          'search-phrase':searchPhrase,
          status:status,
          limit:limit,
          'project-id':selectProjectValue==='all' ? null : selectProjectValue,
      }
      PostMethod(GET_IMPORTED_INDEXES, data, getResponse)
  }

const getResponse=(data)=>{
  if (data.data){
    const rows = []
    setStats(data.stats)
    for(var obj in data.data){
      let object = data.data[obj]
      rows.push(createData(object.id,object.base_index_id, object.basics.photos, object.basics.name, object.sku, object.basics.gtin, object.basics.stock.volume, JSON.stringify(object.basics.price), object.supplier_source_name, object.status, JSON.stringify(object.import)));
    }
    setTableRows(rows);
  }else{
    setTableRows([])
    setStats(data.stats)
  }
  setLoading(false)
}

 const SetPage =(number)=>{
  if(number && !loading){
    setPage(number)
  }
 }

  useEffect(()=>{
    if(searching){
      GetData(searching.trim());
    }
    else{
      GetData();
    }
  },[status,page,limit, selectProjectValue])


  useEffect(()=>{
        
    const searchingTimeout=setTimeout(()=>{
        if(searching && searching.trim()!==''){
            GetData(searching.trim());
        }
        else{
          GetData();
        }
      },500)
    return ()=>{
        clearTimeout(searchingTimeout);
    }
},[searching])



    const handleSetPage =(e)=>{
      const newPage = parseInt(e.target.value)
      if(newPage>0 && newPage<stats.pagination.max+1){
        setPage(newPage)
      }
    }

    useEffect(()=>{
      const data ={
        db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
      }
      PostMethod(GET_PROJECTS, data, getProjectResponse)
    },[])

  const getProjectResponse =(data)=>{
      const allProjectsToAdd = []
      if(data.data){
        Object.keys(data.data).map(i=>{
          allProjectsToAdd.push({id:data.data[i].id, name:data.data[i].settings.name.pl});
        })
      }
      setAllProjects(allProjectsToAdd)
  }


    return(
        <>
            <Grid container mb={2} id={'import_indexes_list_table_panel'} className={`${styles.import_indexes_list_container_top} zoomOnShow`} sx={{backgroundColor:admin_panel_ctx.cSheet.default}} height={'100px'}>
                    <Grid container item xs={12} p={1}>
                        <Grid item xs={10} textAlign={'left'}>
                          {allProjects && allProjects.length>1 &&
                              <FormControl sx={{ m: 2, minWidth: 150 }}>
                                <Select
                                  labelId="demo-simple-select-autowidth-label"
                                  id="demo-simple-select-autowidth2"
                                  value={selectProjectValue}
                                  onChange={(e)=>{setSelectProjectValue(e.target.value); setPage(1)}}
                                  autoWidth={true}
                                  sx={{color:'white'}}
                                  >
                                  <MenuItem value={"all"}>Wszystkie projekty</MenuItem>
                                    {allProjects && allProjects.map(project=>{
                                      return <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                                    })}
                                </Select>
                              </FormControl>
                          }
                              
                              <TextField sx={{ m: 2, minWidth: 150, input:{color:'white'}, placeholder:{color:'white'}}} onChange={(e)=>{setSearching(e.target.value); setPage(1)}} placeholder={'Wyszukaj...'}/>

                              <FormControl sx={{ m: 2, minWidth: 150 }}>
                                <Select
                                  labelId="demo-simple-select-autowidth-label"
                                  id="demo-simple-select-autowidth"
                                  value={status}
                                  onChange={(e)=>{setStatus(e.target.value); setPage(1)}}
                                  autoWidth={true}
                                  sx={{color:'white'}}
                                >
                                  <MenuItem value={'all'}>Wszystkie indexy</MenuItem>
                                  <MenuItem value={'new'}>Nowe indexy</MenuItem>
                                  <MenuItem value={'accepted'}>Sklep</MenuItem>
                                  <MenuItem value={'archive'}>Archiwum</MenuItem>
                                </Select>
                              </FormControl>
                        </Grid>
                        <Grid container item xs={2}>
                          <Grid container item xs={12} textAlign={'right'} justifyContent={'center'} alignItems={'center'} sx={{height:'fit-content'}}>
                            <BsFillArrowLeftCircleFill className={(stats && stats.total !==0)? ((page === 1 || loading) ? styles.arrowDiseabled : styles.arrow): styles.arrowDiseabled} size={20} onClick={()=>{page===1?SetPage(null):SetPage(page-1)}}/>
                              <input className={styles.pageInput} variant='standard' value={page} onChange={handleSetPage} onClick={(e)=>e.target.select()}/>
                            <BsFillArrowRightCircleFill className={(stats && stats.total !==0)? ((page === stats.pagination.max || loading) ? styles.arrowDiseabled : styles.arrow): styles.arrowDiseabled} size={20} onClick={()=>{stats.pagination.next ? SetPage(page+1): SetPage(null)}}/>
                          </Grid>
                          <Grid item xs={12} textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                            <Typography fontSize={13}>{stats ? `od ${page===1? 1 : (1+((page-1)*limit))} do ${page*limit> stats.total ? stats.total :page*limit} / ${stats.total}` : 'brak danych'}</Typography>
                          </Grid>
                        </Grid>
                    </Grid>
            </Grid>
            <Grid container p={2} className="zoomOnShow">
                <Grid container item xs={12} mt={'100px'}>
                    {tableRows.length!==0 && 
                        <ImportIndexesListTable 
                        columns={columns}
                        rows={tableRows} 
                        stats={stats} 
                        status={status} 
                        loading={loading}
                        setLoading={setLoading} 
                        reload={()=>GetData(searching? searching.trim():null)}/>
                    }
                    {tableRows.length === 0 && <Grid item xs={12}><Typography textAlign={'center'} fontSize={25}>Brak wyników</Typography></Grid>}
                </Grid>
            </Grid>
        </>
    )
}

export default ImportIndexesList