import { Grid } from "@mui/material";
import {RiCloseFill} from 'react-icons/ri';
import styles from "./UpdateRowPanel.module.css";

const UpdateRowPanel = props =>{
    return(
        <Grid container sx={{overflow:'auto', height:'100%', width:'100%', backgroundColor:'white'}} className={'scrollerY scrollerX zoomOnShow'}>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}><RiCloseFill className={styles.close_icon} size={30} onClick={()=>props.closePanel()}/></Grid>
        </Grid>
    );
}
export default UpdateRowPanel;