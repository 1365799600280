import { LoadingButton } from "@mui/lab";
import {AiFillWarning} from "react-icons/ai";
import {BsFillCheckCircleFill} from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { Grid, IconButton, InputBase, Paper, Switch, Typography } from "@mui/material";
import AdminPanelContext from '../../../../../../../../../../Context/AdminPanelContext';
const AddNewElementForm =(props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext)
    const [name, setName] = useState();
    const [uri, setUri] = useState();
    const [isUriValid, setIsValidUri] = useState(false);
    const [loading, setLoading] = useState(false);
    const [asChild, setAsChild] = useState();
    const [isUriModified, setIsUriModified] = useState(false);


    const checkIfUriValid =()=>{
        if(uri)setIsValidUri(true)
    }

    useEffect(()=>{
        checkIfUriValid();
    },[])

    useEffect(()=>{
        const uriTimeout = setTimeout(()=>{
            if(uri){
                checkIfUriValid()
            }
            else setIsValidUri(false)
        }, 500)
        return ()=> clearTimeout(uriTimeout);
    },[uri])

    useEffect(()=>{
        const nameTimeout = setTimeout(()=>{
            if(name){console.log(name)}
            setUri(name);
            setIsUriModified(false)
        }, 500)
        return ()=> clearTimeout(nameTimeout);
    },[name])

    const checkIfFormIsValid =()=>{
        return (name && name.trim().length > 0) && isUriValid;
    }

    const submitForm = ()=>{
        setLoading(true)
        setTimeout(()=>{setLoading(false); props.closeModal()}, 1000)
    }


    return (
        <Grid container>
            <Grid container item>
                <Grid container item xs={12} alignItems={'center'} justifyContent={'center'} mb={5}><Typography fontSize={25}>Dodaj nową kategorię</Typography></Grid>
                <Grid container item xs={12} p={3}>

                    <Grid container item xs={12} mb={3} alignItems={'center'} justifyContent={'center'}>
                        <Typography fontSize={20} mr={2}>Uri modified: {isUriModified?"true":'false'}</Typography>
                    </Grid>

                    <Grid container item xs={12} mb={3} alignItems={'center'} justifyContent={'center'}>
                        <Typography fontSize={20} mr={2}>Utwórz jako kategorię główną:</Typography>
                        <Switch onChange={(e)=>{setAsChild(e.target.checked)}} defaultChecked={props.selected ? false : true} disabled={props.selected ? false : true}/>
                    </Grid>
                    {props.selected && !asChild && 
                        <Grid container item xs={12} mb={3} alignItems={'center'} justifyContent={'center'}>
                            <Typography fontSize={20} mr={2}>Utwórz jako podkategorię dla "{props.selected.label}"</Typography>
                        </Grid>
                    }
                        <Grid container item xs={12} mb={3} alignItems={'center'}>
                            <Grid item xs={6} textAlign={'center'}>
                                <Typography fontSize={20} mr={2}>Nazwa Kategorii:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', marginRight:3, backgroundColor:(name && name.trim().length > 0) ? admin_panel_ctx.cPanel.default : '#F9CB9C'}} >
                                    <InputBase
                                        sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }}
                                        value={name} 
                                        onChange={(e)=>setName(e.target.value)}
                                        fullWidth
                                        />
                                    <Typography fontSize={15} p={1} color={'gray'}>{name ? name.trim().length: 0}</Typography>
                                    {(name && name.trim().length > 0) ?
                                        <IconButton style={{padding:0}}><BsFillCheckCircleFill color="green" size={30} margin={0}/></IconButton>
                                        :
                                        <IconButton style={{padding:0}}><AiFillWarning color="darkOrange" size={30} margin={0}/></IconButton>
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems={'center'} justifyContent={'left'}>
                            <Grid item xs={6} textAlign={'center'}>
                                <Typography fontSize={20} mr={2}>Uri:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', marginRight:3, backgroundColor: isUriValid ? admin_panel_ctx.cPanel.default : '#F9CB9C'}}>
                                    <InputBase
                                        sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }}
                                        value={uri} 
                                        onChange={(e)=>{setUri(e.target.value); setIsUriModified(true)}}
                                        fullWidth
                                        />
                                    <Typography fontSize={15} p={1} color={'gray'}>{uri ? uri.trim().length: 0}</Typography>
                                    {isUriValid ?
                                        <IconButton style={{padding:0}}><BsFillCheckCircleFill color="green" size={30} margin={0}/></IconButton>
                                        :
                                        <IconButton style={{padding:0}}><AiFillWarning color="darkOrange" size={30} margin={0}/></IconButton>
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    <Grid container item xs={12} m={1} alignItems={'center'} justifyContent={'flex-end'}>
                        <LoadingButton onClick={submitForm} loading={loading} disabled={!checkIfFormIsValid()} variant="contained">Dodaj</LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default AddNewElementForm;