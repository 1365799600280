import { Grid, Paper } from "@mui/material";
import {AiOutlineLoading} from "react-icons/ai";
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import styles from "./ProductAttributes.module.css";
import { useContext, useState, useEffect } from "react";
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PostMethod from "../../../../../../../../FetchMethods/PostMethod"
import AdminPanelContext from "../../../../../../../../../Context/AdminPanelContext";
import {GET_BULKS_URL, GET_ATTRIBUTES_GROUP_URL, GET_INDEX_ATTRIBUTES_URL, UPDATE_INDEX_ATTRIBUTES_URL} from "../../../../../../../../../Constants"
import ProductAttributesGroup from "./ProductAttributesGroup/ProductAttributesGroup";
import ProductEditContext from "../../../../../../../../../Context/ProductEditContext";

const ProductAttributes =()=>{
    const [bulks, setBulks] = useState();
    const [groups, setGroups] = useState();
    const [expanded, setExpanded] = useState();
    const admin_panel_ctx = useContext(AdminPanelContext)
    const product_edit_context = useContext(ProductEditContext)

    useEffect(()=>{
        GetBulks();
        GetProductAttributes();
    },[])

    useEffect(()=>{
        if(product_edit_context.refreshCurrentAttributesField){
            GetProductAttributes();
        }
        product_edit_context.setRefreshCurrentAttributesField(false)
    },[product_edit_context.refreshCurrentAttributesField])

    useEffect(()=>{
        let bulksArray = [];
        Object.keys(bulks?bulks:'').map((grp, i)=>{
            bulksArray.push(bulks[grp]);
        })
        if (bulksArray.length !== 0 && expanded !==false) getGroups(bulksArray[expanded].id)
    },[expanded])

    const GetBulks=()=>{
        const data  ={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database
        }
        PostMethod(GET_BULKS_URL, data, setBulks)
    }

    const GetProductAttributes = ()=>{
        const data ={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            index:JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.config.id === product_edit_context.product.config.id).config.id
        }   
        PostMethod(GET_INDEX_ATTRIBUTES_URL, data, responseHandler)
    }
    
    const responseHandler =(data)=>{
        console.log(data.data)
        product_edit_context.setProductAttributes(data.data)
    }

    const getGroups=(bulkId)=>{
        const data  ={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            bulkId:bulkId
        }
        PostMethod(GET_ATTRIBUTES_GROUP_URL, data, setAttributesGroups)
    }

    const setAttributesGroups=(data)=>{
        setGroups(data);
        if(JSON.stringify(data) === '{}') setGroups(null)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    const removeAttribute =(attributeId)=>{
        const data = {
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            language:'pl',
            index: JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.config.id === product_edit_context.product.config.id).config.id,
            resetAttributes: null,
            addAttributes: null,
            removeAttributes: [attributeId]
        }
        PostMethod(UPDATE_INDEX_ATTRIBUTES_URL,data,removeAttributeHandler)
    }
    const removeAttributeHandler =(data)=>{
        GetProductAttributes()
    }


    return(
        <Grid container>
            <Grid container item m={1}>
                <Grid item xs={5}>
                    {bulks && Object.keys(bulks).map((bulk, i)=>{
                        return(
                            <MuiAccordion key={i} disableGutters elevation={0} expanded={expanded === i} onChange={handleChange(i)} sx={{animationDelay:`${40+i*50}ms`}}  className={styles.accordion}>
                            <MuiAccordionSummary sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative, boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <Grid container item xs={12}>
                                    <Typography fontSize={13} color={admin_panel_ctx.cText.negative} mt={1}>{bulks[bulk].assets.name.pl}</Typography>
                                </Grid>
                            </MuiAccordionSummary>
                            <MuiAccordionDetails className={styles.accrdeon_details} sx={ expanded===i ? {backgroundColor:admin_panel_ctx.cElement.negative , boxShadow:"#131515 0 0 5px 1px"} : {backgroundColor:admin_panel_ctx.cElement.default}}>
                                <div className={`${styles.accrdeon_details_scroller} scrollerY`}>
                                    <div style={{backgroundColor:admin_panel_ctx.cSheet.default}}>
                                        <ProductAttributesGroup data={groups ? groups : null} />
                                    </div>
                                </div>
                            </MuiAccordionDetails>
                        </MuiAccordion>)
                    })}
                    {!bulks && <Grid item xs={5} ml={'50%'} mt={10}><AiOutlineLoading size={40} className="loading" color="#1976D2"/></Grid>}
                </Grid>
                <Grid container item xs={7}>
                    <Grid container item xs ={12} sx={{border:'black solid 1px', display:'inline-block'}} ml={1}>
                        {product_edit_context.productAttributes && product_edit_context.productAttributes.map(attribute=>{
                            return (
                                <Paper
                                    key={attribute.id}
                                    className={styles.attribute_object}
                                    sx={{margin:1, padding:1, backgroundColor:admin_panel_ctx.color1, color:admin_panel_ctx.cText.negative, display:'inline-block'}}
                                    onDoubleClick={()=>removeAttribute(attribute.id)}
                                    >{attribute.attribute}
                                </Paper>
                            )
                        }) }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default ProductAttributes;