import "./RightAdminPanel.css"
import React,{useContext} from "react";
import AdminPanelContext from "../../../Context/AdminPanelContext";
import {BsPinAngle, BsPinAngleFill} from 'react-icons/bs';
import '../SidePanelsAnimations.css';
import { useEffect } from "react";
import Calculate from "../Calculate";
import Configurate from "../../Modules/BaseModules/Configurate/Configurate";
import PIM from "../../Modules/BaseModules/PIM/PIM";
const RightAdminPanel = () =>{

    const admin_panel_ctx = useContext(AdminPanelContext);

    const getClassName = direction =>{
        if(direction === 'up'){
            if(admin_panel_ctx.isRightUpOpen && !admin_panel_ctx.isRightDownOpen) return "fully_opened";
            else if (admin_panel_ctx.isRightUpOpen && admin_panel_ctx.isRightDownOpen) return "half_opened";
            else return "closed";
        }
        else{
            if(!admin_panel_ctx.isRightUpOpen && admin_panel_ctx.isRightDownOpen) return "fully_opened";
            else if (admin_panel_ctx.isRightUpOpen && admin_panel_ctx.isRightDownOpen) return "half_opened";
            else return "closed";
        }
    }

    useEffect(()=>{
        Calculate();
    }, [admin_panel_ctx.rightPinActive])
    
    useEffect(()=>{
        localStorage.setItem('rightPin','1')
    },[])

    let leaveRightTimeout; 
    
    const LeaveRightTimeout = (e) =>{
        leaveRightTimeout = setTimeout(()=>LeaveActions(e), 500);
    }

    const LeaveActions = (e) =>{
        const wrapperRight = (e.relatedTarget && e.relatedTarget.closest('#wrapperRight'))

        if(!admin_panel_ctx.rightPinActive && !wrapperRight){
            admin_panel_ctx.setIsRightUpOpen(false);
            admin_panel_ctx.setIsRightDownOpen(false);
        }
    }

    return(
        <div className={`right_admin_panel ${(admin_panel_ctx.isRightUpOpen || admin_panel_ctx.isRightDownOpen) && 'right_open'}`} style={{backgroundColor:admin_panel_ctx.color1}} onMouseLeave={(e)=>LeaveRightTimeout(e)} onMouseEnter={()=>clearTimeout(leaveRightTimeout)}>
            <div className={`right_up_panel ${getClassName('up')}`}>
                <div className={`right_up_panel_top`}>
                    {admin_panel_ctx.rightPinActive ? <BsPinAngleFill className={'right_pin_active'} onClick={()=>admin_panel_ctx.setRightPinActive(false)}/> : <BsPinAngle className={'right_pin'} onClick={()=>admin_panel_ctx.setRightPinActive(true)}/>}
                </div>
                {admin_panel_ctx.isRightUpOpen === 2 && <PIM/>}
            </div>
            <div className={`right_down_panel ${getClassName('down')}`}>
                <div className={`right_down_panel_top`}>
                    { !admin_panel_ctx.isRightUpOpen &&  (admin_panel_ctx.rightPinActive ? <BsPinAngleFill className={'right_pin_active'} onClick={()=>admin_panel_ctx.setRightPinActive(false)}/> : <BsPinAngle className={'right_pin'} onClick={()=>admin_panel_ctx.setRightPinActive(true)}/>) }
                </div>
                <div className="modules_main_container scrollerY">
                    {admin_panel_ctx.isRightDownOpen === 2 && <Configurate/>}
                </div>
            </div>
        </div>
    )
}

export default RightAdminPanel;