import { Grid } from "@mui/material";
import DefaultProductEdit from "./DefaultProductEdit/DefaultProductEdit";
import ProductEditContext from "../../../../../../Context/ProductEditContext";
import { useState } from "react";

const IndexListProductEdit = props =>{
    const [selectedOperationField, setSelectedOperationField] = useState(1);
    const [metadata, setMetadata] = useState({url:null, description:null, robots:null});
    const [basicInfo, setBasicInfo] = useState({url:null, name:null});
    const [bulletPoints, setBulletPoints] = useState({points:null});
    const [checkModalOpen, setCheckModalOpen] = useState(false);
    const [productAttributes, setProductAttributes] = useState([]);
    const [refreshCurrentAttributesField, setRefreshCurrentAttributesField] = useState(false)
    const [refreshAddAttributesField, setRefreshAddAttributesField] = useState(false)

    return (
        <ProductEditContext.Provider
        value={{
            product:props.object,
            selectedOperationField,
            metadata,
            basicInfo,
            bulletPoints,
            checkModalOpen,
            productAttributes,
            refreshCurrentAttributesField,
            refreshAddAttributesField,
            setSelectedOperationField,
            setMetadata,
            setBasicInfo,
            setBulletPoints,
            setCheckModalOpen,
            setProductAttributes,
            setRefreshCurrentAttributesField,
            setRefreshAddAttributesField
        }}
        >
            <Grid container className="zoomOnShow">
                <DefaultProductEdit/>
            </Grid>
        </ProductEditContext.Provider>
    )
}
export default IndexListProductEdit;