import Menu from "./CategoriesList/Menu";
import { useContext, useEffect, useState } from "react";
import useFetch from "../../../../../../../Hooks/use-fetch";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import CategoryContext from "../../../../../../../Context/CategoryContext";
import {GET_CATEGORY_LIST, GET_PROJECTS} from "../../../../../../../Constants";
import CategoryConfiguration from "./CategoryConfiguration/CategoryConfiguration";

const CategoryFields =()=>{

    const [projects, setProjects] = useState();
    const [selectedProject, setSelectedProject] = useState('1');




    const {loading:projectsLoading, error:error, sendRequest:getProjects} = useFetch();

      useEffect(()=>{

        const getProjectResponse =(data)=>{
          const allProjectsToAdd = []
          if(data.data){
            Object.keys(data.data).map(i=>{
              allProjectsToAdd.push({id:data.data[i].id, name:data.data[i].settings.name.pl});
            })
          }
          setProjects(allProjectsToAdd)
        }

        getProjects(
          {url:GET_PROJECTS, 
           method:'POST',
           body:{db:JSON.parse(localStorage.getItem("currentOrganization")).config.database}},
           getProjectResponse);
      },[getProjects])

      
      const {loading:categoriesLoading, error:categoriesError, sendRequest:getCategories} = useFetch();
  
        useEffect(()=>{

          const getCategoriesResponse =(data)=>{
            let dataToInsert = [] 
            if(data.data){
              Object.keys(data.data).map(category=>{
                let id = data.data[category].id;
                let label = data.data[category].settings.seo.pl.shortName;
                let children = {id:99*(id+1), label:'brak'}
                dataToInsert.push({id:id, label:label, children:data.data[category].children ? [children] : []})
              })
              category_context.setMainCategories(dataToInsert)
            }
            else{
              category_context.setMainCategories(null)
            }
        }

          getCategories(
            {url:GET_CATEGORY_LIST, 
            method:'POST',
            body:{
              db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
              'project-id':parseInt(selectedProject)
            }},
            getCategoriesResponse);
        },[selectedProject,getCategories])

    const category_context = useContext(CategoryContext)
    const FieldsToRender = [{fieldId:1, name:"Categories List", component:<Menu selectedProject={selectedProject} loading={categoriesLoading || projectsLoading}/>}, {fieldId:2, name:"Category Configuration", component:<CategoryConfiguration/>}]


    return (
        <Grid id={'CategoryFields'} sx={{backgroundColor:'gray'}}>
                <Grid container item xs={12} sx={{height:'15%', backgroundColor:"#4C4F51"}} px={3} alignItems={'center'}>
                    {category_context.currentField === 1 &&   projects && projects.length>1 &&
                      <FormControl sx={{ m: 2, minWidth: 150 }}>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth3"
                          defaultValue={'1'}
                          value={selectedProject}
                          onChange={(e)=>{setSelectedProject(e.target.value)}}
                          autoWidth={true}
                          sx={{color:'white'}}
                          >
                            {projects && projects.map(project=>{
                              return <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                            })}
                        </Select>
                      </FormControl>
                    }
                </Grid>
                <Grid container item xs={12} className='scrollerY' sx={{overflowY:'auto', height:'85%'}}>
                    {FieldsToRender.find(x=>x.fieldId === category_context.currentField).component}
                </Grid>
            </Grid>
    )
}
export default CategoryFields;