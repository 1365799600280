import {FormControl, Grid, IconButton, InputBase, NativeSelect, Paper, TextareaAutosize, Typography } from "@mui/material";
import { useContext, useState } from "react";
import AdminPanelContext from "../../../../../../../../../Context/AdminPanelContext";
import ProductEditContext from "../../../../../../../../../Context/ProductEditContext";
import { useEffect } from "react";
import styles from "./Metadata.module.css"
import {BsFillCheckCircleFill} from "react-icons/bs"
import { GET_PRODUCT_EDIT_DESCRIPTION, GET_PRODUCT_EDIT_ROBOTS, GET_PRODUCT_EDIT_URI, SET_PRODUCT_EDIT_DESCRIPTION, SET_PRODUCT_EDIT_ROBOTS } from "../../../../../../../../../Constants";
import PostMethod from "../../../../../../../../FetchMethods/PostMethod";
import CheckModal from "../CheckModal/CheckModal";
import {AiFillWarning} from "react-icons/ai"
import { CheckBox } from "@mui/icons-material";
import {AiOutlineLoading} from "react-icons/ai"

const Metadata = ()=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const product_edit_context = useContext(ProductEditContext);
    const [openModal, setOpenModal] = useState();
    const [duplicates, setDuplicates] = useState();
    const [loading, setLoading] = useState(false);
    
    const uriSetterHandler = (data) =>{
            product_edit_context.setMetadata((prev) =>{return {...prev, url:data.data.index.uri}})
    }
    const descriptionSetterHandler = (data) =>{
            product_edit_context.setMetadata((prev) =>{return {...prev, description:data.data.description.pl}})
    }
    const robotsSetterHandler = (data) =>{
        console.log(data.data.robots)
            product_edit_context.setMetadata((prev) =>{return {...prev, robots:data.data.robots}})
    }

    useEffect(()=>{
        const data ={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            index:product_edit_context.product.config.id,
            language:'pl',
            'ref-language':'pl'
        }
        PostMethod(GET_PRODUCT_EDIT_URI, data, uriSetterHandler)
        PostMethod(GET_PRODUCT_EDIT_DESCRIPTION, data, descriptionSetterHandler)
        PostMethod(GET_PRODUCT_EDIT_ROBOTS, data, robotsSetterHandler)
    },[localStorage.getItem("ModulesBar")])


    useEffect(()=>{
        if(admin_panel_ctx.reloadProductsEditUri){
            const data={
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                index:product_edit_context.product.config.id,
                uri:product_edit_context.metadata.url.replace("'", '&amp;'),
                language:'pl',
                'ref-language':'pl'
            }
            PostMethod(GET_PRODUCT_EDIT_URI, data, responseUrlHandler);
            admin_panel_ctx.setReloadProductsEditUri(false)
            setLoading(false)
        }
    },[admin_panel_ctx.reloadProductsEditUri])

    useEffect(()=>{
        if(product_edit_context.metadata.url){
            let editingUri = setTimeout(()=>{
                const data={
                    db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                    index:product_edit_context.product.config.id,
                    uri:product_edit_context.metadata.url.replace("'", '&amp;'),
                    language:'pl',
                    'ref-language':'pl'
                }
                setLoading(true)
                PostMethod(GET_PRODUCT_EDIT_URI, data, responseUrlHandler);
                admin_panel_ctx.setReloadProductsEditUri(true)
            },300)
            return ()=>{
                clearTimeout(editingUri)
            }
        }
    },[product_edit_context.metadata.url])

    useEffect(()=>{
        if(product_edit_context.metadata.description){
            let editingDescription = setTimeout(()=>{
                const data={
                    db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                    index:product_edit_context.product.config.id,
                    description:product_edit_context.metadata.description.replace("'", '&amp;'),
                }
                PostMethod(SET_PRODUCT_EDIT_DESCRIPTION, data, responseHandler);
            },500)
            return ()=>{
                clearTimeout(editingDescription)
            }
        }
    },[product_edit_context.metadata.description])

    useEffect(()=>{
        if(product_edit_context.metadata.robots){
            const data={
                db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
                index:product_edit_context.product.config.id,
                robots:product_edit_context.metadata.robots !== 'brak dyrektywy' ? product_edit_context.metadata.robots.replace("'", '&amp;') : null,
            }
            PostMethod(SET_PRODUCT_EDIT_ROBOTS, data, responseHandler);
        }
    },[product_edit_context.metadata.robots])


    const responseHandler =(data)=>{
        console.log(data)
    }
    const responseUrlHandler =(data)=>{
        if(data.data.duplicates){
            setDuplicates(data.data.duplicates)
        }else{
            setDuplicates(null)
        }
    }

    return (
        <>
        <Grid container p={5}>
            <Grid container item xs={8}>
                    <Grid container item xs={3} justifyContent={'right'} alignItems={'center'} pr={2}><Typography textAlign={'right'}>robots</Typography></Grid>
                    <Grid container item xs={9}>
                        <FormControl sx={{minWidth: 80}} fullWidth>
                            <NativeSelect
                            className={styles.select}
                            value={product_edit_context.metadata.robots}
                            onChange={(e)=>{product_edit_context.setMetadata((prev) =>{return {...prev, robots:e.target.value}})}}
                            defaultValue={product_edit_context.metadata.robots}
                            sx={{color:'black'}}
                            >
                                <option value={null}>brak dyrektywy</option>
                                <option value={'index, follow'}>index / follow</option>
                                <option value={'noindex, nofollow'}>noindex / nofollow</option>
                                <option value={'index, nofollow'}>index / nofollow</option>
                                <option value={'noindex, follow'}>noindex / follow</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>

                <Grid container item xs={12} alignItems={'center'} mt={5}>
                    <Grid item xs={3}><Typography mr={2} textAlign={'right'}>Url: </Typography></Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:(duplicates && (duplicates.length > 0)) ? '#F9CB9C' :admin_panel_ctx.cPanel.default}}>
                            <InputBase
                                sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }}
                                placeholder={"Url"}
                                value={product_edit_context.metadata.url}
                                onChange={(e)=>{product_edit_context.setMetadata((prev) =>{return {...prev, url:e.target.value}})}}
                                fullWidth
                                />
                            {loading ? <AiOutlineLoading size={20} className="loading"/> 
                            :
                            (duplicates && duplicates.length > 0) ? 
                            <IconButton style={{padding:0}}><AiFillWarning color="darkOrange" size={25} margin={0} onClick={()=>setOpenModal(true)}/></IconButton>
                            :
                            <IconButton style={{padding:0}}><BsFillCheckCircleFill color="green" size={25} margin={0}/></IconButton>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems={'center'} mt={1}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}><Typography mr={2} textAlign={'right'}></Typography></Grid>
                </Grid>
                <Grid container item xs={12} alignItems={'center'} mt={5} mr={3}>
                    <Grid item xs={3}><Typography mr={2} textAlign={'right'}>META Description: </Typography></Grid>
                    <Grid item xs={9} position={'relative'}>
                        <TextareaAutosize minRows={10} style={{backgroundColor:admin_panel_ctx.cPanel.default, width:'100%', padding:'10px'}} value={product_edit_context.metadata.description} onChange={(e)=>{product_edit_context.setMetadata((prev) =>{return {...prev, description:e.target.value}})}}/>
                        <Typography fontSize={15} color={'gray'} position={'absolute'} bottom={15} right={0}>{product_edit_context.metadata.description ? product_edit_context.metadata.description.trim().length : 0}</Typography>
                    </Grid>
                </Grid>
                {/* OG SECTION */}
                <Grid container item xs={12} mt={5}><Typography fontSize={25} textAlign={'center'}>Og (W trakcie przygotowania)</Typography></Grid>

                <Grid container item xs={12} alignItems={'center'} mt={5}>
                    <Grid item xs={3}><Typography mr={2} textAlign={'right'}>Og:title : </Typography></Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:admin_panel_ctx.cPanel.default}}>
                            <InputBase sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }} placeholder="Og:title"/>
                        </Paper>
                    </Grid>
                    <Grid container item xs={3}></Grid>
                    <Grid container item alignItems={'center'} xs={9} mt={1}>
                        <CheckBox/>
                        <Typography fontSize={15} ml={1}>Użyj tytułu</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems={'center'} mt={5}>
                    <Grid item xs={3}><Typography mr={2} textAlign={'right'}>Og:description : </Typography></Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:admin_panel_ctx.cPanel.default}}>
                            <InputBase sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }} placeholder="Og:description"/>
                        </Paper>
                    </Grid>
                    <Grid container item xs={3}></Grid>
                    <Grid container item alignItems={'center'} xs={9} mt={1}>
                        <CheckBox/>
                        <Typography fontSize={15} ml={1}>Użyj meta description</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems={'center'} mt={5}>
                    <Grid item xs={3}><Typography mr={2} textAlign={'right'}>Og:image : </Typography></Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor:admin_panel_ctx.cPanel.default}}>
                            <InputBase sx={{ mt:'2px', ml: 1, flex: 1, color:admin_panel_ctx.cText.default }} placeholder="Og:image"/>
                        </Paper>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
        {openModal && <CheckModal duplicates={duplicates} close={()=>setOpenModal(false)} uri={true}/>}
        </>
    )
}
export default Metadata;