
import BulletPointsList from "./BulletPointsList/BulletPointsList";
import {Grid, Typography} from "@mui/material";
import BulletPointTable from "./BulletPointsTable/BulletPointsTable";
import BulletPointsContext from "../../../../../../../../../Context/BulletPointsContext";
import { useState, useEffect, useContext } from "react";
import useFetch from '../../../../../../../../../Hooks/use-fetch'
import { GET_BULLET_POINTS_TOKENS, MANAGE_INDEX_BULLET_POINTS } from "../../../../../../../../../Constants";
import ProductEditContext from "../../../../../../../../../Context/ProductEditContext";

const BulletPoints =()=>{
    const [allBulletPointsTokens, setAllBulletPointsTokens] = useState();
    const [currentBulletPoint, setCurrentBulletPoint] = useState();
    const [currentBulletPointIndex, setCurrentBulletPointIndex] = useState(0);
    const [bulletPointsTokensInUse, setBulletPointsTokensInUse] = useState();
    const [bulletPointsTokensToSelect, setBulletPointsTokensToSelect] = useState();

    const [bulletPointsCurrentTokenId, setBulletPointsCurrentTokenId] = useState();
    const [reloadTokens, setReloadTokens] = useState(false);
    const [reloadBulletPoints, setReloadBulletPoints] = useState(false);

    const product_edit_context = useContext(ProductEditContext)
    
    const {loading:bulletPointsListLoading, error:bulletPointsListError, sendRequest:sendBulletPointsListRequest } = useFetch();
    
    const getBulletPointsTokens = (response)=>{
        const tokens = response.map(x => {return {token:x.token, name:x.name}})
        setAllBulletPointsTokens(tokens)
    }

    useEffect(()=>{
        sendBulletPointsListRequest(
            {
            url:GET_BULLET_POINTS_TOKENS,
            method:"GET",
            },
            getBulletPointsTokens)
    },[])

    useEffect(()=>{
        if(bulletPointsTokensInUse!==undefined){
            if(bulletPointsTokensInUse.length>0 && currentBulletPointIndex !== undefined){
                setBulletPointsCurrentTokenId(bulletPointsTokensInUse[currentBulletPointIndex].id)
            }
        }
    },[bulletPointsTokensInUse, currentBulletPointIndex])
    
    useEffect(()=>{
        if(allBulletPointsTokens && bulletPointsTokensInUse){
            const allTokens = allBulletPointsTokens.map(x=>{return x.token})
            const tokensInUse = bulletPointsTokensInUse.map(x=>{return x.token})
            const tokens = allTokens.filter(x =>{return tokensInUse.indexOf(x) < 0})
            let tokensToSelect = []
            tokens.forEach(token => {
                const tok = allBulletPointsTokens.find(x=>x.token == token)
                tokensToSelect.push({token:tok.token, name:tok.name})
            });
            setBulletPointsTokensToSelect(tokensToSelect.length > 0 ? tokensToSelect : null)
        }
    },[allBulletPointsTokens, bulletPointsTokensInUse])

    return (
        <BulletPointsContext.Provider
            value={{
                bulletPointsCurrentTokenId,
                currentBulletPointIndex,
                allBulletPointsTokens,
                bulletPointsTokensToSelect,
                currentBulletPoint,
                bulletPointsTokensInUse,
                setCurrentBulletPointIndex,
                setAllBulletPointsTokens,
                setBulletPointsTokensToSelect,
                setBulletPointsTokensInUse,
                setCurrentBulletPoint,
                setReloadTokens,
                setReloadBulletPoints,
                setBulletPointsCurrentTokenId
            }}
        >
            <Grid container>
                <Grid item xs={3}>
                    <BulletPointsList loading={bulletPointsListLoading}/>
                </Grid>
                <Grid item xs={9}>
                    <BulletPointTable/>
                </Grid>
            </Grid>
        </BulletPointsContext.Provider>
    )
}
export default BulletPoints;