import "./BottomAdminPanel.css"
import React,{useContext} from "react";
import AdminPanelContext from "../../../Context/AdminPanelContext";
import Warehouse from "../../Modules/BaseModules/Warehouse/Warehouse";
const BottomAdminPanel = () =>{
    
    const admin_panel_ctx = useContext(AdminPanelContext);

    const getClassName = direction =>{
        if(direction === 'left'){
            if(admin_panel_ctx.isBottomLeftOpen && !admin_panel_ctx.isBottomRightOpen) return "fully_opened_bottom";
            else if (admin_panel_ctx.isBottomLeftOpen && admin_panel_ctx.isBottomRightOpen) return "half_opened_bottom";
            else return "closed";
        }
        else{
            if(!admin_panel_ctx.isBottomLeftOpen && admin_panel_ctx.isBottomRightOpen) return "fully_opened_bottom";
            else if (admin_panel_ctx.isBottomLeftOpen && admin_panel_ctx.isBottomRightOpen) return "half_opened_bottom";
            else return "closed";
        }
    }

    return(
        <div className={`bottom_admin_panel ${(admin_panel_ctx.isBottomLeftOpen || admin_panel_ctx.isBottomRightOpen) && 'bottom_open'}`} style={{backgroundColor:admin_panel_ctx.color2}}>
            <div className={`bottom_left_panel ${getClassName('left')}`}>
                {admin_panel_ctx.isBottomLeftOpen === 1 && <Warehouse/>}
                {admin_panel_ctx.isBottomLeftOpen === 2 &&
                <>
                    <div className={`bottom_left_panel_top`} style={{backgroundColor:admin_panel_ctx.color1}}>
                        <div>Opcje magazynowania</div>
                    </div>
                    <div className={`bottom_left_panel_side`} style={{backgroundColor:admin_panel_ctx.color1}}>Informacje o magazynach</div>
                </>
                }
            </div>
            <div className={`bottom_right_panel ${getClassName('right')}`}>
                <div className={`bottom_right_panel_top`} style={{backgroundColor:admin_panel_ctx.color1}}>
                    <div>Opcje raportowania</div>
                </div>
                <div className={`bottom_right_panel_side`} style={{backgroundColor:admin_panel_ctx.color1}}>Informacje o raportach</div>
            </div>
        </div>
    )
}

export default BottomAdminPanel;