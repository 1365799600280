import { Grid } from "@mui/material"

const CategoryEditFieldsComponent = () =>{
    return(
        <Grid className={'CategoryEditFields'} sx={{backgroundColor:'gray'}}>
            <Grid item xs={12} sx={{height:'15%', backgroundColor:"#4C4F51"}} p={3}>
            </Grid>
            <Grid item xs={12} className='scrollerY' sx={{overflowY:'auto', height:'85%'}}>
            </Grid>
        </Grid>
    )
}
export default CategoryEditFieldsComponent;