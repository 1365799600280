import { useContext } from 'react';
import AdminPanelContext from '../../../../../Context/AdminPanelContext';
import styles from './AddNewBulkModal.module.css';
import { createPortal } from 'react-dom';
import {RiCloseFill} from 'react-icons/ri';
import AddNewBulkForm from '../AddNewBulkForm/AddNewBulkForm';

const AddNewBulkModal = (props) => {

    const admin_panel_ctx = useContext(AdminPanelContext);

    return(
        <>
            {createPortal(
            <div className={styles.new_bulk_modal}  style={{backgroundColor:admin_panel_ctx.color1}}>
                <div className={styles.new_bulk_modal_close_icon_container}><RiCloseFill className={styles.new_bulk_modal_close_icon} size={25} onClick={()=>props.closeModal(false)}/></div>
                <div className={styles.new_bulk_modal_form}>
                    <AddNewBulkForm reload={()=>props.reload()} close={()=>props.closeModal(false)}/>
                </div>
            </div>, document.getElementById('modal'))}

            {createPortal(<div className={styles.backdrop} onClick={()=>props.closeModal(false)}/>, document.getElementById('backdrop'))}
        </>
    )
}

export default AddNewBulkModal;