import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CategoryEditContext from "../../../../../../../../../Context/CategoryEditContext";
import DefaultCategoryEditElement from "./DefaultCategoryEditElement/DefaultCategoryEditElement";

const CategoryEditElement = (props) =>{
    const [basicInfo, setBasicInfo] = useState({url:null, name:null});
    const [selectedOperationField, setSelectedOperationField] = useState(1);

    useEffect(()=>{
        setBasicInfo(prev => {return { ...prev, name : JSON.parse(localStorage.getItem("ModulesBar")).filter(x=>x.config.componentType === 'CategoryEdit').find(x=>x.config.id === props.object.config.id).label}});
    },[JSON.parse(localStorage.getItem("ModulesBar")).length])

    return (
        <CategoryEditContext.Provider
        value={{
            basicInfo,
            selectedOperationField,
            setBasicInfo,
            setSelectedOperationField
        }}
        >
            <Grid container className="zoomOnShow">
                <DefaultCategoryEditElement/>
            </Grid>
        </CategoryEditContext.Provider>
    )
}
export default CategoryEditElement;