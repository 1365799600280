import React, { useContext } from "react";
import styles from "./WrapperBottom.module.css";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
const WrapperBottom = () =>{
    
    const admin_panel_ctx = useContext(AdminPanelContext);

    const setBottomOpen = (direction, number) => {
            if(direction === 'up'){
                if (admin_panel_ctx.isBottomLeftOpen !== number) admin_panel_ctx.setIsBottomLeftOpen(number);
                else admin_panel_ctx.setIsBottomLeftOpen(false);
            }
            else{
                if (admin_panel_ctx.isBottomRightOpen !== number) admin_panel_ctx.setIsBottomRightOpen(number);
                else admin_panel_ctx.setIsBottomRightOpen(false);
            }
        }

        const getOrganizationPrivileges = ()=>{
            if(JSON.parse(localStorage.getItem('currentOrganization'))){
                return JSON.parse(localStorage.getItem('currentOrganization')).config.privileges;
            }
        } 

    return(
        <div id={"wrapperBottom"} className={styles.wrapper_bottom}>
            <div className={styles.wrapper_bottom_container} style={{backgroundColor:admin_panel_ctx.color1}}>
                <div className={styles.wrapper_bottom_left}>
                    <ul className={styles.wrapper_bottom_left_list}>
                        {getOrganizationPrivileges().warehouse.available && <li style={admin_panel_ctx.isBottomLeftOpen===1?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setBottomOpen('up',1)}>Magazyn</li>}
                        {getOrganizationPrivileges().priceList.available && <li style={admin_panel_ctx.isBottomLeftOpen===2?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}}  onClick={()=>setBottomOpen('up',2)}>Cenniki</li>}
                    </ul>
                </div>
                <div className={styles.wrapper_bottom_right}>
                    <ul className={styles.wrapper_bottom_right_list}>
                        {getOrganizationPrivileges().reports.available && <li style={admin_panel_ctx.isBottomRightOpen===1 ?{backgroundColor:admin_panel_ctx.color2}:{backgroundColor:''}} onClick={()=>setBottomOpen('down',1)}>Raporty</li>}
                    </ul>
                </div>
            </div>
            <div className={styles.wrapper_bottom_status}>
                Status: Ready
            </div>
        </div>
    )
}

export default WrapperBottom;