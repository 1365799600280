import styles from "./Configurate.module.css";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Flag from "@mui/icons-material/Flag";
import { Grid, Typography } from "@mui/material";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LanguageIcon from '@mui/icons-material/Language';
import { useContext } from "react";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import ModulesBarManager from "../../ModulesBarManager";
const Configurate = () =>{

    const admin_panel_ctx = useContext(AdminPanelContext);


    const languagesClick =()=>{
        if(admin_panel_ctx.isLanguagesOpen){
            ModulesBarManager("Languages", "show", {componentType:"Languages"});
        }
        else{
            admin_panel_ctx.setIsLanguagesOpen(true);
            ModulesBarManager("Languages", "push", {componentType:"Languages"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }
    const attributesClick =() =>{
        if(admin_panel_ctx.isAttributesOpen){
            ModulesBarManager("Attributes", "show", {componentType:"Attributes"});
        }
        else{
            admin_panel_ctx.setIsAttributesOpen(true);
            ModulesBarManager("Attributes", "push", {componentType:"Attributes"});
        }
        admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
    }



    return (
            <Grid className={styles.content} container>
                <Grid item xs={12}>Konfiguracja</Grid>

                <Grid p={2} xs={12} item>
                    <MenuList>

                        <MenuItem>
                            <ListItemIcon>
                                <Flag sx={{color:"silver"}} fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText align="left"><Typography fontSize={12}>Kraje</Typography></ListItemText>
                        </MenuItem>

                        <MenuItem onClick={languagesClick}>
                            <ListItemIcon>
                                <LanguageIcon sx={{color:"silver"}} fontSize="medium"/>
                            </ListItemIcon>
                            <ListItemText align="left"><Typography fontSize={12}>Języki</Typography></ListItemText>
                        </MenuItem>

                        <MenuItem onClick={attributesClick}>
                            <ListItemIcon>
                                <BookmarkIcon sx={{color:"silver"}} fontSize="medium"/>
                            </ListItemIcon>
                            <ListItemText align="left"><Typography fontSize={12}>Atrybuty</Typography></ListItemText>
                        </MenuItem>

                    </MenuList>
                </Grid>
            </Grid>
    )
}
export default Configurate;