import { Button, Divider, Grid, IconButton } from "@mui/material";
import styles from "./BlogPostsComponent.module.css";
import { useContext } from "react";
import BlogPostsContext from "../../../../../../Context/BlogPostsContext";

export const FieldOptions = [{id:1, name:'Dane Podstawowe'}, {id:2, name:'Metadata'}, {id:3, name:'Bullet points'}, {id:4, name:'Media'}, {id:5, name:"Opis"}, {id:6, name:"Atrybuty"}]

const BlogPostsComponent =() =>{

    const blog_posts_context = useContext(BlogPostsContext)
    
    return (
        <Grid container className={`${styles.BlogPostsComponent} BlogPostsComponent`} p={1} color={'white'}>
            <IconButton sx={{ py: '10px' }} aria-label="menu">
                <img src="/images/flags/pl.png" width={'35px'} height={'35px'} style={{border:'1px solid gray', borderRadius:'30px'}}/>
            </IconButton>
            <Divider/>
                <Grid item xs={12} mt={2} mb={1}><Button className={styles.fieldButton} variant={blog_posts_context.currentBlogPost === 'all' ? "contained" : "standard" } onClick={()=>blog_posts_context.setCurrentBlogPost('all')} fullWidth sx={{fontSize:17}}>Lista postów na bloga</Button></Grid>
            <Divider/>
            <Grid item xs={12} mt={1}>
            {FieldOptions.map(opt=>{
                return <Grid item xs={12} mt={1} key={opt.id}><Button className={styles.fieldButton} variant={blog_posts_context.currentBlogPost === opt.id ? "contained" : "standard" } onClick={()=>blog_posts_context.setCurrentBlogPost(opt.id)} fullWidth>{opt.name}</Button></Grid>
            })}
            </Grid>
        </Grid>
    )
  
}
export default BlogPostsComponent;  