import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useContext, useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { GoSettings } from 'react-icons/go';
import { BsArrowsFullscreen } from 'react-icons/bs';
import AdminPanelContext from '../../../../../Context/AdminPanelContext';
import { useNavigate } from 'react-router-dom';
import CookiesContext from '../../../../../Context/CookiesContext';
import { useState } from 'react';
import SettingsPanel from '../SettingsPanel/SettingsPanel';

const UserIcon=()=> {
  const admin_panel_ctx = useContext(AdminPanelContext);
  const cookies_ctx = useContext(CookiesContext);
  let navigate = useNavigate();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  
  useEffect(()=>{
    const SpeedDial = document.getElementById('SeppedDial');
    SpeedDial.style.height="50px";
    const SpeedDialIcon = document.getElementsByClassName('MuiFab-root').item(0);
    SpeedDialIcon.style.backgroundColor='black';
    SpeedDialIcon.style.width = '40px';
    SpeedDialIcon.style.height = '40px';
    SpeedDialIcon.style.top = '0px';
  });
  
  const logOut = () =>{
    cookies_ctx.RemoveCookie('token');
    localStorage.clear();
    document.exitFullscreen();
    return navigate('/login');
  }

  return (
    <>
      <Box sx={{ position: 'relative', mr:1}}>
        <SpeedDial
          id="SeppedDial"
          ariaLabel="SpeedDial playground example"
          icon={<AiOutlineUser size={50}/>}
          direction={'left'}
        >
            <SpeedDialAction
              sx={{
                backgroundColor:'black', 
                color:'white',
                '&:hover': {
                    color:'black',
                    backgroundColor: 'white',
                  },
                }}
              key={'Settings'}
              icon={<GoSettings size={20}/>}
              tooltipTitle={'Settings'}
              onClick={()=>setSettingsModalOpen(true)}
            />
                <SpeedDialAction
                  sx={{
                    backgroundColor:'black', 
                    color:'white',
                    '&:hover': {
                        color: 'black',
                        backgroundColor: 'white',
                      },
                    }}
                  key={'Fullscreen'}
                  icon={<BsArrowsFullscreen size={20}/>}
                  tooltipTitle={'Enter fullscreen'}
                  onClick={()=>{
                    if (document.fullscreenElement != null){
                      document.exitFullscreen();
                    }
                    document.documentElement.requestFullscreen()
                  }}
                />
            <SpeedDialAction
              sx={{
                backgroundColor:'black', 
                color:'white',
                '&:hover': {
                    color: 'black',
                    backgroundColor: 'white',
                  },
                }}
              key={'Logout'}
              icon={<BiLogOut size={20}/>}
              tooltipTitle={'Log out'}
              onClick={logOut}
            />
        </SpeedDial>
        </Box>
        {settingsModalOpen && <SettingsPanel isModalOpen={settingsModalOpen} closeModal={setSettingsModalOpen}/>}
      </>
  );
}
export default UserIcon;