import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import { CHANGE_IMPORT_INDEX_STATUS } from "../../../../../../../Constants";
import AdminPanelContext from "../../../../../../../Context/AdminPanelContext";
import PostMethod from "../../../../../../FetchMethods/PostMethod";
import PhotoModal from "../../../PhotoModal/PhotoModal";
import styles from "./ImportIndexesListTable.module.css";
import LinearProgress from '@mui/material/LinearProgress';

const ImportIndexesListTable = (props)=>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [productData, setProductData] = useState();
    const [loading, setLoading] = useState(false);


    const changeImport=(id,gtin,newStatus)=>{
        setLoading(true);
        const data={
            db:JSON.parse(localStorage.getItem("currentOrganization")).config.database,
            id:id,
            gtin:gtin,
            'new-status':newStatus
        }
        PostMethod(CHANGE_IMPORT_INDEX_STATUS,data,getResponse)
    }


    const getResponse = (data)=>{
        props.reload();
        setLoading(false);
    }

    const getStatusPl=(name)=>{
        if(name === 'all') return 'wszystkie';
        else if(name === 'new') return 'nowe';
        else if(name === 'accepted') return 'w sklepie';
        else if(name === 'archive') return 'archiwum';
    }


    return (
        <>
            <Grid container>

                <Grid container item xs={12} sx={{ xs:{backgroundColor:admin_panel_ctx.cSheet.default}}} p={1}>
                    <Grid container item xs={12}>
                        {Object.keys(props.columns).map(col => {
                            return (<Grid key={col} textAlign={props.columns[col].anchor} xs={props.columns[col].width} item px={2}>{props.columns[col].label}</Grid>)
                            })
                        }
                    </Grid>
                </Grid>

                
                {props.loading ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                      :
                <Grid container item xs={12} sx={{backgroundColor:admin_panel_ctx.cSheet.default}} mt={2} p={1}>
                    {Object.keys(props.rows).map((row, index)=>{
                        let price = JSON.parse(props.rows[row].price);
                        let displayedPrice= price.gross ? `${(parseInt(price.gross)/100).toFixed(2)} ${price.currency} brutto` : `${(parseInt(price.net)/100).toFixed(2)} ${price.currency ? price.currency: ''} netto` ;
                        let numberOfPhotos = props.rows[row].photos.length;
                        return (
                            <Grid container item xs={12} p={1} sx={{borderBottom:'solid #1f1f1f 1px'}} key={index}>
                                <Grid container item xs={1} textAlign={'center'} alignItems={'center'} justifyContent={'center'}>
                                    {props.rows[row].status === 'new' && <>
                                        <Button variant="contained" sx={{paddingLeft:'8px',paddingRight:'8px',paddingTop:'4px',paddingBottom:'3px', margin:'20px'}} size="small" onClick={()=>changeImport(props.rows[row].id, props.rows[row].gtin, 'accepted' )} disabled={loading}>do sklepu</Button>
                                        <Button variant="contained" sx={{paddingLeft:'8px',paddingRight:'8px',paddingTop:'4px',paddingBottom:'3px', backgroundColor:'red'}} size="small" onClick={()=>changeImport(props.rows[row].id, props.rows[row].gtin, 'archive' )} disabled={loading}>do archiwum</Button>
                                    </>}
                                    {props.rows[row].status === 'accepted' && <>
                                        <Typography fontSize={20}>w sklepie</Typography>
                                    </>}
                                    {props.rows[row].status === 'archive' && <>
                                        <Button variant="contained" sx={{paddingLeft:'8px',paddingRight:'8px',paddingTop:'4px',paddingBottom:'3px', backgroundColor:'gray', margin:'20px'}} size="small" disabled={loading}>archiwum</Button>
                                        <Button variant="contained" sx={{paddingLeft:'8px',paddingRight:'8px',paddingTop:'4px',paddingBottom:'3px', backgroundColor:'blue'}} size="small" onClick={()=>changeImport(props.rows[row].id, props.rows[row].gtin, 'accepted' )} disabled={loading}>do sklepu</Button>
                                    </>}
                                    
                                </Grid>
                                <Grid container item xs={2} alignContent={'center'} justifyContent={'center'}>
                                    {props.rows[row].photos ?
                                    <div className={styles.table_image_container} onClick={()=>setProductData(props.rows[row])}>
                                        <img className={styles.table_image} alt={'media'} src={props.rows[row].photos[0]} loading="lazy"/>
                                        {numberOfPhotos>1 && <div className={styles.table_images_number}>{numberOfPhotos}</div>}
                                    </div>
                                    :
                                    "Brak zdjęcia"
                                    }
                                </Grid>
                                <Grid item xs={5} px={2} textAlign={'left'}>
                                    <span style={{margin:0}}>{props.rows[row].supplierSourceName}</span>
                                    <h3 style={{margin:0}}>{props.rows[row].name}</h3>
                                    <h4 style={{margin:0, color:'white'}}>{getStatusPl(props.rows[row].status).toUpperCase()}</h4>
                                    <br/>
                                    {props.rows[row].status ==="accepted" && <>
                                    
                                    Bazowe Id: {props.rows[row].base_index_id}<br/>
                                    </>}
                                    SKU: {props.rows[row].sku}<br/>
                                    GTIN: {props.rows[row].gtin}
                                </Grid>
                                <Grid item xs={2} px={2} textAlign={'center'}>{props.rows[row].stock}</Grid>
                                <Grid item xs={2} px={2} textAlign={'center'}>{displayedPrice}<br/><br/>VAT: {`${price.vat}%`.replace('%%','%')}</Grid>
                            </Grid>
                        )
                    })}
                </Grid>
}
            </Grid>
            {productData && <PhotoModal productData={productData} close={()=>setProductData(null)}/>}
        </> 
    )
}

export default ImportIndexesListTable;