import React, { useEffect, useState } from "react";
import styles from "./WrapperTop.module.css";
import { useContext } from "react";
import AdminPanelContext from "../../../../Context/AdminPanelContext";
import UserIcon from "./UserIcon/UserIcon";
import {FormControl, NativeSelect } from "@mui/material";
import ModulesBarManager from "../../../Modules/ModulesBarManager";

const WrapperTop = () =>{
    const admin_panel_ctx = useContext(AdminPanelContext);
    const [selectValue, setSelectValue] = useState('attributesManager');



    useEffect(()=>{
        if(JSON.parse(localStorage.getItem("ModulesBar")) && !JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.label==="IndexesAttributesManager")){
            setSelectValue('attributesManager');
        }
    }, [(JSON.parse(localStorage.getItem("ModulesBar")) && JSON.parse(localStorage.getItem("ModulesBar")).length)])


    useEffect(()=>{
        if(selectValue === 'indexes') indexesAttributesManagerClick();
        else if (selectValue !== 'indexes' && (JSON.parse(localStorage.getItem("ModulesBar")) && JSON.parse(localStorage.getItem("ModulesBar")).find(x=>x.label==="IndexesAttributesManager"))){
        }
    },[selectValue])

    const indexesAttributesManagerClick = () =>{
        if(admin_panel_ctx.isIndexesAttributesManagerOpen){
            ModulesBarManager("IndexesAttributesManager", "show", {componentType:"IndexesAttributesManager"});
            admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
        }
        else{
            admin_panel_ctx.setIsIndexesAttributesManagerOpen(true);
            ModulesBarManager("IndexesAttributesManager", "push", {componentType:"IndexesAttributesManager"});
            admin_panel_ctx.setReloadModulesBar(admin_panel_ctx.reloadModulesBar + 1);
        }
    }

    return(
        <>
            <div id={"wrapperTop"} className={styles.wrapper_top} style={{backgroundColor:admin_panel_ctx.color1}}>
                <div className={styles.wrapper_top_actions}>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 80}}>
                                    <NativeSelect
                                    className={styles.AttributesManagerSelect}
                                    value={selectValue}
                                    onChange={(e)=>{setSelectValue(e.target.value)}}
                                    sx={{color:'white'}}
                                    >
                                        <option value={'attributesManager'}>Menadżer atrybutów</option>
                                        <option value={'indexes'}>Indexy</option>
                                        <option value={'guides'}>Poradniki</option>
                                    </NativeSelect>
                        </FormControl>
                    </div>
                    <div className={styles.wrapper_top_user}><UserIcon/></div>
                </div>
            </div>
        </>
    )
}

export default WrapperTop;