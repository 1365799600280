const ModulesBarManager = (label, operation, config=null) =>{
    let openedModules = localStorage.getItem("ModulesBar");
    let decodedStorage=[];

    //PUSH

    if(operation === "push"){
        if(!openedModules) {decodedStorage.push({label:label, active:true, config:config})}
        else{
            decodedStorage = JSON.parse(openedModules);
            let checkBy = decodedStorage.find(x=>x.label === label)
            if(config.componentType === 'ProductEdit'){
                checkBy = decodedStorage.filter(x=>x.config.componentType === 'ProductEdit').find(x=>x.config.id === config.id);
            }
            else if(config.componentType === 'CategoryEdit'){
                checkBy = decodedStorage.filter(x=>x.config.componentType === 'CategoryEdit').find(x=>x.config.id === config.id);
            }
            if(!checkBy){
                const newItem = {label:label, active:config.isControlPressed ? false : true, config:config};
                if(!config.isControlPressed){
                    if(decodedStorage.length>0) decodedStorage[decodedStorage.findIndex(x=>x.active === true)].active = false;
                }
                decodedStorage.push(newItem);
            }
            else{
                decodedStorage[decodedStorage.findIndex(x=>x.active === true)].active = false;
                const newActive = checkBy;
                decodedStorage[newActive].active = true;
            }
        }
    }

    //POP

    else if(operation === "pop"){
        decodedStorage = JSON.parse(openedModules);
        const isMultiComponent = config.componentType === "ProductEdit" || config.componentType === "CategoryEdit";
        let checkBy = isMultiComponent ? decodedStorage.findIndex(x=>(x.config.componentType === config.componentType && x.config.id === config.id)) : decodedStorage.findIndex(x=>(x.config.componentType === config.componentType && x.label === label))

        if(checkBy===0 && decodedStorage.length>1){
            if(checkBy === decodedStorage.findIndex(x=>x.active === true)){
                decodedStorage[decodedStorage.findIndex(x=>x.active === true)].active = false;
            }
            decodedStorage.splice(0,1);
            decodedStorage[0].active = true;
        }
        else if(checkBy===0 && decodedStorage.length===1){
            decodedStorage.splice(0,1);
        }
        else{
            if(checkBy === decodedStorage.findIndex(x=>x.active === true)){
                decodedStorage[decodedStorage.findIndex(x=>x.active === true)].active = false;
                decodedStorage[checkBy-1].active = true;
            }
            decodedStorage.splice(checkBy,1); 
        }
    }

    //SHOW

    else if(operation === "show"){
        decodedStorage = JSON.parse(openedModules);
        decodedStorage[decodedStorage.findIndex(x=>x.active === true)].active = false;
        if(config.componentType === 'ProductEdit' || config.componentType === "CategoryEdit" ){
            let filteredDecodedStorage = decodedStorage.filter(x=>x.config.componentType === config.componentType);
            filteredDecodedStorage[filteredDecodedStorage.findIndex(x=>x.config.id === config.id)].active = true
        }
        else{
            decodedStorage[decodedStorage.findIndex(x=>x.label === label)].active = true;
        }
    }

    else if(operation === "changeModulesBarItemLabel"){
        decodedStorage = JSON.parse(openedModules);
        decodedStorage[decodedStorage.findIndex(x=>x.config.id === config.id)].label = label
        decodedStorage[decodedStorage.findIndex(x=>x.config.id === config.id)].config.name = label
    }

    localStorage.setItem("ModulesBar",JSON.stringify(decodedStorage));

};

export default ModulesBarManager;